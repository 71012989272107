import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "parentEmail",
    "decisionApprove",
    "decisionDeny",
    "confirmBtn",
    "transcriptFile",
    "parentEmailContainer",
    "transcriptContainer",
  ];

  connect() {
    this.addedParentEmail = false;
    this.addedTranscript = false;
  }

  controlParentEmail() {
    this.addedParentEmail = !(this.parentEmailTarget.value.length > 1);
    this.controlButton();
  }

  controlEmail() {
    if (this.decisionApproveTarget.checked === true) {
      this.parentEmailTarget.disabled = false;
      this.parentEmailTarget.focus();
      this.confirmBtnTarget.disabled = true;
      this.parentEmailTarget.placeholder = "user@domain.com";
      this.transcriptFileTarget.disabled = false;
      this.parentEmailContainerTarget.classList.remove("hidden");
      this.transcriptContainerTarget.classList.remove("hidden");
    } else if (this.decisionDenyTarget.checked === true) {
      this.transcriptFileTarget.disabled = true;
      this.parentEmailTarget.value = "";
      this.parentEmailTarget.placeholder = "";
      this.parentEmailTarget.disabled = true;
      this.confirmBtnTarget.disabled = false;
      this.confirmBtnTarget.focus();
      this.parentEmailContainerTarget.classList.add("hidden");
      this.transcriptContainerTarget.classList.add("hidden");
    }
  }

  checkTranscriptFile() {
    let file = this.transcriptFileTarget.files[0];
    this.addedTranscript =
      parseInt(this.data.get("maxFileSize")) >= parseInt(file.size);
    this.controlButton();
  }

  controlButton() {
    this.confirmBtnTarget.disabled =
      this.addedParentEmail == true && this.addedTranscript == true;
  }
}
