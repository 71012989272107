/* globals $ */
import { Controller } from "stimulus";
import consumer from "../channels/consumer";

export default class extends Controller {
  static targets = ["universityChecks"];

  connect() {
    // TODO Replace jQuery usage
    this._update_summary_label();

    this.channel = consumer.subscriptions.create(
      {
        channel: "FeatureHealthcheckChannel",
        id: this.data.get("id"),
      },
      {
        received: this._cableReceived.bind(this),
      }
    );
  }

  _cableReceived(data) {
    let check = $(this.universityChecksTargets)
      .find('span[data-check-id="' + data.id + '"]')
      .get(0);

    this._update_check_status(check, data);
    this._maybe_move_checks_row(check);
    this._maybe_hide_show_sections();
  }

  _update_summary_label() {
    $(".universityChecks")
      .toArray()
      .forEach(function (el) {
        var passingChecks = $(el).hasClass("passing");
        var summaryCount = $(el).find("tbody tr").length;
        var summaryClass =
          passingChecks === true ? "text-green-700" : "text-red-700";
        var summaryIcon =
          passingChecks === true
            ? '<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1 text-green-700 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/></svg>'
            : '<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1 text-red-700 inline-block" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"/></svg>';
        var plural = summaryCount > 1;
        var targetButton = $(el).find("button.collapseButton").get(0);

        var summaryLabel = plural === true ? "Universities " : "University ";
        if (passingChecks) {
          summaryLabel += plural === true ? "pass " : "passes";
          summaryLabel += " all checks";
        } else {
          summaryLabel += "with some missing set up";
        }

        var icon = summaryIcon + summaryCount.toString();
        var span =
          '<span class="flex-inline items-center mr-1 ' +
          summaryClass +
          '">' +
          icon +
          "</span> " +
          summaryLabel +
          '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block ml-auto collapse-icon" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/></svg>';

        $(targetButton).empty().append(span);
      });
  }

  _update_check_status(check, data) {
    if (data.status == "passing") {
      $(check).parent().removeClass().addClass("university-check passed");
    } else if (data.status == "failing") {
      $(check).parent().removeClass().addClass("university-check failed");
    } else {
      $(check).parent().removeClass().addClass("university-check warning");
    }
  }

  _maybe_move_checks_row(check) {
    let row = $(check).parents("tr").get(0);
    let allChecksPassing = $.makeArray(
      $(row).find("td > .university-check")
    ).every(function (el) {
      return $(el).hasClass("passed");
    });
    let currentRowInPassingSection = $(
      $(row).parents(".universityChecks").get(0)
    ).hasClass("passing");

    if (allChecksPassing && !currentRowInPassingSection) {
      // Move to passing section
      $("div.universityChecks.passing table.dashboard-table")
        .find("tbody")
        .prepend($(row).clone());
      $(row).remove();
      this._update_summary_label();
    } else if (!allChecksPassing && currentRowInPassingSection) {
      // Move to failing section
      $("div.universityChecks.failing table.dashboard-table")
        .find("tbody")
        .prepend($(row).clone());
      $(row).remove();
      this._update_summary_label();
    }
  }

  _maybe_hide_show_sections() {
    $(".universityChecks")
      .toArray()
      .forEach(function (el) {
        let alreadyHidden = $(el).hasClass("hidden");
        let rowsQuantity = $(el).find("tbody tr").length;

        if (alreadyHidden && rowsQuantity > 0) {
          $(el).removeClass("hidden");
        } else if (!alreadyHidden && rowsQuantity == 0) {
          $(el).addClass("hidden");
        }
      });
  }
}
