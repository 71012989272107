import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["universitySelector", "newTuitionLink"];

  updateLink() {
    this.newTuitionLinkTarget.href =
      "/admin/tuition_policies/new?university_id=" +
      this.universitySelectorTarget.value;
  }
}
