/* globals $ */
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "universitySelect",
    "select",
    "submitSearchButton",
    "requiredFieldsMessage",
  ];

  connect() {
    let controller = this;

    $(this.selectTarget).selectize({
      plugins: ["selectize-plugin-a11y"],
      valueField: "id",
      labelField: "text",
      openOnFocus: false,
      score: function () {
        return function () {
          return 1;
        };
      },
      onType: function () {
        this.clearOptions();
        this.addOption([{ id: "", text: "Loading..." }]);
        this.refreshOptions(true);
      },
      load: function (query) {
        $.ajax({
          url:
            "/api/universities/" + controller.universityId() + "/sessions.json",
          dataType: "json",
          type: "GET",
          data: {
            query: query,
          },
          success: (response) => {
            let results;
            if (!response.results.length) {
              results = [{ id: "", text: "No Results" }];
            } else {
              results = response.results.map(function (session) {
                return [
                  { id: session.id.replace(/[^\d]/g, ""), text: session.text },
                ];
              });
            }

            // This makes sure that 'Loading...' is removed
            this.clearOptions();
            this.addOption(results);
            this.refreshOptions(true);
          },
        });
      },
      render: {
        option: function (item) {
          // Every option must have a unique id
          return `<div class="option" role="option" id="${item.text.replace(
            / /g,
            ""
          )}">${item.text}</div>`;
        },
      },
    });

    controller.toggleStatus();

    if (
      this.hasSubmitSearchButtonTarget &&
      this.hasRequiredFieldsMessageTarget
    ) {
      controller.toggleSearchButton();
    }
  }

  toggleSearchButton() {
    if (this.selectTarget.value) {
      this.submitSearchButtonTarget.removeAttribute("disabled");
      this.requiredFieldsMessageTarget.style["display"] = "none";
    } else {
      this.submitSearchButtonTarget.disabled = "disabled";
      this.requiredFieldsMessageTarget.style["display"] = null;
    }
  }

  toggleStatus() {
    if (this.universityId() !== "") {
      this.selectTarget.selectize.enable();
    } else {
      this.selectTarget.selectize.disable();
    }
    if (
      this.hasSubmitSearchButtonTarget &&
      this.hasRequiredFieldsMessageTarget
    ) {
      this.toggleSearchButton();
    }
  }

  empty() {
    this.selectTarget.selectize.clear();
    this.selectTarget.selectize.clearOptions();
  }

  universityId() {
    return this.universitySelectTarget.options[
      this.universitySelectTarget.selectedIndex
    ].value;
  }
}
