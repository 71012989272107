/*globals $ */

import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
import consumer from "../channels/consumer";

export default class extends Controller {
  static targets = [
    "selectedItemInput",
    "addedYearGroup",
    "removedYearGroup",
    "courseChangeSelector",
    "currentCourseSelection",
    "loadingSpinner",
    "statusSummary",
    "statusMessagesContainer",
    "fulfillerImportStatusInProgress",
    "fulfillerImportStatusDone",
    "tabControllerContainer",
  ];

  showLoadingIndicator() {
    this.loadingSpinnerTarget.style["display"] = null;
  }

  afterReflex() {
    if (this.hasTabControllerContainerTarget) this.tabsController.showTab();

    if (this.hasLoadingSpinnerTarget)
      this.loadingSpinnerTarget.style["display"] = "none";

    if (this.hasSelectedItemInputTarget && this.selectedItemInputTarget.value) {
      document
        .getElementById(
          "flight-plan-item-" + this.selectedItemInputTarget.value
        )
        .focus();
    }

    if (
      this.hasAddedYearGroupTarget &&
      this.addedYearGroupTarget.value == "true"
    ) {
      var year_groups = document.getElementsByClassName("pathways-year-group");
      var last_year = year_groups[year_groups.length - 1];
      last_year.getElementsByClassName("remove-year-button")[0].focus();
      this.addedYearGroupTarget.value = "false";
    }

    if (
      this.hasRemovedYearGroupTarget &&
      this.removedYearGroupTarget.value == "true"
    ) {
      window.scrollTo(0, 0);
    }
  }

  connect() {
    this.loadingSpinnerTarget.style["display"] = "none";
    let self = this;

    this.channel = consumer.subscriptions.create(
      {
        channel: "UserPathwayValidationsChannel",
        id: self.data.get("id"),
      },
      {
        received: this._validationsCableReceived.bind(this),
      }
    );

    consumer.subscriptions.create(
      {
        channel: "UserPathwayProgressChannel",
        id: this.data.get("id"),
      },
      {
        received: this._progressCableReceived.bind(this),
      }
    );

    consumer.subscriptions.create(
      {
        channel: "UserPathwayFulfillerChannel",
        id: this.data.get("id"),
      },
      {
        received: this._fulfillerCableReceived.bind(this),
      }
    );

    StimulusReflex.register(this);
  }

  _progressCableReceived(data) {
    $("#planned-items-count").text(data["planned_items_count"]);
    $("#total-items-count").text(data["total_items_count"]);
    $("#completed-items-credits-total").text(
      data["completed_items_credits_total"]
    );
    $("#total-items-credits").text(data["total_items_credits"]);
  }

  _validationsCableReceived(data) {
    $(this.statusSummaryTarget)
      .find("svg")
      .toArray()
      .forEach(function (svgEl) {
        if (svgEl.id == data["status"]) {
          svgEl.classList.remove("hidden");
          svgEl.classList.add("inline");
        } else {
          svgEl.classList.add("hidden");
        }
      });
    $(this.statusSummaryTarget).find("span")[0].innerText = data["message"];

    if (data["status"] != "success") {
      $(this.statusMessagesContainerTarget)
        .find("li")
        .toArray()
        .forEach(function (liEl) {
          liEl.remove();
        });
      this.statusMessagesContainerTarget.classList.add("flex");
      this.statusMessagesContainerTarget.classList.remove("hidden");

      let newLis = "";

      data["errors"]
        .concat(data["warnings"])
        .sort()
        .forEach(function (message) {
          newLis += `<li class="list-disc">${message}</li>`;
        });

      $(this.statusMessagesContainerTarget).find("ul")[0].innerHTML = newLis;
    }
  }

  _fulfillerCableReceived(data) {
    if (data["status"] == "done") {
      this.fulfillerImportStatusInProgressTarget.classList.add("hidden");
      this.fulfillerImportStatusDoneTarget.classList.remove("hidden");
    }
  }

  toggleCourseChangeSelector(event) {
    if (this.hasCourseChangeSelectorTarget) {
      this.courseChangeSelectorTarget.classList.toggle("hidden");

      if (!this.courseChangeSelectorTarget.classList.value.includes("hidden")) {
        this.stimulate(
          "Programs::FlightPlan::UserPathwayReflex#available_courses_page",
          event.target
        );
      }
    }
  }

  get tabsController() {
    return this.application.getControllerForElementAndIdentifier(
      this.tabControllerContainerTarget,
      "tabs"
    );
  }
}
