import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    approveState: String,
    denyState: String,
    approveInputRequired: Boolean,
  };
  static targets = [
    "newStudentIdInput",
    "decisionRadioButton",
    "decisionApproveInput",
    "decisionDenyInput",
  ];

  requireStudentId() {
    this.newStudentIdInputTarget.required = true;
    this.newStudentIdInputTarget.disabled = false;
  }

  optionalStudentId() {
    this.newStudentIdInputTarget.required = false;
    this.newStudentIdInputTarget.disabled = true;
    this.newStudentIdInputTarget.value = "";
  }

  selectApprove() {
    if (this.approveInputRequiredValue) {
      this.decisionApproveInputTarget.required = true;
      this.decisionApproveInputTarget.disabled = false;
      this.decisionApproveInputTarget.classList.remove("hidden");
    }

    this.decisionDenyInputTarget.required = false;
    this.decisionDenyInputTarget.disabled = true;
    this.decisionDenyInputTarget.classList.add("hidden");
  }

  selectDeny() {
    if (this.approveInputRequiredValue) {
      this.decisionApproveInputTarget.required = false;
      this.decisionApproveInputTarget.disabled = true;
      this.decisionApproveInputTarget.classList.add("hidden");
    }

    this.decisionDenyInputTarget.required = true;
    this.decisionDenyInputTarget.disabled = false;
    this.decisionDenyInputTarget.classList.remove("hidden");
  }

  toggleApprove(event) {
    if (event.target.value == this.approveStateValue) {
      this.selectApprove();
    } else if (event.target.value == this.denyStateValue) {
      this.selectDeny();
    }
  }
}
