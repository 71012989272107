import { Controller } from "stimulus";

export default class extends Controller {
  static values = { seconds: Number };

  connect() {
    setInterval(function () {
      window.location.reload();
    }, this.secondsValue * 1000);
  }
}
