import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["objectToToggle"];

  toggle(e) {
    if (e.target.value) {
      this.objectToToggleTargets.forEach(
        (element) => (element.disabled = false)
      );
    } else {
      this.objectToToggleTargets.forEach(
        (element) => (element.disabled = true)
      );
    }
  }
}
