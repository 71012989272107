import { Controller } from "stimulus";

export default class extends Controller {
  reloadCatalogImportOptions() {
    var cadence_option = document.getElementById(
      "sis_integration_app_course_catalog_auto_import_cadence"
    ).value;

    var run_day = document.getElementById(
      "sis_integration_app_course_catalog_auto_import_run_day"
    );
    var run_day_section = document.getElementById(
      "course_catalog_auto_import_run_day"
    );
    var import_time = document.getElementById(
      "sis_integration_app_course_catalog_auto_import_run_time"
    );
    var import_time_section = document.getElementById(
      "course_catalog_auto_import_run_time"
    );
    var placeholder_div = document.getElementById(
      "course_catalog_auto_import_placeholder_div"
    );

    switch (cadence_option) {
      case "nightly":
        run_day.value = "";
        run_day_section.hidden = true;
        import_time_section.hidden = false;
        placeholder_div.hidden = false;
        break;
      case "weekly":
        run_day_section.hidden = false;
        import_time_section.hidden = false;
        placeholder_div.hidden = true;
        break;
      case "monthly":
        run_day_section.hidden = false;
        import_time_section.hidden = false;
        placeholder_div.hidden = true;
        break;
      case "once_per_quarter":
        run_day_section.hidden = false;
        import_time_section.hidden = false;
        placeholder_div.hidden = true;
        break;
      default:
        run_day.value = "";
        run_day_section.hidden = true;
        import_time.value = null;
        import_time_section.hidden = true;
        placeholder_div.hidden = true;
    }
  }
}
