import { Controller } from "stimulus";
import CableReady from "cable_ready";
import consumer from "../channels/consumer";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  static values = { id: Number };
  static targets = ["confirmationButton", "processing"];

  connect() {
    const paymentId = this.idValue;
    StimulusReflex.register(this);

    this.channel = consumer.subscriptions.create(
      {
        channel: "TouchnetPaymentChannel",
        id: paymentId,
      },
      {
        received(data) {
          if (data.cableReady) {
            CableReady.perform(data.operations);
          }
        },
      }
    );
  }

  confirm() {
    const paymentId = this.idValue;
    this.confirmationButtonTarget.classList.add("hidden");
    this.processingTarget.classList.remove("hidden");
    this.stimulate("Payments#confirm_touchnet", paymentId);
  }

  disconnect() {
    this.channel.unsubscribe();
  }
}
