import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
import { useIntersection } from "stimulus-use";

export default class extends Controller {
  static targets = ["datacontainer"];

  connect() {
    StimulusReflex.register(this);
    useIntersection(this, this.options);
  }

  appear() {
    if (this.hasDatacontainerTarget) {
      return;
    }
    waitFor(
      3000,
      () => this.isActionCableConnectionOpen(),
      () => {
        this.stimulate(this.data.get("reflex"));
      }
    );
  }
}

function waitFor(period, condition, callback) {
  if (condition()) {
    callback();
  } else {
    window.setTimeout(waitFor.bind(null, period, condition, callback), period);
  }
}
