import { Controller } from "stimulus";
import Cookies from "js.cookie";

// TODO replace this logic with https://github.com/browserslist/browserslist
// which is already a dependency of our frontend (see yarn.lock)
export default class extends Controller {
  static targets = ["browserNotSupportedAlert"];

  connect() {
    if (!this.alertPreviouslyDismissed() && this.unsupportedBrowser()) {
      this.browserNotSupportedAlertTarget.classList.remove("hidden");
    }
  }

  alertDismissed() {
    this.browserNotSupportedAlertTarget.classList.add("hidden");
    Cookies.set("ie-message-dismissed", "t", { expires: 1 });
  }

  isChrome() {
    return navigator.userAgent.indexOf("Chrome") > -1;
  }

  isChrome61orBelow() {
    var matches = navigator.userAgent.match("Chrome" + "[ /]+[0-9]+(.[0-9]+)*");
    if (matches) {
      var version = matches[0].split("/").pop();
      // Edge reports itself as Chrome 61 for some reason
      return !this.isEdge() && this.isChrome() && parseInt(version) < 62;
    } else {
      return false;
    }
  }

  isSafari() {
    var hasChrome = navigator.userAgent.indexOf("Chrome") > -1;
    var hasSafari = navigator.userAgent.indexOf("Safari") > -1;
    return hasSafari && !hasChrome;
  }

  isSafariBelow10() {
    var matchesSafari = navigator.userAgent.match(
      "Safari" + "[ /]+[0-9]+(.[0-9]+)*"
    );
    var matchesVersion = navigator.userAgent.match("Version/([0-9.]{1,5})");
    if (matchesSafari && matchesVersion) {
      var version = matchesVersion[0].split("/").pop();
      return this.isSafari() && parseInt(version) <= 9;
    } else {
      return false;
    }
  }

  isFirefox() {
    return navigator.userAgent.indexOf("Firefox") > -1;
  }

  isFirefox56orBelow() {
    var matches = navigator.userAgent.match(
      "Firefox" + "[ /]+[0-9]+(.[0-9]+)*"
    );
    if (matches) {
      var version = matches[0].split("/").pop();
      return this.isFirefox() && parseInt(version) < 56;
    } else {
      return false;
    }
  }

  isEdge() {
    return navigator.userAgent.indexOf("Edge") > -1;
  }

  isEdge15orBelow() {
    var matches = navigator.userAgent.match("Edge" + "[ /]+[0-9]+(.[0-9]+)*");
    if (matches) {
      var version = matches[0].split("/").pop();
      return this.isEdge() && parseInt(version) < 15;
    } else {
      return false;
    }
  }

  isExplorer10OrBelow() {
    return navigator.userAgent.indexOf("MSIE") > -1;
  }

  isMobile() {
    return this.isExplicitlyMobile() || this.isAndroid();
  }

  isExplicitlyMobile() {
    return window.navigator.userAgent.indexOf("Mobile") > -1;
  }

  isAndroid() {
    return window.navigator.userAgent.indexOf("Android") > -1;
  }

  isDesktopSafari() {
    return this.isSafari() && !this.isMobile();
  }

  alertPreviouslyDismissed() {
    return Cookies.get("ie-message-dismissed") === "t";
  }

  unsupportedBrowser() {
    return (
      this.isFirefox56orBelow() ||
      this.isExplorer10OrBelow() ||
      this.isChrome61orBelow() ||
      this.isSafariBelow10() ||
      this.isEdge15orBelow()
    );
  }
}
