import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source", "filterable", "noResults", "signInForm"];

  localSignInForm(event) {
    this.signInFormTarget.hidden = !this.signInFormTarget.hidden;

    let form = this.signInFormTarget.getElementsByClassName("new-user")[0];

    if (!this.signInFormTarget.hidden) {
      form.querySelector("input[type=email]").focus();
      this.signInFormTarget.getElementsByClassName("form_title")[0].innerHTML =
        event.currentTarget.getAttribute("data-title");
    }
  }

  filter() {
    let criteria = this.sourceTarget.value.toLowerCase();
    this.signInFormTarget.hidden = true;
    let elements = [];

    if (criteria.length > 0) {
      // first hide all elements
      this.filterableTargets.forEach((el) => {
        el.classList.add("hidden");
      });

      this.filterableTargets.forEach((el) => {
        let text_content = el.textContent
          .replace(/(\r\n|\n|\r)/gm, "")
          .toLowerCase();

        try {
          if (text_content.match(new RegExp(criteria, "ig")).length > 0) {
            elements.push(el);
            el.classList.remove("hidden");
          }
        } catch (error) {
          console.log("no matches");
        }
      });

      if (parseInt(elements.length) == 0) {
        this.noResultsTarget.classList.remove("hidden");
      } else {
        this.noResultsTarget.classList.add("hidden");
      }
    } else {
      // if less or equal to 1 character just show up everything again
      this.filterableTargets.forEach((el) => {
        el.classList.remove("hidden");
        this.noResultsTarget.classList.add("hidden");
      });
    }
  }
}
