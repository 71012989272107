import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    skipAuth: Boolean,
  };

  connect() {
    if (this.skipAuthValue) return;
    document.getElementById("header-sign-in-link-hidden").click();
  }
}
