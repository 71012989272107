import { Controller } from "stimulus";

export default class extends Controller {
  selectCourse() {
    var course_select = document.getElementById(
      "program_flight_plan_item_course_id"
    );
    var allow_course_change_checkbox = document.getElementById(
      "program_flight_plan_item_allow_course_change"
    );
    var allow_course_change_placeholder = document.getElementById(
      "program_flight_plan_item_allow_course_change_placeholder"
    );
    var transfer_not_allowed_checkbox = document.getElementById(
      "program_flight_plan_item_transfer_not_allowed"
    );
    var transfer_not_allowed_placeholder = document.getElementById(
      "program_flight_plan_item_transfer_not_allowed_placeholder"
    );

    if (course_select.value == "") {
      allow_course_change_checkbox.checked = true;
      allow_course_change_placeholder.checked = true;
      allow_course_change_checkbox.parentElement.parentElement.hidden = true;
      allow_course_change_placeholder.parentElement.parentElement.hidden = false;
      transfer_not_allowed_checkbox.parentElement.parentElement.hidden = false;
      transfer_not_allowed_placeholder.parentElement.parentElement.hidden = true;
    } else {
      allow_course_change_checkbox.parentElement.parentElement.hidden = false;
      allow_course_change_placeholder.parentElement.parentElement.hidden = true;
    }

    this.selectAllowCourseChange();
  }

  selectAllowCourseChange() {
    var allow_course_change_checkbox = document.getElementById(
      "program_flight_plan_item_allow_course_change"
    );
    var transfer_not_allowed_checkbox = document.getElementById(
      "program_flight_plan_item_transfer_not_allowed"
    );
    var transfer_not_allowed_placeholder = document.getElementById(
      "program_flight_plan_item_transfer_not_allowed_placeholder"
    );

    if (!allow_course_change_checkbox.parentElement.parentElement.hidden) {
      if (!allow_course_change_checkbox.checked) {
        transfer_not_allowed_checkbox.checked = true;
        transfer_not_allowed_placeholder.checked = true;
        transfer_not_allowed_checkbox.parentElement.parentElement.hidden = true;
        transfer_not_allowed_placeholder.parentElement.parentElement.hidden = false;
      } else {
        transfer_not_allowed_checkbox.parentElement.parentElement.hidden = false;
        transfer_not_allowed_placeholder.parentElement.parentElement.hidden = true;
      }
    }
  }
}
