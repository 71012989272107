import { Controller } from "stimulus";
import React from "react";
import ReactDOM from "react-dom/client";

import { darkTheme, lightTheme } from "../../packs/theme";
import { ThemeProvider, FormControlLabel, Checkbox } from "@mui/material";

export default class extends Controller {
  static values = {
    props: Object,
  };

  connect() {
    const root = ReactDOM.createRoot(this.element);
    const theme = this.propsValue.theme || lightTheme;
    const dataControllerTarget =
      "data-" + this.propsValue.controllername + "-target";
    root.render(
      <ThemeProvider theme={theme}>
        <FormControlLabel
          control={
            <Checkbox
              name={this.propsValue.name}
              disabled={this.propsValue.disabled === true}
              value={1}
              inputProps={{
                [dataControllerTarget]: this.propsValue.target,
                "data-action": this.propsValue.action,
              }}
            />
          }
          label={this.propsValue.label}
          componentsProps={{
            typography: {
              variant: "bodyMedium",
            },
          }}
        />
      </ThemeProvider>
    );
  }
}
