import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "courseUniversitySelect",
    "sectionUniversitySelect",
    "sectionSelect",
    "courseSelect",
    "submitButton",
  ];

  connect() {
    this.inputChanged();
  }
  validate() {
    var courseUniversity =
      this.courseUniversitySelectTarget.options[
        this.courseUniversitySelectTarget.selectedIndex
      ].value;
    var sectionUniversity =
      this.sectionUniversitySelectTarget.options[
        this.sectionUniversitySelectTarget.selectedIndex
      ].value;
    var courseSelected =
      this.courseSelectTarget.options[this.courseSelectTarget.selectedIndex]
        .value;
    var sectionSelected =
      this.sectionSelectTarget.options[this.sectionSelectTarget.selectedIndex]
        .value;
    return (
      courseUniversity != sectionUniversity &&
      courseSelected != "" &&
      sectionSelected != ""
    );
  }

  inputChanged() {
    if (this.validate()) {
      this.submitButtonTarget.disabled = false;
    }
  }
}
