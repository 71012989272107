/*globals $ */

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["universitySelect", "courseSelect", "sectionSelect"];

  loadSectionsOptions() {
    let self = this;
    $.ajax({
      url:
        "/api/universities/" +
        this.universitySelectTarget.options[
          this.universitySelectTarget.selectedIndex
        ].value +
        "/courses/" +
        this.courseSelectTarget.options[this.courseSelectTarget.selectedIndex]
          .value +
        "/offerings?template=options_for_select",
      dataType: "html",
      cache: false,
    }).done(function (result) {
      self.sectionSelectTarget.innerHTML = result;
    });
  }

  emptySection() {
    this.sectionSelectTarget.selectedIndex = 0;
  }
}
