import { Controller } from "stimulus";

export default class extends Controller {
  toggle(event) {
    const element = event.currentTarget;
    const selector = element.dataset.selector;
    const targetElements = document.querySelectorAll(selector);

    if (targetElements.length) {
      targetElements.forEach((element) => {
        element.classList.toggle("hidden");
      });
    }
  }
}
