import React, { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Popover,
  Box,
  Button,
  Typography,
  ThemeProvider,
} from "@mui/material";

import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";

import { lightTheme } from "./theme";

export function SearchSort(props) {
  const mdBreakpoint = useMediaQuery(lightTheme.breakpoints.up("md"));

  const [sortAnchorEl, setSortAnchorEl] = React.useState(null);
  const sortPopoverIsOpen = Boolean(sortAnchorEl);

  const sortPopoverId = "sort-popover";
  const openSort = (event) => {
    setSortAnchorEl(event.currentTarget);
    document.body.classList.add("popover-open");
  };

  const [value, setValue] = React.useState(props.sortSelected);
  const textValue = props.sortSelectedText;
  const handleRadioChange = (event) => {
    setValue(event.target.value);
    onClose();
    submitForm();
  };
  const formContainer = document.getElementById("new_filter");

  const onClose = () => {
    setSortAnchorEl(null);
    document.body.classList.remove("popover-open");
  };

  const submitForm = () => {
    setTimeout(() => {
      document.getElementById("new_filter_submit").click();
      // formContainer.submit();
    }, 1);
  };
  if (!mdBreakpoint) return null;
  return (
    <ThemeProvider theme={lightTheme}>
      <FormControl
        size="small"
        sx={{
          height: 44,
          minWidth: 172,
          m: 0,
        }}
      >
        <Button
          variant="sort"
          color="primary"
          type="button"
          disableFocusRipple={true}
          onClick={openSort}
          startIcon={<FilterListIcon />}
          endIcon={sortPopoverIsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexGrow: 1,
            boxShadow: sortPopoverIsOpen ? `0 0 0 2px #1271C4` : "",
          }}
          aria-describedby={sortPopoverId}
        >
          <Box
            sx={{
              flexGrow: 1,
              textAlign: "left",
            }}
          >
            <Typography
              variant="bodyMediumBase"
              color="text.primary"
              component="span"
            >
              Sort By
            </Typography>{" "}
            {textValue}
          </Box>
        </Button>
        <Popover
          id={sortPopoverId}
          container={formContainer}
          keepMounted
          open={sortPopoverIsOpen}
          onClose={onClose}
          anchorEl={sortAnchorEl}
          anchorOrigin={{
            vertical: 52,
            horizontal: "left",
          }}
          componentsProps={{
            backdrop: {
              style: {
                backgroundColor: "transparent",
              },
            },
          }}
          PaperProps={{
            sx: {
              borderRadius: 5.5,
              display: "flex",
              flexDirection: "column",
              p: 6,
            },
          }}
        >
          <FormControl>
            <FormLabel id="sort-label">
              <Typography
                variant="eyebrowSmall"
                color="text.secondary"
                sx={{
                  mb: 6,
                }}
              >
                Sort By
              </Typography>
            </FormLabel>
            <RadioGroup
              aria-labelledby="sort-label"
              name="filter[sort]"
              value={value}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="created_at_desc"
                control={<Radio />}
                label="Newest to Oldest"
              />
              <FormControlLabel
                value="alpha_asc"
                control={<Radio />}
                label="A to Z"
              />
              <FormControlLabel
                value="alpha_desc"
                control={<Radio />}
                label="Z to A"
              />
              <FormControlLabel
                value="credits_desc"
                control={<Radio />}
                label="Most Credits"
              />
              <FormControlLabel
                value="credits_asc"
                control={<Radio />}
                label="Fewest Credits"
              />
            </RadioGroup>
          </FormControl>
        </Popover>
      </FormControl>
    </ThemeProvider>
  );
}
