import { Controller } from "stimulus";
import React from "react";
import ReactDOM from "react-dom/client";

import { Filters } from "../../packs/resultsFilters";

export default class extends Controller {
  static values = {
    searchKey: String,
    institutions: Array,
    selectedInstitutions: Array,
    subjects: Array,
    selectedSubjects: Array,
    sessions: Array,
    selectedSessions: Array,
    deliveryMethods: Array,
    selectedMethods: Array,
    selectedSelfPaced: Boolean,
    credits: Object,
    days: Array,
    selectedDays: Array,
    startDate: String,
    endDate: String,
    sortSelected: String,
    sortSelectedText: String,
  };

  connect() {
    const root = ReactDOM.createRoot(this.element);
    root.render(
      <Filters
        searchKey={this.searchKeyValue}
        institutions={this.institutionsValue}
        selectedInstitutions={this.selectedInstitutionsValue}
        subjects={this.subjectsValue}
        selectedSubjects={this.selectedSubjectsValue}
        credits={this.creditsValue}
        sessions={this.sessionsValue}
        selectedSessions={this.selectedSessionsValue}
        deliveryMethods={this.deliveryMethodsValue}
        selectedMethods={this.selectedMethodsValue}
        selectedSelfPaced={this.selectedSelfPacedValue}
        days={this.daysValue}
        selectedDays={this.selectedDaysValue}
        startDate={this.startDateValue}
        endDate={this.endDateValue}
        sortSelected={this.sortSelectedValue}
        sortSelectedText={this.sortSelectedTextValue}
      />
    );
  }
}
