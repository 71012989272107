import React, { useState } from "react";
import {
  ThemeProvider,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";

import { lightTheme } from "./theme";

export function FormSelect(props) {
  const { properties, options } = props;

  const theme = lightTheme;

  const [selectValue, setSelectValue] = React.useState("");
  const handleChange = (event) => {
    setSelectValue(event.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
      <FormControl sx={{ m: 0 }}>
        <InputLabel id={`${properties.name}-label`}>
          {properties.label} {properties.required && "*"}
        </InputLabel>
        <Select
          labelId={`${properties.name}-label`}
          id={properties.id}
          name={properties.name}
          value={selectValue}
          label={properties.label}
          onChange={handleChange}
          required={properties.required}
          MenuProps={{
            style: {
              maxHeight: 600,
            },
          }}
        >
          {options.map(({ value, name }, index) => (
            <MenuItem value={value} key={index}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
}
