import { Controller } from "stimulus";
import React from "react";
import ReactDOM from "react-dom/client";
import StimulusReflex from "stimulus_reflex";
import SyncRoundedIcon from "@mui/icons-material/SyncRounded";

import { darkTheme, lightTheme } from "../../packs/theme";
import { Button, ThemeProvider } from "@mui/material";

export default class extends Controller {
  static values = {
    props: Object,
  };

  connect() {
    StimulusReflex.register(this);
    this.root = ReactDOM.createRoot(this.element);
    this.render();
  }

  beforeReflex(_element, _reflex) {
    this.root.unmount();
  }

  afterReflex(_element, _reflex) {
    this.root = ReactDOM.createRoot(this.element);
    this.render();
  }

  enableButton() {
    this.render("false");
  }

  disableButton() {
    this.render(true);
  }

  render(disabled) {
    const theme = this.propsValue.theme || lightTheme;

    let buttonDisabled = (disabled || this.propsValue.disabled) === true;
    const otherProps = {
      [`data-${this.propsValue.controllerName}-target`]: this.propsValue.target,
    };

    let endIcon = null;
    if (this.propsValue.loading && buttonDisabled) {
      endIcon = <SyncRoundedIcon className="animate-spin" />;
    }

    let buttonText = "";
    if (buttonDisabled) {
      buttonText = this.propsValue.disabledText || this.propsValue.text;
    } else {
      buttonText = this.propsValue.text;
    }

    this.root.render(
      <ThemeProvider theme={theme}>
        <Button
          color={this.propsValue.color}
          variant={this.propsValue.variant}
          href={
            this.propsValue.href
              ? `${this.propsValue.href}${
                  this.propsValue.paramsToEncode
                    ? encodeURIComponent(this.propsValue.paramsToEncode)
                    : ""
                }`
              : null
          }
          sx={this.propsValue.sx}
          disabled={buttonDisabled}
          type={this.propsValue.type}
          className={this.propsValue.classes}
          id={this.propsValue.id}
          data-action={this.propsValue.action}
          endIcon={endIcon}
          {...otherProps}
        >
          {buttonText}
        </Button>
      </ThemeProvider>
    );
  }
}
