import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source", "filterable", "noResults"];

  connect() {
    this.sourceTarget.focus();
  }

  filter(event) {
    if (event.keyCode == 27) {
      this.sourceTarget.value = "";
    }
    let criteria = this.sourceTarget.value.toLowerCase();
    let elements = [];

    if (criteria.length > 0) {
      // first hide all elements
      this.filterableTargets.forEach((el) => {
        el.classList.add("not-found");
      });

      this.filterableTargets.forEach((el) => {
        let text_content = el.textContent
          .replace(/(\r\n|\n|\r)/gm, "")
          .toLowerCase();

        try {
          if (text_content.match(new RegExp(criteria, "ig")).length > 0) {
            elements.push(el);
            el.classList.remove("not-found");
          }
        } catch (error) {
          console.log("no matches");
        }
      });

      if (parseInt(elements.length) == 0) {
        this.noResultsTarget.classList.remove("hidden");
      } else {
        this.noResultsTarget.classList.add("hidden");
      }
    } else {
      // if less or equal to 1 character just show up everything again
      this.filterableTargets.forEach((el) => {
        el.classList.remove("not-found");
        this.noResultsTarget.classList.add("hidden");
      });
    }

    document
      .querySelectorAll(".quottly-admin-links-container .links-group")
      .forEach((el) => {
        if (
          el.querySelectorAll(".not-found").length ==
          el.querySelectorAll(".links-link").length
        ) {
          el.classList.add("hidden");
        } else {
          el.classList.remove("hidden");
        }
      });
    document
      .querySelectorAll(".quottly-admin-links-container .links-column")
      .forEach((el) => {
        if (
          el.querySelectorAll(".links-group").length ==
          el.querySelectorAll(".links-group.hidden").length
        ) {
          el.classList.add("hidden");
        } else {
          el.classList.remove("hidden");
        }
      });
    document
      .querySelectorAll(".quottly-admin-links-container")
      .forEach((el) => {
        if (
          el.querySelectorAll(".links-column").length ==
          el.querySelectorAll(".links-column.hidden").length
        ) {
          el.classList.add("hidden");
        } else {
          el.classList.remove("hidden");
        }
      });
  }

  clearSearch() {
    this.sourceTarget.value = "";
    this.filterableTargets.forEach((el) => {
      el.classList.remove("not-found");
    });
    this.noResultsTarget.classList.add("hidden");
    document
      .querySelectorAll(".quottly-admin-links-container .links-group")
      .forEach((el) => {
        el.classList.remove("hidden");
      });
    document
      .querySelectorAll(".quottly-admin-links-container .links-column")
      .forEach((el) => {
        el.classList.remove("hidden");
      });
    document
      .querySelectorAll(".quottly-admin-links-container")
      .forEach((el) => {
        el.classList.remove("hidden");
      });
  }
}
