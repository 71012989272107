import React, { Fragment, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { lightTheme } from "./theme";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  List,
  ListItem,
  FormControlLabel,
  Checkbox,
  TextField,
  Box,
  Badge,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@mui/material";

import {
  SearchOutlined as SearchOutlinedIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

export function AllFiltersAccordion(props) {
  const {
    sortValue,
    setSortValue,
    sortTextValue,
    setSortTextValue,
    deliveryMethods,
    selectedMethods,
    setSelectedMethods,
    minCredsValue,
    setMinCredsValue,
    maxCredsValue,
    setMaxCredsValue,
    sessions,
    selectedSessions,
    setSelectedSessions,
    selectedSelfPaced,
    setSelectedSelfPaced,
    allFiltersSearch,
    creditsRangeError,
    setCreditsRangeError,
    minCreditsErrorMessage,
    setMinCreditsErrorMessage,
    maxCreditsErrorMessage,
    setMaxCreditsErrorMessage,
  } = props;

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSessionsSelection = (event) => {
    let value = event.target.value;
    if (selectedSessions.includes(value)) {
      setSelectedSessions([...selectedSessions.filter((id) => id !== value)]);
    } else {
      setSelectedSessions([...selectedSessions, value]);
    }
  };

  const handleMethodsSelection = (event) => {
    let value = event.target.value;
    if (selectedMethods.includes(value)) {
      setSelectedMethods([...selectedMethods.filter((id) => id !== value)]);
    } else {
      setSelectedMethods([...selectedMethods, value]);
    }
  };

  const handleSelfPacedSelection = (event) => {
    setSelectedSelfPaced(event.target.checked);
  };

  const handleSortRadioChange = (event) => {
    setSortValue(event.target.value);
    setSortTextValue(event.target.labels[0].innerText);
  };

  const handleCreditsChange = (minimumCredits, maximumCredits) => {
    if (parseInt(minimumCredits) > parseInt(maximumCredits)) {
      setCreditsRangeError(true);
      setMinCreditsErrorMessage("Invalid Credits Range");
    } else {
      setCreditsRangeError(false);
      setMinCreditsErrorMessage("");
      setMaxCreditsErrorMessage("");
    }

    if (parseInt(minimumCredits) < 0) {
      setCreditsRangeError(true);
      setMinCreditsErrorMessage("Min Credits cannot be lower than 0");
    }

    if (parseInt(maximumCredits) > 20) {
      setCreditsRangeError(true);
      setMaxCreditsErrorMessage("Max Credits cannot be greter than 20");
    }
  };

  const mdBreakpoint = useMediaQuery(lightTheme.breakpoints.up("md"));

  return (
    <>
      {!mdBreakpoint && (
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          square={true}
          sx={{
            backgroundColor: "transparent",
            minHeight: 0,
            py: 4,
            px: 0,
            mx: 6,
            borderBottom: "1px solid",
            borderColor: "common.hoverFill",
            borderRadius: "0",
            boxShadow: "none",
            "&:before": {
              display: "none",
            },
            "&.Mui-expanded": {
              my: 0,
              mx: 6,
            },
            ".MuiAccordionSummary-content": {
              my: 0,
            },
            ".MuiAccordionSummary-content.Mui-expanded": {
              my: 0,
            },
            ".MuiAccordionSummary-root.Mui-expanded": {
              minHeight: 0,
              mb: 2,
            },
            ".MuiCollapse-wrapperInner": {
              mb: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: "text.primary",
                }}
              />
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{
              backgroundColor: "transparent",
              minHeight: 0,
              px: 0,
              ".MuiBadge-badge": {
                transform: "scale(1) translate(140%, -10%)",
              },
              ".MuiBadge-invisible": {
                transform: "scale(0) translate(50%, -50%)",
              },
            }}
          >
            <FormLabel
              id="sort-label"
              sx={{
                display: "flex",
                gap: 2,
              }}
            >
              <Typography variant="eyebrowSmall" color="text.secondary">
                Sort By
              </Typography>
              <Typography variant="eyebrowSmall" color="primary">
                {sortTextValue}
              </Typography>
            </FormLabel>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              p: 0,
              mt: 2,
            }}
          >
            <FormControl>
              <RadioGroup
                aria-labelledby="sort-label"
                name="filter[sort]"
                value={sortValue}
                onChange={handleSortRadioChange}
              >
                <FormControlLabel
                  value="created_at_desc"
                  control={<Radio size="small" />}
                  label="Newest to Oldest"
                  componentsProps={{
                    typography: {
                      variant: "bodyMedium",
                    },
                  }}
                />
                <FormControlLabel
                  value="alpha_asc"
                  control={<Radio size="small" />}
                  label="A to Z"
                  componentsProps={{
                    typography: {
                      variant: "bodyMedium",
                    },
                  }}
                />
                <FormControlLabel
                  value="alpha_desc"
                  control={<Radio size="small" />}
                  label="Z to A"
                  componentsProps={{
                    typography: {
                      variant: "bodyMedium",
                    },
                  }}
                />
                <FormControlLabel
                  value="credits_desc"
                  control={<Radio size="small" />}
                  label="Most Credits"
                  componentsProps={{
                    typography: {
                      variant: "bodyMedium",
                    },
                  }}
                />
                <FormControlLabel
                  value="credits_asc"
                  control={<Radio size="small" />}
                  label="Fewest Credits"
                  componentsProps={{
                    typography: {
                      variant: "bodyMedium",
                    },
                  }}
                />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      )}
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        square={true}
        sx={{
          backgroundColor: "transparent",
          minHeight: 0,
          py: 4,
          px: 0,
          mx: 6,
          borderBottom: "1px solid",
          borderColor: "common.hoverFill",
          borderRadius: "0",
          boxShadow: "none",
          "&:before": {
            display: "none",
          },
          "&.Mui-expanded": {
            my: 0,
            mx: 6,
          },
          ".MuiAccordionSummary-content": {
            my: 0,
          },
          ".MuiAccordionSummary-content.Mui-expanded": {
            my: 0,
          },
          ".MuiAccordionSummary-root.Mui-expanded": {
            minHeight: 0,
            mb: 2,
          },
          ".MuiCollapse-wrapperInner": {
            mb: 0,
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                color: "text.primary",
              }}
            />
          }
          aria-controls="panel2bh-content"
          id="panel2bh-header"
          sx={{
            backgroundColor: "transparent",
            minHeight: 0,
            px: 0,
            ".MuiBadge-badge": {
              transform: "scale(1) translate(140%, -10%)",
            },
            ".MuiBadge-invisible": {
              transform: "scale(0) translate(50%, -50%)",
            },
          }}
        >
          <Typography color="text.secondary" variant="eyebrowSmall">
            Term
          </Typography>
          <Badge
            badgeContent={
              selectedSelfPaced
                ? selectedSessions.length + 1
                : selectedSessions.length
            }
            color="primary"
          />
        </AccordionSummary>
        <AccordionDetails
          sx={{
            p: 0,
            mt: 2,
          }}
        >
          <List
            sx={{
              p: 0,
            }}
          >
            {sessions.map((session, index) => (
              <ListItem
                key={index}
                sx={{
                  p: 0,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      value={session}
                      onChange={handleSessionsSelection}
                      checked={selectedSessions.includes(session)}
                      name="filter[session_names][]"
                    />
                  }
                  label={session}
                  componentsProps={{
                    typography: {
                      variant: "bodyMedium",
                    },
                  }}
                />
              </ListItem>
            ))}
            <ListItem
              key={props.sessions.lenght}
              sx={{
                p: 0,
              }}
            >
              <input
                type="hidden"
                name="filter[show_self_paced]"
                value="false"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="true"
                    onChange={handleSelfPacedSelection}
                    checked={selectedSelfPaced}
                    name="filter[show_self_paced]"
                  />
                }
                label="Self Paced"
                componentsProps={{
                  typography: {
                    variant: "bodyMedium",
                  },
                }}
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        square={true}
        sx={{
          backgroundColor: "transparent",
          minHeight: 0,
          py: 4,
          px: 0,
          mx: 6,
          borderBottom: "1px solid",
          borderColor: "common.hoverFill",
          borderRadius: "0",
          boxShadow: "none",
          "&:before": {
            display: "none",
          },
          "&.Mui-expanded": {
            my: 0,
            mx: 6,
          },
          ".MuiAccordionSummary-content": {
            my: 0,
          },
          ".MuiAccordionSummary-content.Mui-expanded": {
            my: 0,
          },
          ".MuiAccordionSummary-root.Mui-expanded": {
            minHeight: 0,
            mb: 2,
          },
          ".MuiCollapse-wrapperInner": {
            mb: 0,
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                color: "text.primary",
              }}
            />
          }
          aria-controls="panel3bh-content"
          id="panel3bh-header"
          sx={{
            backgroundColor: "transparent",
            minHeight: 0,
            px: 0,
            ".MuiBadge-badge": {
              transform: "scale(1) translate(140%, -10%)",
            },
            ".MuiBadge-invisible": {
              transform: "scale(0) translate(50%, -50%)",
            },
          }}
        >
          <Typography color="text.secondary" variant="eyebrowSmall">
            Credits
          </Typography>
          <Badge
            badgeContent={minCredsValue > 0 || maxCredsValue < 20 ? 1 : 0}
            color="primary"
          />
        </AccordionSummary>
        <AccordionDetails
          sx={{
            p: 0,
            mt: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 3,
            }}
          >
            <TextField
              variant="outlined"
              label="Min"
              type="number"
              id="filter_min_credits_range"
              name="filter[min_credits_range]"
              value={minCredsValue}
              onChange={(event) => {
                handleCreditsChange(event.target.value, maxCredsValue);
                setMinCredsValue(event.target.value);
              }}
              InputProps={{
                inputProps: { min: 0, max: maxCredsValue, step: 1 },
              }}
              sx={{
                width: "150px",
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  if (!creditsRangeError) {
                    allFiltersSearch();
                  }
                }
              }}
              error={creditsRangeError}
              helperText={minCreditsErrorMessage}
            />
            <TextField
              variant="outlined"
              label="Max"
              type="number"
              id="filter_max_credits_range"
              name="filter[max_credits_range]"
              value={maxCredsValue}
              onChange={(event) => {
                handleCreditsChange(minCredsValue, event.target.value);
                setMaxCredsValue(event.target.value);
              }}
              InputProps={{
                inputProps: { min: minCredsValue, max: 20, step: 1 },
              }}
              sx={{
                width: "150px",
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  if (!creditsRangeError) {
                    allFiltersSearch();
                  }
                }
              }}
              error={creditsRangeError}
              helperText={maxCreditsErrorMessage}
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
        square={true}
        sx={{
          backgroundColor: "transparent",
          minHeight: 0,
          py: 4,
          px: 0,
          mx: 6,
          borderBottom: "1px solid",
          borderColor: "common.hoverFill",
          borderRadius: "0",
          boxShadow: "none",
          "&:before": {
            display: "none",
          },
          "&.Mui-expanded": {
            my: 0,
            mx: 6,
          },
          ".MuiAccordionSummary-content": {
            my: 0,
          },
          ".MuiAccordionSummary-content.Mui-expanded": {
            my: 0,
          },
          ".MuiAccordionSummary-root.Mui-expanded": {
            minHeight: 0,
            mb: 2,
          },
          ".MuiCollapse-wrapperInner": {
            mb: 0,
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                color: "text.primary",
              }}
            />
          }
          aria-controls="panel4bh-content"
          id="panel4bh-header"
          sx={{
            backgroundColor: "transparent",
            minHeight: 0,
            px: 0,
            ".MuiBadge-badge": {
              transform: "scale(1) translate(140%, -10%)",
            },
            ".MuiBadge-invisible": {
              transform: "scale(0) translate(50%, -50%)",
            },
          }}
        >
          <Typography color="text.secondary" variant="eyebrowSmall">
            Learning Format
          </Typography>
          <Badge badgeContent={selectedMethods.length} color="primary" />
        </AccordionSummary>
        <AccordionDetails
          sx={{
            p: 0,
            mt: 2,
          }}
        >
          <List
            sx={{
              p: 0,
            }}
          >
            {deliveryMethods.map(({ value, name }, index) => (
              <ListItem
                key={index}
                sx={{
                  p: 0,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      value={value}
                      onChange={handleMethodsSelection}
                      checked={selectedMethods.includes(value)}
                      name="filter[delivery_methods][]"
                    />
                  }
                  label={name}
                  componentsProps={{
                    typography: {
                      variant: "bodyMedium",
                    },
                  }}
                />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
