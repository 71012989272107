import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "form",
    "allowNotifications",
    "allowEmailNotifications",
    "allowPhoneNotifications",
    "notificationsEmailAddress",
    "notificationsPhoneNumber",
    "notificationsFrequency",
    "notificationsFieldsContainer",
  ];

  connect() {
    this.toggleItemCheckbox();
  }

  toggleNotificationOptionsVisibility() {
    this.allowEmailNotificationsTarget.checked =
      this.allowNotificationsTarget.checked;
    this.allowPhoneNotificationsTarget.checked =
      this.allowNotificationsTarget.checked;
    this.toggleRequiredFields();
    this.toggleNotificationFields();
  }

  toggleNotificationFields() {
    this.allowNotificationsTarget.checked
      ? this.notificationsFieldsContainerTarget.classList.remove("hidden")
      : this.notificationsFieldsContainerTarget.classList.add("hidden") &&
        this.clearNotificationFields();
  }

  toggleRequiredFields() {
    this.notificationsFrequencyTarget.required =
      this.allowEmailNotificationsTarget.checked ||
      this.allowPhoneNotificationsTarget.checked;
    this.notificationsEmailAddressTarget.required =
      this.allowEmailNotificationsTarget.checked;
    this.notificationsPhoneNumberTarget.required =
      this.allowPhoneNotificationsTarget.checked;
    this.clearEmail();
    this.clearPhone();
    !this.allowNotificationsTarget.checked && this.clearFrequency();
  }

  toggleItemCheckbox() {
    this.allowNotificationsTarget.checked =
      this.allowEmailNotificationsTarget.checked ||
      this.allowPhoneNotificationsTarget.checked;
    this.toggleRequiredFields();
    this.toggleNotificationFields();
  }

  clearEmail() {
    !this.allowEmailNotificationsTarget.checked &&
      (this.notificationsEmailAddressTarget.value = null);
    this.notificationsEmailAddressTarget.disabled =
      !this.allowEmailNotificationsTarget.checked;
  }
  clearPhone() {
    !this.allowPhoneNotificationsTarget.checked &&
      (this.notificationsPhoneNumberTarget.value = null);
    this.notificationsPhoneNumberTarget.disabled =
      !this.allowPhoneNotificationsTarget.checked;
  }
  clearFrequency() {
    this.notificationsFrequencyTarget.value = null;
  }
}
