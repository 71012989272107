import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "authenticatesInTypeInput",
    "authenticatesInIdInput",
    "authenticatesInUniversityInput",
    "authenticatesInHighSchoolInput",
  ];

  connect() {}

  selectUniversity() {
    // nasty trick to keep own value selected after jquery propagates the event
    let currentOption = this.currentOption(
      this.authenticatesInUniversityInputTarget
    );
    if (currentOption) {
      this.authenticatesInTypeInputTarget.value = "University";
      this.authenticatesInIdInputTarget.value = currentOption.value;
      this.authenticatesInHighSchoolInputTarget.selectize.clear();
      this.keepValueSelected(
        currentOption.value,
        currentOption.text,
        this.authenticatesInUniversityInputTarget
      );
    }
  }

  selectHighSchool() {
    // nasty trick to keep own value selected after jquery propagates the event
    let currentOption = this.currentOption(
      this.authenticatesInHighSchoolInputTarget
    );
    if (currentOption) {
      this.authenticatesInTypeInputTarget.value = "HighSchool";
      this.authenticatesInIdInputTarget.value = currentOption.value;
      this.authenticatesInUniversityInputTarget.selectize.clear();
      this.keepValueSelected(
        currentOption.value,
        currentOption.text,
        this.authenticatesInHighSchoolInputTarget
      );
    }
  }

  currentOption(target) {
    return target.selectize.options[target.selectize.getValue()];
  }

  keepValueSelected(id, text, target) {
    if (id) {
      target.selectize.addOption([
        {
          value: id,
          text: text,
        },
      ]);
      target.selectize.setValue(id, true);
    }
  }
}
