import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  reflexHalted(anchorElement) {
    const { reflex } = anchorElement.dataset;
    if (reflex.match(/create$|delete/)) window.location.href = "/users/sign_in";
  }
}
