import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "cancelLinkContainer",
    "seekingDegreeContainer",
    "triggerLinkContainer",
    "seekingDegreeValue",
    "seekingDegreeLabel",
    "seekingDegreeOptValue",
  ];

  connect() {}

  showInputBox() {
    this.triggerLinkContainerTarget.classList.add("hidden");
    this.cancelLinkContainerTarget.classList.remove("hidden");
    this.seekingDegreeContainerTarget.classList.remove("hidden");
    this.seekingDegreeLabelTarget.classList.add("hidden");
    this.seekingDegreeValueTarget.setAttribute("required", true);
    if (this.seekingDegreeOptValueTarget.value == "Y") {
      this.seekingDegreeValueTarget.focus();
      this.seekingDegreeValueTarget.removeAttribute("disabled");
    } else {
      this.seekingDegreeValueTarget.setAttribute("disabled", "disabled");
    }
    document.getElementById("batch_application_seeking_degree").value =
      this.seekingDegreeValueTarget.value;
    document.getElementById("batch_application_seeking_degree_opt").value =
      this.seekingDegreeOptValueTarget.value;
  }

  hideInputBox(event) {
    let default_school_name = event.target.dataset.defaultSchoolNameValue;
    let default_opt_in = event.target.dataset.defaultStudentOptValue;

    this.triggerLinkContainerTarget.classList.remove("hidden");
    this.cancelLinkContainerTarget.classList.add("hidden");
    this.seekingDegreeContainerTarget.classList.add("hidden");
    this.seekingDegreeLabelTarget.classList.remove("hidden");
    this.seekingDegreeValueTarget.removeAttribute("required");

    document.getElementById("batch_application_seeking_degree").value =
      default_school_name;
    document.getElementById("batch_application_seeking_degree_opt").value =
      default_opt_in;
  }

  updateSeekingDegree() {
    document.getElementById("batch_application_seeking_degree").value =
      this.seekingDegreeValueTarget.value;
  }

  updateSeekingDegreeOpt() {
    document.getElementById("batch_application_seeking_degree_opt").value =
      this.seekingDegreeOptValueTarget.value;

    if (this.seekingDegreeOptValueTarget.value == "N") {
      this.seekingDegreeValueTarget.value = "";
      this.seekingDegreeValueTarget.setAttribute("disabled", "disabled");
    } else {
      this.seekingDegreeValueTarget.setAttribute("required", true);
      this.seekingDegreeValueTarget.removeAttribute("disabled");
    }
  }
}
