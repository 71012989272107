import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["actionOptionInput"];

  connect() {
    console.log("connected");
  }

  actionSelectionChanged(e) {
    let targetId = e.target.value + "_input";
    this.actionOptionInputTargets.forEach(function (t) {
      if (targetId == t.id) {
        t.removeAttribute("disabled");
      } else {
        t.disabled = "disabled";
      }
    });
  }
}
