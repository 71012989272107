import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "existingPathwaySelector",
    "newPathwayInput",
    "submitButton",
  ];

  connect() {
    if (this.hasSubmitButtonTarget) {
      this.createNewPathwayMode();
    }
  }

  createNewPathwayMode() {
    this.existingPathwaySelectorTarget.removeAttribute("required");
    this.existingPathwaySelectorTarget.value = "";
    this.existingPathwaySelectorTarget.classList.add("hidden");
    this.newPathwayInputTarget.value = "";
    this.newPathwayInputTarget.classList.remove("hidden");
    this.newPathwayInputTarget.setAttribute("required", "required");
    this.submitButtonTarget.innerText = "Create pathway";
  }

  useExistingPathwayMode() {
    this.existingPathwaySelectorTarget.setAttribute("required", "required");
    this.existingPathwaySelectorTarget.value = "";
    this.existingPathwaySelectorTarget.classList.remove("hidden");
    this.newPathwayInputTarget.removeAttribute("required");
    this.newPathwayInputTarget.value = "";
    this.newPathwayInputTarget.classList.add("hidden");
    this.submitButtonTarget.innerText = "Add to pathway";
  }

  submitForm() {
    this.submitButtonTarget.disabled = "disabled";
  }
}
