import { Controller } from "stimulus";
import React from "react";
import ReactDOM from "react-dom/client";

import { darkTheme, lightTheme } from "../../packs/theme";
import { ThemeProvider, Radio } from "@mui/material";

export default class extends Controller {
  static values = {
    props: Object,
  };

  connect() {
    const root = ReactDOM.createRoot(this.element);
    const theme = this.propsValue.theme || lightTheme;
    root.render(
      <ThemeProvider theme={theme}>
        <Radio
          name={this.propsValue.name}
          checked={this.propsValue.checked}
          disabled={this.propsValue.disabled}
          size={this.propsValue.size}
          color={this.propsValue.color}
          value={this.propsValue.value}
        />
      </ThemeProvider>
    );
  }
}
