import { createTheme } from "@mui/material/styles";

const coreTheme = createTheme();

const spacing = (multiplier = 1) => multiplier * 4;

const fontFamilyBase = [
  "ui-sans-serif",
  "system-ui",
  "-apple-system",
  "BlinkMacSystemFont",
  "Segoe UI",
  "Roboto",
  "Helvetica Neue",
  "Arial",
  "Noto Sans",
  "sans-serif",
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji",
];
const fontFamilyHalyard = ["halyard-text", ...fontFamilyBase].join(",");
// const fontFamilyDrukWide = ['Druk Wide', ...fontFamilyBase].join(',')

const fontWeights = {
  normal: 400,
  medium: 500,
  bold: 700,
  heavy: 900,
};

const fontSizes = {
  smallest: 11,
  small: 12,
  normal: 14,
};

/*Base fonts*/
const displaySmallBase = {
  fontWeight: fontWeights.bold,
  fontSize: 36,
  lineHeight: "40px",
};
const bodyMediumBase = {
  fontSize: 14,
  lineHeight: "20px",
};
const headlineSmallBase = {
  fontWeight: fontWeights.medium,
  fontSize: 24,
  lineHeight: "32px",
};
const titleLargeBase = {
  fontWeight: fontWeights.bold,
  fontSize: 22,
  lineHeight: "28px",
};
const labelLargeBase = {
  fontWeight: fontWeights.medium,
  fontSize: 14,
  lineHeight: "20px",
};

const typographies = {
  fontSize: fontSizes.normal,
  fontFamily: fontFamilyHalyard,
  fontWeightBold: fontWeights.bold,
  fontWeightHeavy: fontWeights.heavy,

  displaySmall: displaySmallBase,
  displayMedium: {
    fontWeight: fontWeights.bold,
    fontSize: 44,
    lineHeight: "52px",
  },
  displayLarge: {
    ...displaySmallBase,
    [coreTheme.breakpoints.up("md")]: {
      fontSize: 56,
      lineHeight: "64px",
    },
  },
  headlineLarge: {
    ...headlineSmallBase,
    [coreTheme.breakpoints.up("md")]: {
      fontSize: 32,
      lineHeight: "40px",
    },
  },
  headlineMedium: {
    fontWeight: fontWeights.medium,
    fontSize: 28,
    lineHeight: "36px",
  },
  headlineSmall: headlineSmallBase,
  titleLarge: titleLargeBase,
  titleMedium: {
    fontWeight: fontWeights.bold,
    fontSize: 16,
    lineHeight: "22px",
  },
  titleSmall: {
    fontWeight: fontWeights.bold,
    fontSize: 14,
    lineHeight: "20px",
  },
  bodyExtraLarge: {
    ...bodyMediumBase,
    [coreTheme.breakpoints.up("md")]: {
      fontSize: 24,
      lineHeight: "36px",
    },
  },
  bodyLarge: {
    fontSize: 16,
    lineHeight: "24px",
  },
  bodyMedium: bodyMediumBase,
  bodySmall: {
    fontSize: 12,
    lineHeight: "16px",
  },
  eyebrowLarge: {
    fontWeight: fontWeights.medium,
    fontSize: 16,
    lineHeight: "24px",
    textTransform: "uppercase",
  },
  eyebrowMedium: {
    fontSize: 14,
    lineHeight: "20px",
    textTransform: "uppercase",
  },
  eyebrowStrong: {
    fontWeight: fontWeights.bold,
    fontSize: 12,
    lineHeight: "16px",
    textTransform: "uppercase",
  },
  eyebrowSmall: {
    fontSize: 11,
    lineHeight: "16px",
    textTransform: "uppercase",
  },
  labelLarge: labelLargeBase,
  labelMedium: {
    fontWeight: fontWeights.normal,
    fontSize: 12,
    lineHeight: "16px",
  },
  labelSmall: {
    fontWeight: fontWeights.medium,
    fontSize: 11,
    lineHeight: "16px",
  },
};

const palette = {
  gold: {
    light: "#EFCA91",
    main: "#E0992C",
    dark: "#E7B25F",
    contrastText: "#835E26",
  },
  cypress: {
    light: "#196150",
    main: "#196150",
    dark: "#196150",
  },
  blue: {
    light: "#B8D4ED",
    main: "#1271C4",
    dark: "#0E5A9D",
    subtleHover: "#ECF4FA",
  },
  common: {
    graphite: "#1F1F1F",
    canvas: "#FAF9F8",
    primaryDark: "#000000E5",
    secondDark: "#000000A3",
    inactiveDark: "#00000047",
    hoverFill: "#0000001F",
    white: "#FFFFFF",
    secondWhite: "#D0D0D0",
    inactiveWhite: "#5d5d5d",
    amethyst: "#9747FF",
    steel: "#21799E",
    fire: "#C85105",
    sky: "#B2E6F9",
    lightGold: "#F8F1E0",
    aqua: "#8BD3E6",
    selectedLightBlue: "#d3e2f4",
    selectedDarkGold: "#463724",
    outlinedInput: "#c4c4c4",
    outlinedDropdownHover: "#1271c40a",
    outlinedDarkDropdownHover: "#e0992c0a",
  },
  success: {
    light: "#E3F5E9",
    main: "#A2DEB4",
    dark: "#196150",
  },
  info: {
    light: "#d0e3f3",
    main: "#3498EF",
    dark: "#113573",
  },
  warning: {
    light: "#FFF4CC",
    main: "#E3F5E9",
    dark: "#925D07",
  },
  error: {
    light: "#FFF1F0",
    main: "#FF5546",
    dark: "#BB1126",
  },
};

const baseThemeOptions = {
  spacing: spacing(),
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
  typography: typographies,
  palette,
  components: {
    MuiContainer: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          paddingTop: spacing(8),
          paddingBottom: spacing(8),
          paddingRight: spacing(6),
          paddingLeft: spacing(6),
        },
      },
      variants: [
        {
          props: {
            variant: "footer",
          },
          style: ({ theme }) => ({
            "&.MuiContainer-root": {
              "@media (min-width: 1280px)": {
                paddingLeft: 0,
                paddingRight: 0,
              },
            },
          }),
        },
      ],
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          expressiveLarge: "p",
          expressiveMedium: "p",
          expressiveSmall: "p",
          expressiveExtraSmall: "p",
          displayLarge: "p",
          displayMedium: "p",
          displaySmall: "p",
          headlineLarge: "p",
          headlineMedium: "p",
          headlineSmall: "p",
          titleLarge: "p",
          titleMedium: "p",
          titleSmall: "p",
          bodyExtraLarge: "p",
          bodyLarge: "p",
          bodyMedium: "p",
          bodySmall: "p",
          eyebrowLarge: "p",
          eyebrowMedium: "p",
          eyebrowSmall: "p",
          labelLarge: "p",
          labelMedium: "p",
          labelSmall: "p",
        },
      },
      styleOverrides: {
        root: {
          "&:last-child": {
            marginBottom: 0, // The last text child should never have margin bottom, let the container deal with that.
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...labelLargeBase,
          borderRadius: 24,
          paddingTop: spacing(3),
          paddingBottom: spacing(3),
          paddingLeft: spacing(5.5),
          paddingRight: spacing(5.5),
          textTransform: "none",
        },
        outlined: {
          borderWidth: 2,
          paddingTop: spacing(2.5),
          paddingBottom: spacing(2.5),
          paddingLeft: spacing(5),
          paddingRight: spacing(5),

          ":hover": {
            borderWidth: 2,
          },
        },
        sort: {
          ...bodyMediumBase,
          color: palette.blue.main,
          fontWeight: fontWeights.regular,
          borderWidth: 2,
          paddingTop: spacing(2.5),
          paddingBottom: spacing(2.5),
          paddingLeft: spacing(5),
          paddingRight: spacing(5),

          ":hover": {
            borderWidth: 2,
          },
        },
      },
      variants: [
        {
          props: {
            variant: "outlinedDropdown",
          },
          style: ({ theme }) => ({
            border: "none",
            boxShadow: `0 0 0 1px ${theme.palette.common.outlinedInput}`,
            fontWeight: fontWeights.normal,
            paddingTop: spacing(2.5),
            paddingBottom: spacing(2.5),
            paddingLeft: spacing(4),
            paddingRight: spacing(3),
            textTransform: "none",

            "&:focus": {
              boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
            },

            "&:hover": {
              boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
              backgroundColor: theme.palette.common.outlinedDropdownHover,
            },

            "&:active": {
              boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
            },

            "& .MuiTouchRipple-root span": {
              backgroundColor: theme.palette.primary.main,
            },

            "& .MuiBadge-badge": {
              position: "relative",
              top: 0,
              left: 0,
              transform: "none",
            },

            "& .MuiBadge-invisible": {
              transform: "scale(0) translate(50%, -50%)",
            },

            "& .MuiButton-endIcon": {
              marginRight: spacing(0),
            },

            "& .MuiButton-endIcon svg": {
              color: theme.palette.primary.main,
              width: "24px",
              height: "24px",
            },
            "& .MuiButton-startIcon": {
              marginLeft: 0,
            },
            "& .MuiButton-startIcon svg": {
              color: theme.palette.primary.main,
              width: "24px",
              height: "24px",
            },
          }),
        },
        {
          props: {
            variant: "sort",
          },
          style: ({ theme }) => ({
            border: "none",
            // boxShadow: `0 0 0 1px ${theme.palette.common.outlinedInput}`,
            fontWeight: fontWeights.normal,
            paddingTop: spacing(2.5),
            paddingBottom: spacing(2.5),
            paddingLeft: spacing(4),
            paddingRight: spacing(3),
            textTransform: "none",

            "&:focus": {
              boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
            },

            "&:hover": {
              boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
              backgroundColor: theme.palette.common.outlinedDropdownHover,
            },

            "&:active": {
              boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
            },

            "& .MuiTouchRipple-root span": {
              backgroundColor: theme.palette.primary.main,
            },

            "& .MuiBadge-badge": {
              position: "relative",
              top: 0,
              left: 0,
              transform: "none",
            },

            "& .MuiBadge-invisible": {
              transform: "scale(0) translate(50%, -50%)",
            },

            "& .MuiButton-endIcon": {
              marginRight: spacing(0),
            },

            "& .MuiButton-endIcon svg": {
              color: theme.palette.primary.main,
              width: "24px",
              height: "24px",
            },
          }),
        },
        {
          props: {
            variant: "chip",
          },
          style: ({ theme }) => ({
            ...typographies.bodySmall,
            backgroundColor: theme.palette.blue.subtleHover,
            color: theme.palette.blue.main,
            fontWeight: fontWeights.medium,
            borderWidth: "1px",
            borderColor: "transparent",
            borderStyle: "solid",
            paddingTop: spacing(2),
            paddingBottom: spacing(2),
            paddingLeft: spacing(4),
            paddingRight: spacing(1),
            textTransform: "none",
            whiteSpace: "nowrap",

            "&:hover": {
              borderColor: theme.palette.blue.main,
              backgroundColor: theme.palette.blue.subtleHover,
            },

            "&:focus": {
              boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
              borderColor: theme.palette.common.white,
            },

            "&:active": {
              boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
              borderColor: theme.palette.common.white,
            },

            "& .MuiButton-endIcon": {
              marginRight: spacing(1),
            },

            "& .MuiButton-endIcon svg": {
              color: theme.palette.primary.main,
              width: "14px",
              height: "14px",
              marginTop: "2px",
            },

            "&.Mui-disabled": {
              background: "rgba(0, 0, 0, 0.12)",
              color: "rgba(0, 0, 0, 0.28)",
            },

            "&.Mui-disabled svg": {
              color: "rgba(0, 0, 0, 0.28)",
            },
          }),
        },
      ],
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: 14,
          lineHeight: "20px",
          verticalAlign: "baseline",
          outlineColor: "inherit",
          textDecorationColor: "inherit",
          textUnderlineOffset: 4,

          "&:focus": {
            outline: `2px solid ${theme.palette.primary.light}`,
            outlineOffset: 2,
          },

          "&:hover, &:focus": {
            textDecorationThickness: 2,
          },
        }),
      },
      variants: [
        {
          props: {
            variant: "menuItem",
          },
          style: ({ theme }) => ({
            ...rootTheme.typography.titleMedium,
            color: theme.palette.common.white,
            padding: "12px 16px",
            textDecorationThickness: 2,

            "&:focus": {
              outlineColor: theme.palette.primary.main,
              outlineOffset: -2,
            },

            "&:hover, &:focus": {
              textDecorationLine: "underline",
            },

            "&.selected": {
              textDecorationLine: "underline",
              textDecorationColor: theme.palette.primary.main,
            },
          }),
        },
        {
          props: {
            variant: "menuItemNew",
          },
          style: ({ theme }) => ({
            ...rootTheme.typography.titleMedium,
            color: theme.palette.common.white,
            padding: "12px 16px",
            height: "96px",
            display: "flex",
            alignItems: "center",
            borderWidth: "0 0 6px 0",
            borderColor: "transparent",
            borderStyle: "solid",
            textDecorationThickness: 2,

            "&:focus": {
              outlineColor: theme.palette.primary.main,
              outlineOffset: -2,
            },
            "&:hover": {
              textDecorationLine: "none",
            },
            "&:hover span": {
              opacity: "0.7",
            },
            "& > span": {
              marginTop: "6px",
            },
            "&.selected": {
              borderColor: theme.palette.primary.main,
            },
            "& .endIcon": {
              marginLeft: spacing(2),
            },
          }),
        },
        {
          props: {
            variant: "mobMenuItemNew",
          },
          style: ({ theme }) => ({
            ...rootTheme.typography.titleMedium,
            color: theme.palette.common.white,
            padding: "12px 16px",
            display: "inline-flex",
            alignItems: "center",
            textDecorationThickness: 2,

            "&:focus": {
              outlineColor: theme.palette.primary.main,
              outlineOffset: -2,
            },
            "&:hover": {
              textDecorationLine: "underline",
              textDecorationColor: theme.palette.common.white,
              opacity: "0.7",
            },
            "&.selected": {
              borderWidth: "0 0 6px 0",
              borderStyle: "solid",
              borderColor: theme.palette.primary.main,
            },
            "& .endIcon": {
              marginRight: spacing(-1),
              marginLeft: spacing(2),
            },
          }),
        },
        {
          props: {
            variant: "menuItemSmall",
          },
          style: ({ theme }) => ({
            color: theme.palette.common.white,
            padding: "10px 16px",
            textDecorationThickness: 2,

            "&:focus, .Mui-focusVisible &": {
              outlineColor: theme.palette.primary.main,
              outlineOffset: -2,
            },

            "&:hover, &:focus": {
              textDecorationLine: "underline",
            },

            "&.selected": {
              textDecorationLine: "underline",
              textDecorationColor: theme.palette.primary.main,
            },
          }),
        },
        {
          props: {
            variant: "linkSmall",
          },
          style: {
            fontFamily: fontFamilyHalyard,
            fontSize: 12,
            lineHeight: "16px",
          },
        },
        {
          props: {
            variant: "bodyMedium",
          },
          style: {
            fontFamily: fontFamilyHalyard,
            fontSize: 14,
            lineHeight: "20px",
          },
        },
        {
          props: {
            variant: "bodyLarge",
          },
          style: {
            fontFamily: fontFamilyHalyard,
            fontSize: 16,
            lineHeight: "24px",
            letterSpacing: "0.24px",
          },
        },
        {
          props: {
            variant: "linkInline",
          },
          style: {
            color: "inherit",
            fontSize: "inherit",
            fontWeight: "inherit",

            "&:focus": {
              outlineColor: "inherit",
            },

            "&[disabled]": {
              cursor: "default",
            },
          },
        },
        {
          props: {
            variant: "avatarNavDesktop",
          },
          style: ({ theme }) => ({
            outlineOffset: "-2px",
            outline: `2px solid transparent`,

            "&.profileIcon": {
              outlineOffset: "-2px",
              outlineColor: "transparent",
            },
            "&:focus, .Mui-focusVisible": {
              outlineOffset: "-2px",
              outlineColor: theme.palette.primary.main,
            },
            "&:hover": {
              outlineOffset: "-2px",
              outlineColor: palette.common.white,
            },
            "&:hover&:focus": {
              outlineOffset: "-2px",
              outlineWidth: "2px",
            },
          }),
        },
      ],
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          display: "flex",
          marginTop: 8,
          marginBottom: 0,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,

          // workaround - seems to be a bug where transform overrides our overrides. so adding an additional className selector
          "&.MuiInputLabel-animated": {
            transform: "translate(14px, 12px) scale(1)",
          },

          "&.MuiInputLabel-shrink": {
            transform: "translate(14px, -8px) scale(0.75)",
          },

          "&.Mui-disabled": {
            color: palette.common.inactiveWhite,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 14,
          "& input": {
            paddingTop: spacing(3),
            paddingBottom: spacing(3),
            paddingLeft: spacing(4),
          },
          "&.MuiInputBase-adornedEnd": {
            paddingRight: spacing(3),
          },
          ".MuiIconButton-edgeEnd": {
            marginRight: spacing(0),
            paddingRight: spacing(0),
            paddingLeft: spacing(0),
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingTop: spacing(3),
          paddingBottom: spacing(3),
          paddingLeft: spacing(4),
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, .5)",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          "&.MuiSnackbar-root ": {
            width: "calc(100% - 48px)",
            minWidth: "calc(100% - 48px)",
            "@media (min-width: 1200px)": {
              minWidth: 904,
            },
            top: 90,
            "@media (min-width: 900px)": {
              top: 120,
            },
          },
          ".MuiPaper-root ": {
            width: "100%",
            maxWidth: 904,
          },
        },
        anchorOriginTopCenter: {
          left: "50%",
          transform: "translateX(-50%)",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          const severity = !ownerState.severity ? "info" : ownerState.severity;

          return {
            backgroundColor: theme.palette[severity].light,
            color: theme.palette.text.primary,
            paddingTop: spacing(3),
            paddingBottom: spacing(3),
            paddingLeft: spacing(4),
            paddingRight: spacing(4),
            ".MuiAlert-icon": {
              color: theme.palette[severity].dark,
            },
            ".MuiAlert-message": {
              paddingTop: 4,
            },
            ".MuiAlert-action": {
              marginRight: -spacing(3),
              marginTop: -spacing(3),
              ".MuiSvgIcon-root": {
                width: 16,
                height: 16,
              },
            },
          };
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          ".MuiPaper-root": {
            backgroundImage: "none",
          },
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        align: "center",
        component: "h1",
        variant: "displaySmall",
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: palette.common.inactiveWhite,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: palette.common.inactiveWhite,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: fontSizes.normal,
          paddingTop: spacing(2.5),
          paddingBottom: spacing(2.5),
          "&.Mui-selected": {
            backgroundColor: palette.common.selectedLightBlue,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          ...typographies.bodySmall,
          color: palette.blue.main,
          fontWeight: fontWeights.regular,
          backgroundColor: palette.blue.subtleHover,
          borderWidth: 1,
          borderColor: "transparent",
          ":hover": {
            borderColor: palette.blue.main,
          },
          "& .MuiChip-deleteIcon": {
            color: palette.blue.main,
          },
        },
      },
    },
  },
};

const rootTheme = createTheme(baseThemeOptions);

export const lightTheme = createTheme(rootTheme, {
  palette: {
    primary: rootTheme.palette.blue,
    secondary: rootTheme.palette.cypress,
    background: {
      default: rootTheme.palette.common.primaryDark,
      primary: rootTheme.palette.common.white,
      secondary: rootTheme.palette.common.canvas,
      paper: rootTheme.palette.common.white,
    },
    text: {
      primary: rootTheme.palette.common.black,
      secondary: rootTheme.palette.common.secondDark,
    },
    error: {
      main: rootTheme.palette.error.dark,
    },
  },
});

export const darkTheme = createTheme(rootTheme, {
  palette: {
    mode: "dark",
    primary: rootTheme.palette.gold,
    background: {
      default: rootTheme.palette.common.black,
      primary: rootTheme.palette.common.black,
      secondary: rootTheme.palette.common.graphite,
      paper: rootTheme.palette.common.black,
    },
    text: {
      primary: rootTheme.palette.common.white,
      secondary: rootTheme.palette.common.secondWhite,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: palette.common.primaryDark,
          "&.Mui-disabled": {
            background: "#FFFFFF40",
            color: "#FFFFFF40",
          },
        },
        outlined: {
          color: palette.gold.main,
        },
        text: {
          color: palette.gold.main,
        },
      },
      variants: [
        {
          props: {
            variant: "outlinedDropdown",
          },
          style: ({ theme }) => ({
            color: theme.palette.common.white,
            boxShadow: `0 0 0 1px ${theme.palette.common.white}`,

            "& .MuiButton-endIcon svg": {
              color: theme.palette.primary.main,
            },

            "&:focus": {
              boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
            },

            "&:hover": {
              boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
              backgroundColor: theme.palette.common.outlinedDarkDropdownHover,
            },

            "&:active": {
              boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
            },

            "& .MuiTouchRipple-root span": {
              backgroundColor: theme.palette.primary.main,
            },
          }),
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
            {
              borderColor: palette.common.inactiveWhite,
            },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: palette.common.white,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: palette.common.selectedDarkGold,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: rootTheme.palette.common.white,
            },
          },
        },
      },
    },
    MuiMobileStepper: {
      styleOverrides: {
        dot: {
          backgroundColor: palette.gold.contrastText,
        },
        dotActive: {
          backgroundColor: palette.gold.main,
        },
        root: {
          background: "inherit",
        },
      },
    },
  },
});

export const headerMobileHeight = 64;
export const headerDesktopHeight = 96;
