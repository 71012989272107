import { Controller } from "stimulus";
import React from "react";
import ReactDOM from "react-dom/client";

import { ToastMessage } from "../../packs/toastMessage";

export default class extends Controller {
  static values = {
    severity: String,
    message: String,
    title: String,
    cta: String,
    open: Boolean,
  };

  connect() {
    const root = ReactDOM.createRoot(this.element);
    root.render(
      <ToastMessage
        severity={this.severityValue}
        title={this.titleValue}
        message={this.messageValue}
        ctaLabel={this.ctaValue}
        open={this.hasOpenValue ? this.openValue : true}
      />
    );
  }
}
