import { Controller } from "stimulus";
import React from "react";
import ReactDOM from "react-dom/client";

import { darkTheme, lightTheme } from "../../packs/theme";
import { ThemeProvider, TextField } from "@mui/material";

export default class extends Controller {
  static values = {
    props: Object,
  };

  connect() {
    const root = ReactDOM.createRoot(this.element);
    const theme = this.propsValue.theme || lightTheme;
    let inputType = this.propsValue.type || "string";
    root.render(
      <ThemeProvider theme={theme}>
        <TextField
          variant="outlined"
          name={this.propsValue.name}
          value={this.propsValue.value}
          id={this.propsValue.id}
          className={this.propsValue.class}
          label={this.propsValue.label}
          required={this.propsValue.required}
          type={inputType}
        />
      </ThemeProvider>
    );
  }
}
