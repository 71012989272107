import { Controller } from "stimulus";
import React from "react";
import ReactDOM from "react-dom/client";
import StimulusReflex from "stimulus_reflex";

import { SearchSort } from "../../packs/searchSort";

export default class extends Controller {
  static values = {
    sortSelected: String,
    sortSelectedText: String,
  };

  connect() {
    StimulusReflex.register(this);
    this.root = ReactDOM.createRoot(this.element);
    this.render();
  }

  beforeReflex(_element, _reflex) {
    this.root.unmount();
  }

  afterReflex(_element, _reflex) {
    this.root = ReactDOM.createRoot(this.element);
    this.render();
  }

  render() {
    this.root.render(
      <SearchSort
        options={this.optionsValue}
        sortSelected={this.sortSelectedValue}
        sortSelectedText={this.sortSelectedTextValue}
      />
    );
  }
}
