/*globals $ */

import { Controller } from "stimulus";
import CableReady from "cable_ready";
import consumer from "../channels/consumer";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  static values = { id: Number, loadContactInfo: String };
  static targets = ["loadingMessage", "errorMessage"];

  connect() {
    const self = this;
    const appId = this.idValue;
    StimulusReflex.register(this);

    if (this.loadContactInfoValue == "true") {
      this.channel = consumer.subscriptions.create(
        {
          channel: "BatchApplicationChannel",
          id: appId,
        },
        {
          connected() {
            try {
              self.stimulate("BatchApplications#load_contact_info", appId);
            } catch (error) {
              self.handleConnectionError(error);
            }
          },
          received(data) {
            if (data.cableReady) {
              CableReady.perform(data.operations);

              // Load the correct Auth Token into the form since
              // the one provided by the cable ready rendering is not correct
              const csrfToken = document.querySelector(
                'meta[name="csrf-token"]'
              ).content;
              const elem = document.querySelector(
                'form#batch_application_form > input[name="authenticity_token"]'
              );
              if (elem) {
                elem.value = csrfToken;
              }
            }
          },
        }
      );
    }
  }

  disconnect() {
    this.channel.unsubscribe();
  }

  handleConnectionError = (error) => {
    // POST error to rollbar
    $.ajax({
      url: "/api/errors",
      dataType: "json",
      type: "POST",
      data: {
        message: error.toString(),
      },
      success: function () {
        console.error(error);
      },
    });
    this.loadingMessageTarget.classList.add("hidden");
    this.errorMessageTarget.classList.remove("hidden");
  };
}
