import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["universitySelect", "select"];

  connect() {}

  toggleStatus() {
    if (this.universityId() !== "") {
      this.selectTarget.selectize.enable();
    } else {
      this.selectTarget.selectize.disable();
    }
  }

  empty() {
    this.selectTarget.selectize.disable();
    this.selectTarget.selectize.clear();
    this.selectTarget.selectize.clearOptions();
  }

  universityId() {
    return this.universitySelectTarget.options[
      this.universitySelectTarget.selectedIndex
    ].value;
  }
}
