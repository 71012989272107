import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "button", "content"];

  toggle() {
    if (this.containerTarget.classList.contains("opened")) {
      this.containerTarget.classList.remove("finished");
      this.containerTarget.classList.add("closed");
      this.containerTarget.classList.remove("opened");
      this.buttonTarget.setAttribute("aria-expanded", false);
      this.contentTarget.setAttribute("aria-hidden", true);
    } else {
      this.buttonTarget.setAttribute("aria-expanded", true);
      this.contentTarget.setAttribute("aria-hidden", false);
      this.containerTarget.classList.remove("closed");
      this.containerTarget.classList.add("opened");
      setTimeout(() => this.containerTarget.classList.add("finished"), 501);
    }
  }
}
