import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["faqTemplate", "faqItem", "faqContainer", "faqRemoveBtn"];

  removeFaq(event) {
    let element = event.target.closest("div.faq_item_container");
    element.remove();
  }

  addFaq(event) {
    let element = event.target;
    element.remove();
    let t = this.faqTemplateTarget;
    let clone = document.importNode(t.content, true);
    this.faqRemoveBtnTargets.forEach((btn) => {
      btn.classList.remove("hidden");
    });
    this.faqContainerTarget.appendChild(clone);
  }
}
