import { Controller } from "stimulus";
import consumer from "../channels/consumer";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  static values = { id: Number };

  connect() {
    const self = this;
    const courseId = this.idValue;
    StimulusReflex.register(this);

    this.channel = consumer.subscriptions.create(
      {
        channel: "CourseChannel",
        id: courseId,
      },
      {
        received(data) {
          self.stimulate("Sections#seat_count_reload", data.id);
          const event = new CustomEvent("seatcountready");
          window.dispatchEvent(event);
        },

        disconnected() {
          this.markUnavailable();
        },

        rejected() {
          this.markUnavailable();
        },

        markUnavailable() {
          for (const node of document.querySelectorAll(".seat-info.checking")) {
            node.classList.add("hidden");
          }

          for (const node of document.querySelectorAll(".seat-count-number")) {
            node.textContent = "Unavailable";
          }
        },
      }
    );
  }

  disconnect() {
    this.channel.unsubscribe();
  }
}
