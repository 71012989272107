import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  static targets = ["loadingIndicator"];
  static values = { display: String };
  skipReflexes = ["EquivalenciesSearch#set_transfer_to"];

  connect() {
    this._hideLoadingIndicators();
    StimulusReflex.register(this);
  }

  beforeReflex(element, reflex) {
    if (this.skipReflexes.indexOf(reflex) < 0) {
      this._showLoadingIndicators();
    }
  }

  afterReflex() {
    this._hideLoadingIndicators();
    this._show_content();
    this._enable_content();
  }

  _showLoadingIndicators() {
    this.loadingIndicatorTargets.forEach((target) => {
      target.classList.remove("hidden");
      if (this.hasDisplayValue) {
        target.classList.add(this.displayValue);
      } else {
        target.classList.add("flex");
      }
    });
    this._hide_content();
    this._disable_content();
  }

  _hideLoadingIndicators() {
    if (this.hasLoadingIndicatorTarget) {
      this.loadingIndicatorTargets.forEach((target) => {
        target.classList.add("hidden");
        if (this.hasDisplayValue) {
          target.classList.remove(this.displayValue);
        } else {
          target.classList.remove("flex");
        }
      });
    }
  }

  _hide_content() {
    for (const node of this.loadingIndicatorController.element.querySelectorAll(
      ".loading-content"
    )) {
      node.classList.add("hidden");
    }
  }

  _show_content() {
    for (const node of this.loadingIndicatorController.element.querySelectorAll(
      ".loading-content"
    )) {
      node.classList.remove("hidden");
    }
  }

  _disable_content() {
    for (const node of this.loadingIndicatorController.element.querySelectorAll(
      ".disabled-content"
    )) {
      node.setAttribute("disabled", "disabled");
    }
  }

  _enable_content() {
    for (const node of this.loadingIndicatorController.element.querySelectorAll(
      ".disabled-content"
    )) {
      node.removeAttribute("disabled");
    }
  }

  get loadingIndicatorController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      "loading-indicator"
    );
  }
}
