import { Controller } from "stimulus";
import React from "react";
import ReactDOM from "react-dom/client";

import { Footer } from "../../packs/footer";
import { AuthenticatedFooter } from "../../packs/authenticatedFooter";

export default class extends Controller {
  static values = {
    logo: String,
    image: String,
    domain: String,
    institutions: Array,
    user: Object,
    emailSubscriptionEndpoint: String,
  };

  connect() {
    const root = ReactDOM.createRoot(this.element);
    root.render(
      this.userValue.logged ? (
        <AuthenticatedFooter
          image={this.imageValue}
          logoDarkUrl={this.logoValue}
          institutionsLogos={this.institutionsValue}
          domain={this.domainValue}
          user={this.userValue}
        />
      ) : (
        <Footer
          image={this.imageValue}
          logoDarkUrl={this.logoValue}
          institutionsLogos={this.institutionsValue}
          domain={this.domainValue}
          user={this.userValue}
          emailSubscriptionEndpoint={this.emailSubscriptionEndpointValue}
        />
      )
    );
  }
}
