import React, { Fragment, useState } from "react";
import { AccountCircleOutlined as AccountCircleOutlinedIcon } from "@mui/icons-material";
import { Box, Link, Button, Typography, Popover } from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";

import { accountLinks, mainLinks } from "./headerContentNew";

export function DesktopNavNew(props) {
  const [anchorElList, setAnchorElList] = useState({
    id: null,
  });

  const accountMenuId = "menu-account";

  function onOpenMenu(id, event) {
    setAnchorElList({
      [id]: event.currentTarget,
    });
  }

  function onCloseMenu() {
    setAnchorElList({});
  }

  return (
    <Box
      sx={{
        display: {
          xs: "none",
          md: "flex",
        },
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box>
        <Link href={props.externalDomain}>
          <img
            alt="hbcuv logo"
            src={props.logoWhiteUrl}
            width="127px"
            height="24px"
            className="inline align-baseline"
          />
        </Link>
        {props.user.logged && (
          <Typography
            variant="titleSmall"
            sx={{
              color: "common.white",
            }}
          >
            {props.user.institutionName}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          right: 0,
          zIndex: "5",
          ...(props.user.logged && {
            marginRight: {
              md: 10,
              xl: 24,
            },
          }),
        }}
      >
        <Box
          component="nav"
          sx={{
            display: "flex",
            ml: "auto",
            gap: 2,
          }}
        >
          {mainLinks(props.externalDomain).map(
            ({ href, text, subLinks, show, target }, index) => {
              if (
                (show === "Auth" && !props.user.logged) ||
                (show === "Unauth" && props.user.logged)
              ) {
                return <Fragment key={index} />;
              }

              if (!subLinks) {
                return (
                  <Link
                    key={index}
                    href={href}
                    variant="menuItemNew"
                    underline="hover"
                    target={target}
                  >
                    <Box component="span">{text}</Box>
                  </Link>
                );
              }

              const id = `menu-${index}`;

              return (
                <Fragment key={index}>
                  <Link
                    component="button"
                    variant="menuItemNew"
                    underline="hover"
                    className="selected"
                    onClick={(event) => onOpenMenu(id, event)}
                  >
                    <Box component="span">{text}</Box>
                    <Box component="span" className="endIcon">
                      {Boolean(anchorElList[id]) ? (
                        <ExpandLessIcon fontSize="small" />
                      ) : (
                        <ExpandMoreIcon fontSize="small" />
                      )}
                    </Box>
                  </Link>
                  <Popover
                    id={id}
                    keepMounted
                    open={Boolean(anchorElList[id])}
                    onClose={onCloseMenu}
                    anchorEl={anchorElList[id]}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    PaperProps={{
                      sx: {
                        borderRadius: 0,
                        display: "flex",
                        flexDirection: "column",
                        width: 200,
                      },
                    }}
                  >
                    {subLinks.map(({ href, text, target }, index) => {
                      if (
                        text === "My Courses" &&
                        !props.user.show_my_courses
                      ) {
                        return <Fragment key={index} />;
                      }

                      return (
                        <Link
                          key={index}
                          onClick={onCloseMenu}
                          href={href}
                          variant="menuItemSmall"
                          sx={{
                            fontWeight: "500",
                          }}
                          underline="hover"
                          target={target}
                          className={href === "/search" ? "selected" : ""}
                        >
                          {text}
                        </Link>
                      );
                    })}
                  </Popover>
                </Fragment>
              );
            }
          )}
        </Box>
        {props.user.logged && (
          <>
            <Box
              sx={{
                display: "flex",
                height: "68px",
                width: "68px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link
                component="button"
                onClick={function (event) {
                  return onOpenMenu(accountMenuId, event);
                }}
                aria-label="Open account menu"
                color="inherit"
                size="large"
                variant="avatarNavDesktop"
                sx={{
                  borderRadius: "100%",
                  overflow: "hidden",
                  width: "44px",
                  height: "44px",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                className={
                  props.user.profileImgUrl ? "profileImage" : "profileIcon"
                }
              >
                {props.user.profileImgUrl ? (
                  <img
                    alt="user profile image"
                    src={props.user.profileImgUrl}
                    className="object-cover w-11 h-11"
                  />
                ) : (
                  <AccountCircleOutlinedIcon
                    sx={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                )}
              </Link>
            </Box>
            <Popover
              id={accountMenuId}
              keepMounted
              open={Boolean(anchorElList[accountMenuId])}
              onClose={onCloseMenu}
              anchorEl={anchorElList[accountMenuId]}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                sx: {
                  borderRadius: 0,
                  display: "flex",
                  gap: 1,
                  flexDirection: "column",
                  width: 200,
                },
              }}
              sx={{
                top: "36px",
              }}
            >
              {accountLinks(props.externalDomain).map(
                ({ href, text }, index) => (
                  <Link
                    key={index}
                    onClick={onCloseMenu}
                    href={href}
                    variant="menuItemSmall"
                    underline="hover"
                    sx={{
                      typography: "labelLarge",
                    }}
                  >
                    {text}
                  </Link>
                )
              )}
              <Link
                href={props.user.logoutUrl}
                variant="menuItemSmall"
                underline="hover"
                sx={{
                  textAlign: "left",
                  typography: "labelLarge",
                }}
                data-method="delete"
              >
                Log Out
              </Link>
            </Popover>
          </>
        )}
        {!props.user.logged && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 4,
              ml: 10,
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              href="https://uncf.org/hbcuv"
              target="_blank"
              rel="noopener"
            >
              Support HBCUv
            </Button>
            <Button
              color="primary"
              variant="contained"
              href={`${
                props.externalDomain
              }/?login=true&loginRedirectUrl=${encodeURIComponent(
                `https://${props.domain}/global_sign_in?redirect_url=${window.location.href}`
              )}`}
            >
              Sign In
            </Button>
          </Box>
        )}
      </Box>
      {props.user.logged && (
        <Box
          sx={{
            overflow: "hidden",
            width: {
              md: "96px",
              xl: "220px",
            },
            height: "96px",
            position: "absolute",
            top: "0",
            right: "0",
            marginRight: {
              xl: -16,
            },
          }}
        >
          <Box
            component="img"
            sx={{
              width: "220px",
              height: 96,
              maxWidth: "none",
            }}
            alt="partner cobranded logo"
            src={props.user.institutionDeskImgUrl}
          />
        </Box>
      )}
    </Box>
  );
}
