/*globals $ */

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select"];

  connect() {
    this.connectSubstituteCourseFinder();
  }

  connectSubstituteCourseFinder() {
    $(this.selectTarget).selectize({
      valueField: "id",
      labelField: "text",
      // openOnFocus: false,
      plugins: ["selectize-plugin-a11y"],
      score: function () {
        return function () {
          return 1;
        };
      },
      onType: function () {
        this.clearOptions();
        this.addOption([{ id: "", text: "Loading..." }]);
        this.refreshOptions(true);
      },
      load: function (query, callback) {
        // Hacky hacky hacky! needs to be replaced with a data-target or global data attribute
        var university_id = document.getElementById(
          "filter_university_id"
        ).value;
        var select = this;

        $.ajax({
          url: "/api/search.json",
          dataType: "json",
          type: "GET",
          data: {
            query: query,
            university_id: university_id,
            only_visible: true,
          },
          success: function (results) {
            select.clearOptions();
            var searchResults =
              results.results && results.results.length
                ? results.results
                : [{ id: "", text: "No Results" }];
            callback(searchResults);
          },
        });
      },
      render: {
        option: function (item, escape) {
          if (item.id === "") {
            return (
              "<div role='option' class='option' id='missing-id-option' data-selectable data-value='" +
              escape(item.id) +
              "'>" +
              escape(item.text) +
              "</div>"
            );
          } else {
            return (
              "<div role='option' class='option' id='" +
              escape(item.id) +
              "' data-selectable data-value='" +
              escape(item.id) +
              "'> Show substitutes for " +
              escape(item.text) +
              "</div>"
            );
          }
        },
      },
    });
  }
}
