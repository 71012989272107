import { Controller } from "stimulus";
import React from "react";
import ReactDOM from "react-dom/client";

import Header from "../../packs/header";

export default class extends Controller {
  static values = {
    logo: String,
    newHeader: Boolean,
    domain: String,
    externalDomain: String,
    user: Object,
  };

  connect() {
    const root = ReactDOM.createRoot(this.element);
    root.render(
      <Header
        logoWhiteUrl={this.logoValue}
        newHeader={this.newHeaderValue}
        user={this.userValue}
        externalDomain={this.externalDomainValue}
        domain={this.domainValue}
      />
    );
  }
}
