import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["title", "playButton", "pauseButton"];

  get titles() {
    return this.data.get("titles").split("|");
  }

  connect() {
    this.rotate(0);
  }

  toggle() {
    if (this.data.has("active")) {
      this.data.delete("active");
      this.playButtonTarget.classList.remove("hidden");
      this.pauseButtonTarget.classList.add("hidden");
    } else {
      this.data.set("active", true);
      this.playButtonTarget.classList.add("hidden");
      this.pauseButtonTarget.classList.remove("hidden");
    }
  }

  rotate(index) {
    index %= this.titles.length;

    if (this.data.has("active")) {
      this.titleTarget.innerText = this.titles[index++];
    }
    setTimeout(this.rotate.bind(this, index), 4000);
  }
}
