import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  static targets = ["seats", "radioOffering"];

  beforeReflex(_element, _reflex) {}

  calculateButton() {
    const oneChecked = this.radioOfferingTargets.some((elem) => elem.checked);
    const noSeats = this.seatsTargets.every(
      (elem) =>
        elem.textContent.trim() == "0" || elem.textContent.trim() == "Undefined"
    );
    if (!oneChecked || noSeats) {
      const event = new CustomEvent("disableregisterbutton");
      window.dispatchEvent(event);
    } else {
      const event = new CustomEvent("enableregisterbutton");
      window.dispatchEvent(event);
    }
  }

  afterReflex(_element, _reflex) {}

  connect() {
    StimulusReflex.register(this);
  }
}
