import { Controller } from "stimulus";
import consumer from "../channels/consumer";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  static targets = [
    "main",
    "loadingRow",
    "classSchedule",
    "classScheduleRowTemplate",
  ];

  connect() {
    StimulusReflex.register(this);
    const self = this;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    this.channel = consumer.subscriptions.create(
      {
        channel: "ClassScheduleChannel",
        id: this.data.get("id"),
      },
      {
        connected() {
          fetch("/profile/load_class_schedule", {
            method: "post",
            headers: { "X-CSRF-Token": csrfToken },
          }).then(function (response) {
            console.log(response);
          });
        },
        received() {
          self._cableReceived();
        },
      }
    );
  }

  _cableReceived() {
    this.stimulate("ClassSchedule#reload");
  }
}
