import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "titleLabel",
    "descriptionField",
    "descriptionFieldContainer",
  ];

  updateType(event) {
    if (event.target.value === "title") {
      this.descriptionFieldTarget.setAttribute("disabled", "disabled");
      this.descriptionFieldContainerTarget.classList.add("hidden");
      this.titleLabelTarget.innerHTML = "Title";
    } else {
      this.descriptionFieldContainerTarget.classList.remove("hidden");
      this.descriptionFieldTarget.removeAttribute("disabled");
      this.titleLabelTarget.innerHTML = "Question";
    }
  }
}
