import React, { Fragment, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  Container,
  ThemeProvider,
  Box,
  Grid,
  Link,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";

import { lightTheme } from "./theme";
import { footerLinks } from "./footerLinks";

export function AuthenticatedFooter(props) {
  const [expandedPanel, setExpandedPanel] = useState("");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : "");
  };
  const year = new Date().getFullYear();

  return (
    <ThemeProvider theme={lightTheme}>
      <Box component="footer" bgcolor="background.primary" color="text.primary">
        <Container
          variant="footer"
          sx={{
            pt: props.user.logged ? 10 : 20,
            pb: 10,
            px: {
              xs: 6,
              xl: 0,
            },
          }}
        >
          <Grid
            container
            sx={{
              columnGap: {
                md: 15,
                lg: 24,
              },
            }}
          >
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                mb: 14,
              }}
            >
              <Box
                sx={{
                  mb: {
                    xs: 4,
                    md: 10,
                  },
                  position: "relative",
                  height: "35px",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: {
                      xs: 150,
                      md: 187,
                    },
                    height: {
                      xs: 28,
                      md: 35,
                    },
                  }}
                  alt="hbcuv logo"
                  src={props.logoDarkUrl}
                />
              </Box>
              <Typography
                variant="titleLarge"
                sx={{
                  mb: 10,
                }}
              >
                Through collective genius, Black Excellence thrives.
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                  justifyContent: "space-between",
                  mb: 14,
                }}
              >
                {footerLinks(props.domain).map(
                  ({ title, subTitles, show }, index) => {
                    if (
                      (show === "Auth" && !props.user.logged) ||
                      (show === "Unauth" && props.user.logged)
                    ) {
                      return <Fragment key={index} />;
                    }

                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                        key={index}
                      >
                        <Typography
                          variant="titleMedium"
                          sx={{
                            mb: 6,
                          }}
                        >
                          {title}
                        </Typography>
                        <Box
                          className={
                            title === "My HBCUv" ? "footer-row " : "footer-col"
                          }
                        >
                          {subTitles.map(
                            (
                              {
                                title,
                                href,
                                target,
                                emailAddress,
                                emailCC,
                                emailBCC,
                                emailSubject,
                                emailBody,
                              },
                              subIndex
                            ) => {
                              if (
                                title === "My Courses" &&
                                !props.user.show_my_courses
                              ) {
                                return <Fragment key={subIndex} />;
                              }

                              return (
                                <Link
                                  key={subIndex}
                                  href={
                                    href
                                      ? href
                                      : `mailto:${emailAddress}?${
                                          emailCC && `&cc=${emailCC}`
                                        }${emailBCC && `&bcc=${emailBCC}`}${
                                          emailSubject &&
                                          `&subject=${emailSubject}`
                                        }${
                                          emailBody && `&body=${emailBody}`
                                        }`.replace("?&", "?")
                                  }
                                  target={target && "_blank"}
                                  color="text.secondary"
                                  variant="bodyMedium"
                                  underline="hover"
                                  sx={{
                                    p: 0,
                                  }}
                                >
                                  {title}
                                </Link>
                              );
                            }
                          )}
                        </Box>
                      </Box>
                    );
                  }
                )}
              </Box>
              <Box
                sx={{
                  display: {
                    md: "none",
                  },
                  mb: 14,
                }}
              >
                {footerLinks(props.domain).map(
                  ({ title, subTitles, show }, index) => {
                    if (
                      (show === "Auth" && !props.user.logged) ||
                      (show === "Unauth" && props.user.logged)
                    ) {
                      return <Fragment key={index} />;
                    }

                    return (
                      <Accordion
                        key={index}
                        expanded={expandedPanel === title}
                        elevation={0}
                        onChange={handleChange(title)}
                        sx={{
                          backgroundColor: "transparent",
                          minHeight: 0,
                          "&:before": {
                            display: "none",
                          },
                          "&.Mui-expanded": {
                            my: 0,
                          },
                          ".MuiAccordionSummary-content": {
                            my: 0,
                          },
                          ".MuiAccordionSummary-content.Mui-expanded": {
                            my: 0,
                          },
                          ".MuiAccordionSummary-root.Mui-expanded": {
                            minHeight: 0,
                          },
                          ".MuiCollapse-wrapperInner": {
                            mb: 0,
                          },
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon
                              sx={{
                                color: "text.primary",
                              }}
                            />
                          }
                          sx={{
                            backgroundColor: "transparent",
                            p: 0,
                            minHeight: 0,
                            mt: index === 0 ? 0 : 6,
                          }}
                        >
                          <Typography
                            variant="titleMedium"
                            sx={{
                              m: 0,
                            }}
                          >
                            {title}
                          </Typography>
                        </AccordionSummary>
                        {subTitles.map(
                          (
                            {
                              title,
                              href,
                              target,
                              emailAddress,
                              emailCC,
                              emailBCC,
                              emailSubject,
                              emailBody,
                            },
                            subIndex
                          ) => (
                            <AccordionDetails
                              key={subIndex}
                              sx={{
                                p: 0,
                                mt: 6,
                                mb:
                                  footerLinks.length - 1 !== index &&
                                  subTitles.length - 1 === subIndex
                                    ? 4
                                    : 0,
                              }}
                            >
                              <Link
                                key={subIndex}
                                href={
                                  href
                                    ? href
                                    : `mailto:${emailAddress}?${
                                        emailCC && `&cc=${emailCC}`
                                      }${emailBCC && `&bcc=${emailBCC}`}${
                                        emailSubject &&
                                        `&subject=${emailSubject}`
                                      }${
                                        emailBody && `&body=${emailBody}`
                                      }`.replace("?&", "?")
                                }
                                target={target && "_blank"}
                                color="text.secondary"
                                variant="bodyMedium"
                                underline="hover"
                                sx={{
                                  p: 0,
                                }}
                              >
                                {title}
                              </Link>
                            </AccordionDetails>
                          )
                        )}
                      </Accordion>
                    );
                  }
                )}
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              gap: {
                xs: 6,
                md: 10,
              },
            }}
          >
            <Link
              variant="linkSmall"
              color="text.secondary"
              href="https://uncf.org/pages/uncf-privacy-policy"
              rel="noopener"
              target="_blank"
              sx={{
                textAlign: "left",
              }}
            >
              Privacy Policy
            </Link>
            <Link
              variant="linkSmall"
              color="text.secondary"
              href="https://uncf.org/pages/uncf-website-terms-and-conditions"
              rel="noopener"
              target="_blank"
              sx={{
                textAlign: "left",
              }}
            >
              Terms and Conditions
            </Link>
            <Typography variant="bodySmall" color="text.secondary">
              © Copyright {year}
            </Typography>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
