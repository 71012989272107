import { Dropdown } from "tailwindcss-stimulus-components";

export default class extends Dropdown {
  static targets = ["focusable"];

  toggle() {
    super.toggle();
    if (this.hasFocusableTarget && this.openValue) {
      setTimeout(() => this.focusableTarget.focus(), 300);
    }
  }
}
