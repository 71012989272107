import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "sessionId",
    "isSelfPacedCheckbox",
    "enrollmentTypeSelection",
  ];

  toggleSelfPaced(e) {
    if (e.currentTarget.name == "offering[session_id]") {
      if (this.sessionIdTarget.value == "") {
        this.isSelfPacedCheckboxTarget.checked = "checked";
      }
    }
  }

  toggleDualEnrollmentFields(e) {
    var delivery_location_input = document.getElementById(
      "offering_delivery_location"
    );

    var offering_high_school_ids_input = document.getElementById(
      "offering_high_school_ids"
    );

    var offering_course_offering_codes_input = document.getElementById(
      "offering_course_offering_codes"
    );

    if (
      ["dual_enrollment", "unrestricted"].includes(
        this.enrollmentTypeSelectionTarget.value
      )
    ) {
      delivery_location_input.parentElement.classList.remove("hidden");
      offering_high_school_ids_input.parentElement.parentElement.classList.remove(
        "hidden"
      );
      offering_course_offering_codes_input.parentElement.classList.remove(
        "hidden"
      );
    } else {
      delivery_location_input.value = "";
      delivery_location_input.parentElement.classList.add("hidden");
      offering_high_school_ids_input.value = "";
      offering_high_school_ids_input.parentElement.parentElement.classList.add(
        "hidden"
      );
      offering_course_offering_codes_input.parentElement.classList.add(
        "hidden"
      );
    }
  }
}
