import React, { Fragment, useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  Checkbox,
  FormControlLabel,
  Divider,
  Button,
  List,
  ListItem,
  Popover,
  Badge,
  ThemeProvider,
  Drawer,
} from "@mui/material";

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { AllFiltersAccordion } from "./allFiltersAccordion";

import {
  SearchOutlined as SearchOutlinedIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Close as CloseIcon,
  Tune as TuneIcon,
} from "@mui/icons-material";

import useMediaQuery from "@mui/material/useMediaQuery";

import { lightTheme } from "./theme";

export function Filters(props) {
  const mobileBreakpoint = useMediaQuery(lightTheme.breakpoints.down("sm"));

  /* Keyword Fitler*/
  const [keywordValue, setKeywordValue] = React.useState(props.searchKey);
  const [keywordValueSearched, setKeywordValueSearched] =
    React.useState(keywordValue);
  const cleanKeyword = () => {
    setKeywordValueSearched("");
    setKeywordValue("");
    submitForm();
  };
  const formContainer = document.getElementById("new_filter");

  //Institution Filter
  const [institutionAnchorEl, setInstitutionAnchorEl] = React.useState(null);
  const institutionPopoverIsOpen = Boolean(institutionAnchorEl);
  const institutionPopoverId = "institution-popover";
  const institutions = props.institutions;
  const selectedInstitutions = props.selectedInstitutions;
  const [selectedInstitutionsIds, setSelectedInstitutionsIds] = React.useState(
    selectedInstitutions.at(0) === ""
      ? institutions.map(({ id }) => id)
      : selectedInstitutions
  );
  const [searchedInstitutions, setSearchedInstitutions] = React.useState(
    institutions.map(({ id }) => id)
  );
  const [searchInstitutionValue, setSearchInstitutionValue] =
    React.useState("");

  const clearSearchedInstitutions = () => {
    setSearchedInstitutions(institutions.map(({ id }) => id));
  };

  const handleClickInstitutions = (event) => {
    setInstitutionAnchorEl(event.currentTarget);
    setSelectedInstitutionsIdsTmp(selectedInstitutionsIds);
    openPopover();
  };

  const handleCloseInstitutions = () => {
    setSearchInstitutionValue("");
    clearSearchedInstitutions();
    setInstitutionAnchorEl(null);
    document.body.classList.remove("popover-open");
  };

  const resetInstitutions = () => {
    setSelectedInstitutionsIds("");
    setSelectedInstitutionsIdsTmp("");
    setSearchInstitutionValue("");
    clearSearchedInstitutions();
    updateInstitutionsChips();
    submitForm();
  };
  const cleanInstitutionSearch = () => {
    setSearchInstitutionValue("");
  };

  const restoreTmpInstitutions = () => {
    setSelectedInstitutionsIds(selectedInstitutionsIdsTmp);
  };

  const openPopover = () => {
    if (window.innerWidth > document.body.clientWidth) {
      document.body.classList.add("fixed-scrollbar");
    }
    document.body.classList.add("popover-open");
  };

  const closePopover = () => {
    document.body.classList.remove("fixed-scrollbar");
    document.body.classList.remove("popover-open");
  };

  const submitForm = () => {
    setTimeout(() => {
      document.getElementById("new_filter_submit").click();
    }, 1);
  };

  const handleInstitutionSelection = (event) => {
    let value = event.target.value;
    value = value == "All" ? value : Number(value);
    if (value === "All") {
      clearSearchedInstitutions();
      setSelectedInstitutionsIds(
        selectedInstitutionsIds.length != institutions.length
          ? institutions.map(({ id }) => id)
          : []
      );
      setSearchInstitutionValue("");
    } else if (selectedInstitutionsIds.includes(value)) {
      setSelectedInstitutionsIds([
        ...selectedInstitutionsIds.filter((id) => id !== value),
      ]);
    } else {
      setSelectedInstitutionsIds([...selectedInstitutionsIds, value]);
    }
  };

  React.useEffect(() => {
    handleSearchInstitution();
  }, [searchInstitutionValue]);

  const [selectedInstitutionsIdsTmp, setSelectedInstitutionsIdsTmp] =
    React.useState("");

  const handleSearchInstitution = () => {
    setSearchedInstitutions(
      institutions
        .filter(({ name }) =>
          name.toLowerCase().includes(searchInstitutionValue)
        )
        .map(({ id }) => id)
    );
  };
  // End Institutions

  // Subjects Filter
  const subjects = props.subjects;
  const selectedSubjects = props.selectedSubjects;
  const [subjectsAnchorEl, setSubjectsAnchorEl] = React.useState(null);
  const subjectsPopoverIsOpen = Boolean(subjectsAnchorEl);

  const subjectsPopoverId = "subjects-popover";

  const [searchSubjectsValue, setSearchSubjectsValue] = React.useState("");
  const [selectedSubjectsIds, setSelectedSubjectsIds] = React.useState(
    selectedSubjects.at(0) === ""
      ? subjects.map(({ id }) => id)
      : selectedSubjects
  );

  const [searchedSubjectsIds, setSearchedSubjectsIds] = React.useState(
    subjects.map(({ id }) => id)
  );

  const clearSearchedSubjectsIds = () => {
    setSearchedSubjectsIds(subjects.map(({ id }) => id));
  };

  const handleClickSubjects = (event) => {
    setSubjectsAnchorEl(event.currentTarget);
    setSelectedSubjectsIdsTmp(selectedSubjectsIds);
    openPopover();
  };

  const handleCloseSubjects = () => {
    setSubjectsAnchorEl(null);
    setSearchSubjectsValue("");
    clearSearchedSubjectsIds();
    closePopover();
  };

  const handleSubjectsSelection = (event) => {
    let value = event.target.value;
    value = value == "All" ? value : Number(value);
    if (value === "All") {
      clearSearchedSubjectsIds();
      setSearchSubjectsValue("");
      setSelectedSubjectsIds(
        selectedSubjectsIds.length != subjects.length
          ? subjects.map(({ id }) => id)
          : []
      );
    } else if (selectedSubjectsIds.includes(value)) {
      setSelectedSubjectsIds([
        ...selectedSubjectsIds.filter((id) => id !== value),
      ]);
    } else {
      setSelectedSubjectsIds([...selectedSubjectsIds, value]);
    }
  };

  const handleSearchSubjects = () => {
    setSearchedSubjectsIds(
      searchedSubjectsIds.at(0) === ""
        ? subjects.map(({ id }) => id)
        : subjects
            .filter(({ name }) =>
              name.toLowerCase().includes(searchSubjectsValue)
            )
            .map(({ id }) => id)
    );
  };

  const resetSubjects = () => {
    setSelectedSubjectsIds("");
    setSelectedSubjectsIdsTmp("");
    setSearchSubjectsValue("");
    clearSearchedSubjectsIds();
    updateSubjectsChips();
    submitForm();
  };

  const cleanSubjectsSearch = () => {
    setSearchSubjectsValue("");
  };

  const restoreTmpSubjects = () => {
    setSelectedSubjectsIds(selectedSubjectsIdsTmp);
  };

  const [selectedSubjectsIdsTmp, setSelectedSubjectsIdsTmp] =
    React.useState("");

  React.useEffect(() => {
    handleSearchSubjects();
  }, [searchSubjectsValue]);

  // End Subjects

  // Date & Time filters
  const [dateAnchorEl, setDateAnchorEl] = React.useState(null);
  const datePopoverIsOpen = Boolean(dateAnchorEl);

  const datePopoverId = "date-popover";

  const handleClickDate = (event) => {
    setDateAnchorEl(event.currentTarget);
    setStartDateValueTmp(startDateValue);
    setEndDateValueTmp(endDateValue);
    setSelectedDaysTmp(selectedDays);
    openPopover();
  };

  const handleCloseDate = () => {
    setDateAnchorEl(null);
    closePopover();
  };

  const [startDateValue, setStartDateValue] = React.useState(
    props.startDate
      ? dayjs(props.startDate, "YYYY-MM-DD", true).isValid()
        ? dayjs(props.startDate)
        : null
      : null
  );
  const [endDateValue, setEndDateValue] = React.useState(
    props.endDate
      ? dayjs(props.endDate, "YYYY-MM-DD", true).isValid()
        ? dayjs(props.endDate)
        : null
      : null
  );
  const [startDateError, setStartDateError] = React.useState(null);
  const [endDateError, setEndDateError] = React.useState(null);

  const startDateErrorMessage = React.useMemo(() => {
    switch (startDateError) {
      case "maxDate": {
        return "Start Date cannot be later than the End Date";
      }

      case "disablePast": {
        return "Start Date cannot be in the past";
      }

      case "invalidDate": {
        return "Start Date is not valid";
      }

      default: {
        return "";
      }
    }
  }, [startDateError]);

  const endDateErrorMessage = React.useMemo(() => {
    switch (endDateError) {
      case "minDate": {
        return "End Date cannot be earlier than the Start Date";
      }

      case "disablePast": {
        return "End Date cannot be in the past";
      }

      case "invalidDate": {
        return "End Date is not valid";
      }

      default: {
        return "";
      }
    }
  }, [endDateError]);

  const [startDateValueTmp, setStartDateValueTmp] = React.useState(null);
  const [endDateValueTmp, setEndDateValueTmp] = React.useState(null);

  const days = props.days;
  const [selectedDays, setSelectedDays] = React.useState(props.selectedDays);
  const [selectedDaysTmp, setSelectedDaysTmp] = React.useState(null);

  const handleDaysSelection = (event) => {
    let value = Number(event.target.value);
    if (selectedDays.includes(value)) {
      setSelectedDays([...selectedDays.filter((id) => id !== value)]);
    } else {
      setSelectedDays([...selectedDays, value]);
    }
    // submitForm();
  };

  const restoreTmpDays = () => {
    setSelectedDays(selectedDaysTmp);
  };

  const restoreTmpDates = () => {
    setStartDateValue(startDateValueTmp);
    setEndDateValue(endDateValueTmp);
  };

  const resetDates = () => {
    setSelectedDays("");
    setSelectedDaysTmp("");
    setStartDateValue(null);
    setEndDateValue(null);
    setStartDateValueTmp(null);
    setEndDateValueTmp(null);
    submitForm();
  };

  // End Date & Time filters

  // All filters
  const [sortValueTmp, setSortValueTmp] = React.useState(null);
  const [sortTextValueTmp, setSortTextValueTmp] = React.useState(null);
  const [selectedMethodsTmp, setSelectedMethodsTmp] = React.useState(null);
  const [selectedSessionsTmp, setSelectedSessionsTmp] = React.useState(null);
  const [selectedSelfPacedTmp, setSelectedSelfPacedTmp] = React.useState(null);
  const [minCredsTmp, setMinCredsTmp] = React.useState(null);
  const [maxCredsTmp, setMaxCredsTmp] = React.useState(null);
  const [moreFilterIsOpen, setMoreFilterIsOpen] = React.useState(false);

  const onCloseMoreFilter = () => {
    setMoreFilterIsOpen(false);
    closePopover();
    setSelectedSelfPaced(selectedSelfPacedTmp);
    setSelectedMethods(selectedMethodsTmp);
    setSelectedSessions(selectedSessionsTmp);
    setMinCredsValue(minCredsTmp);
    setMaxCredsValue(maxCredsTmp);
    setSortValue(sortValueTmp);
    setSortTextValue(sortTextValueTmp);
    setCreditsRangeError(false);
    setMinCreditsErrorMessage("");
    setMaxCreditsErrorMessage("");
  };
  const openMoreFilter = () => {
    setSortValueTmp(sortValue);
    setSortTextValueTmp(sortTextValue);
    setSelectedMethodsTmp(selectedMethods);
    setSelectedSelfPacedTmp(selectedSelfPaced);
    setSelectedSessionsTmp(selectedSessions);
    setMinCredsTmp(minCredsValue);
    setMaxCredsTmp(maxCredsValue);
    setMoreFilterIsOpen(true);
    openPopover();
  };

  const allFiltersSearch = () => {
    setSortValueTmp(null);
    setSortTextValueTmp(null);
    setSelectedMethodsTmp(null);
    setSelectedSessionsTmp(null);
    setSelectedSelfPacedTmp(null);
    setMinCredsTmp(null);
    setMaxCredsTmp(null);
    setMoreFilterIsOpen(false);
    setMethodsChips(
      deliveryMethods.filter((method) => selectedMethods.includes(method.value))
    );
    setCreditChip(
      minCredsValue > 0 || maxCredsValue < 20
        ? `${minCredsValue || 0} - ${maxCredsValue || 20}`
        : null
    );
    setSessionsChip(
      sessions.filter((session) => selectedSessions.includes(session))
    );
    submitForm();
    closePopover();
  };

  const moreFiltersClean = () => {
    setSelectedSelfPaced("");
    setSelectedMethods("");
    setSelectedSessions("");
    setMinCredsValue(0);
    setMaxCredsValue(20);
    setSortValue("");
    setSortTextValue("");
    setCreditsRangeError(false);
    setMinCreditsErrorMessage("");
    setMaxCreditsErrorMessage("");
    setSortValueTmp(null);
    setSortTextValueTmp(null);
    setSelectedMethodsTmp(null);
    setSelectedSessionsTmp(null);
    setSelectedSelfPacedTmp(null);
    setMinCredsTmp(null);
    setMaxCredsTmp(null);
    setMoreFilterIsOpen(false);
    setMethodsChips("");
    setCreditChip("");
    setSessionsChip([]);
    submitForm();
    closePopover();
  };

  const [creditsRangeError, setCreditsRangeError] = React.useState(false);
  const [minCreditsErrorMessage, setMinCreditsErrorMessage] =
    React.useState(false);
  const [maxCreditsErrorMessage, setMaxCreditsErrorMessage] =
    React.useState(false);

  const [sortValue, setSortValue] = React.useState(props.sortSelected);
  const [sortTextValue, setSortTextValue] = React.useState(
    props.sortSelectedText
  );

  const sessions = props.sessions;
  const [selectedSessions, setSelectedSessions] = React.useState(
    props.selectedSessions
  );

  const [selectedSelfPaced, setSelectedSelfPaced] = React.useState(
    Boolean(props.selectedSelfPaced)
  );

  const deliveryMethods = props.deliveryMethods;
  const [selectedMethods, setSelectedMethods] = React.useState(
    props.selectedMethods
  );

  const [minCredsValue, setMinCredsValue] = React.useState(
    props.credits.min_credits
  );
  const [maxCredsValue, setMaxCredsValue] = React.useState(
    props.credits.max_credits
  );
  /* End All Filters */

  /*  SEARCH Chips */
  const [subjectsChips, setSubjectsChips] = React.useState(
    subjects.filter((subject) => selectedSubjectsIds.includes(subject.id))
  );
  const [subjectsChipsUpdated, setSubjectsChipsUpdated] = React.useState("");

  React.useEffect(() => {
    setSubjectsChips(
      subjects.filter((subject) => selectedSubjectsIds.includes(subject.id))
    );
  }, [subjectsChipsUpdated]);

  const updateSubjectsChips = () => {
    setSubjectsChipsUpdated(
      subjects.filter((uni) => selectedSubjectsIds.includes(uni.id))
    );
  };

  const removeSubjectId = (value) => {
    setSelectedSubjectsIds([
      ...selectedSubjectsIds.filter((id) => id !== value),
    ]);
    updateSubjectsChips();
    submitForm();
  };

  const [institutionsChipsUpdated, setInstitutionsChipsUpdated] =
    React.useState("");

  const [institutionsChips, setInstitutionsChips] = React.useState(
    institutions.filter((uni) => selectedInstitutionsIds.includes(uni.id))
  );

  const updateInstitutionsChips = () => {
    setInstitutionsChipsUpdated(
      institutions.filter((uni) => selectedInstitutionsIds.includes(uni.id))
    );
  };

  React.useEffect(() => {
    setInstitutionsChips(
      institutions.filter((uni) => selectedInstitutionsIds.includes(uni.id))
    );
  }, [institutionsChipsUpdated]);

  const removeInstitutionId = (value) => {
    setSelectedInstitutionsIds([
      ...selectedInstitutionsIds.filter((id) => id !== value),
    ]);
    updateInstitutionsChips();
    submitForm();
  };

  const [dateChip, setDateChip] = React.useState(
    dayjs(startDateValue, "YYYY-MM-DD", true).isValid()
      ? dayjs(endDateValue, "YYYY-MM-DD", true).isValid()
        ? `${startDateValue.format("MM/DD")} - ${endDateValue.format("MM/DD")}`
        : `${startDateValue.format("MM/DD")}`
      : dayjs(endDateValue, "YYYY-MM-DD", true).isValid()
      ? `${endDateValue.format("MM/DD")}`
      : null
  );

  const [dateChipsUpdated, setDateChipsUpdated] = React.useState("");

  const updateDatesChips = () => {
    setDateChipsUpdated(
      dayjs(startDateValue, "YYYY-MM-DD", true).isValid()
        ? dayjs(endDateValue, "YYYY-MM-DD", true).isValid()
          ? `${startDateValue.format("MM/DD")} - ${endDateValue.format(
              "MM/DD"
            )}`
          : `${startDateValue.format("MM/DD")}`
        : dayjs(endDateValue, "YYYY-MM-DD", true).isValid()
        ? `${endDateValue.format("MM/DD")}`
        : null
    );
  };

  React.useEffect(() => {
    setDateChip(
      dayjs(startDateValue, "YYYY-MM-DD", true).isValid()
        ? dayjs(endDateValue, "YYYY-MM-DD", true).isValid()
          ? `${startDateValue.format("MM/DD")} - ${endDateValue.format(
              "MM/DD"
            )}`
          : `${startDateValue.format("MM/DD")}`
        : dayjs(endDateValue, "YYYY-MM-DD", true).isValid()
        ? `${endDateValue.format("MM/DD")}`
        : null
    );
  }, [dateChipsUpdated]);

  const removeDates = () => {
    setStartDateValue(null);
    setEndDateValue(null);
    setDateChipsUpdated("");
    submitForm();
  };

  const [daysChip, setDaysChip] = React.useState(
    days
      .filter((day) => selectedDays.includes(day.id))
      .map((day) => day.name.slice(0, 3))
      .join(", ")
  );

  const [daysChipsUpdated, setDaysChipsUpdated] = React.useState("");

  React.useEffect(() => {
    setDaysChip(
      days
        .filter((day) => selectedDays.includes(day.id))
        .map((day) => day.name.slice(0, 3))
        .join(", ")
    );
  }, [daysChipsUpdated]);

  const updateDaysChips = () => {
    setDaysChipsUpdated(
      days
        .filter((day) => selectedDays.includes(day.id))
        .map((day) => day.name.slice(0, 3))
        .join(", ")
    );
  };

  const removeDays = () => {
    setSelectedDays([]);
    setDaysChipsUpdated("");
    submitForm();
  };

  const [methodsChips, setMethodsChips] = React.useState(
    deliveryMethods.filter((method) => selectedMethods.includes(method.value))
  );

  const removeMethod = (value) => {
    const updatedSelectedMethods = selectedMethods.filter(
      (val) => val !== value
    );
    setSelectedMethods(updatedSelectedMethods);
    setMethodsChips(
      deliveryMethods.filter((method) =>
        updatedSelectedMethods.includes(method.value)
      )
    );
    submitForm();
  };

  const [selfPacedChip, setSelfPacedChip] = React.useState(
    Boolean(props.selectedSelfPaced)
  );
  const removeSelfPaced = () => {
    setSelectedSelfPaced(false);
    setSelfPacedChip(null);
    submitForm();
  };

  React.useEffect(() => {
    setSelfPacedChip(selectedSelfPaced);
  }, [selectedSelfPacedTmp]);

  const [creditChip, setCreditChip] = React.useState(
    minCredsValue > 0 || maxCredsValue < 20
      ? `${minCredsValue || 0} - ${maxCredsValue || 20}`
      : null
  );

  const removeCredits = () => {
    setMinCredsValue(0);
    setMaxCredsValue(20);
    setCreditChip(null);
    submitForm();
  };

  const [sessionsChip, setSessionsChip] = React.useState(
    sessions.filter((session) => selectedSessions.includes(session))
  );

  const removeSession = (value) => {
    const updatedSessions = selectedSessions.filter(
      (session) => session !== value
    );
    setSelectedSessions(updatedSessions);
    setSessionsChip(
      sessions.filter((session) => updatedSessions.includes(session))
    );
    submitForm();
  };

  const [anyChip, setAnyChip] = React.useState(
    methodsChips.length > 0 ||
      dateChip ||
      daysChip ||
      institutionsChips.length > 0 ||
      subjectsChips.length > 0 ||
      sessionsChip.length > 0 ||
      selfPacedChip
  );

  React.useEffect(() => {
    setAnyChip(
      methodsChips.length > 0 ||
        dateChip ||
        creditChip ||
        institutionsChips.length > 0 ||
        subjectsChips.length > 0 ||
        sessionsChip.length > 0 ||
        selfPacedChip
    );
  }, [
    methodsChips,
    dateChip,
    daysChip,
    institutionsChips,
    subjectsChips,
    creditChip,
    sessionsChip,
    selfPacedChip,
  ]);

  return (
    <ThemeProvider theme={lightTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          gap: {
            xs: 4,
            md: 2,
          },
        }}
      >
        <Box
          sx={{
            flexGrow: 3,
            order: 0,
            width: "100%",
            p: "2px",
          }}
        >
          <TextField
            size="small"
            name="filter[subject]"
            value={keywordValue}
            onChange={(event) => {
              setKeywordValue(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                setKeywordValueSearched(keywordValue);
                submitForm();
              }
            }}
            sx={{
              "& fieldset": {
                borderRadius: 8,
              },
            }}
            id="filter_subject"
            placeholder="Search by keyword"
            inputProps={{
              "aria-label": "Keyword",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {keywordValueSearched && (
                    <IconButton
                      type="button"
                      aria-label="Clear Keyword"
                      onClick={cleanKeyword}
                    >
                      <CloseIcon color="primary" />
                    </IconButton>
                  )}
                  <IconButton
                    type="button"
                    onClick={() => {
                      setKeywordValueSearched(keywordValue);
                      submitForm();
                    }}
                    aria-label="Search"
                  >
                    <SearchOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            overflowX: {
              xs: "auto",
              md: "inherit",
            },
            p: "2px 2px 20px 2px",
          }}
        >
          <FormControl
            size="small"
            sx={{
              height: 44,
              minWidth: {
                xs: 144,
                lg: 172,
              },
              m: 0,
              order: 2,
            }}
          >
            <Button
              variant="outlinedDropdown"
              color="primary"
              type="button"
              onClick={handleClickSubjects}
              disableFocusRipple={true}
              endIcon={
                subjectsPopoverIsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />
              }
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexGrow: 1,
                boxShadow: subjectsPopoverIsOpen ? `0 0 0 2px #1271C4` : "",
              }}
              aria-describedby={subjectsPopoverId}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  textAlign: "left",
                }}
              >
                Subjects
              </Box>
              <Badge
                badgeContent={selectedSubjectsIds.length}
                color="primary"
                sx={{
                  ml: 2,
                }}
              />
            </Button>
            <Popover
              id={subjectsPopoverId}
              container={formContainer}
              keepMounted
              open={subjectsPopoverIsOpen}
              onClose={() => {
                restoreTmpSubjects();
                handleCloseSubjects();
              }}
              anchorEl={subjectsAnchorEl}
              anchorOrigin={{
                vertical: 52,
                horizontal: "left",
              }}
              componentsProps={{
                backdrop: {
                  style: {
                    backgroundColor: "transparent",
                  },
                },
              }}
              PaperProps={{
                sx: {
                  borderRadius: 5.5,
                  display: "flex",
                  flexDirection: "column",
                  px: 6,
                  pt: 6,
                },
              }}
            >
              <Typography
                variant="eyebrowSmall"
                color="text.secondary"
                sx={{
                  mb: 6,
                }}
              >
                Subjects
              </Typography>
              <TextField
                size="small"
                inputProps={{
                  sx: {
                    pl: {
                      xs: 4,
                      md: 8,
                    },
                  },
                }}
                sx={{
                  flexGrow: 3,
                  mb: 5,
                  width: "100%",
                  "& fieldset": {
                    borderRadius: 8,
                  },
                }}
                placeholder="Search"
                onChange={(event) => {
                  setSearchSubjectsValue(event.target.value.toLowerCase());
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    handleSearchSubjects();
                  }
                }}
                value={searchSubjectsValue}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchSubjectsValue ? (
                        <IconButton
                          aria-label="Clear Suject Search"
                          type="button"
                          onClick={cleanSubjectsSearch}
                        >
                          <CloseIcon color="primary" />
                        </IconButton>
                      ) : (
                        <SearchOutlinedIcon />
                      )}
                    </InputAdornment>
                  ),
                  inputProps: { "aria-label": "Subjects" },
                }}
              />
              <Divider
                color="background.primary"
                sx={{
                  backgroundColor: "common.hoverFill",
                  mb: 7,
                }}
              />
              <List
                sx={{
                  p: 0,
                }}
              >
                {subjects.map(({ id, name }, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      p: 0,
                      display: searchedSubjectsIds.includes(id) ? "" : "none",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={id}
                          onChange={handleSubjectsSelection}
                          checked={selectedSubjectsIds.includes(id)}
                          name="filter[subject_ids][]"
                        />
                      }
                      label={name}
                      componentsProps={{
                        typography: {
                          variant: "bodyMedium",
                        },
                      }}
                    />
                  </ListItem>
                ))}
              </List>
              <Box
                sx={{
                  display: "flex",
                  py: 4,
                  gap: 4,
                  position: "sticky",
                  bottom: 0,
                  justifyContent: "flex-end",
                  backgroundColor: "common.white",
                }}
              >
                <Button
                  color="primary"
                  variant="text"
                  onClick={resetSubjects}
                  sx={{
                    px: 10,
                  }}
                >
                  Reset
                </Button>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    handleCloseSubjects();
                    updateSubjectsChips();
                    submitForm();
                  }}
                  sx={{
                    px: 10,
                  }}
                >
                  Apply
                </Button>
              </Box>
            </Popover>
          </FormControl>
          <FormControl
            size="small"
            sx={{
              height: 44,
              minWidth: 172,
              m: 0,
              order: 3,
            }}
          >
            <Button
              variant="outlinedDropdown"
              color="primary"
              type="button"
              onClick={handleClickDate}
              disableFocusRipple={true}
              endIcon={
                datePopoverIsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />
              }
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexGrow: 1,
                boxShadow: datePopoverIsOpen ? `0 0 0 2px #1271C4` : "",
              }}
              aria-describedby={datePopoverId}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  textAlign: "left",
                }}
              >
                Date & Time
              </Box>
              <Badge
                badgeContent={
                  startDateValue || endDateValue
                    ? selectedDays.length > 0
                      ? 2
                      : 1
                    : selectedDays.length > 0
                    ? 1
                    : 0
                }
                color="primary"
                sx={{
                  ml: 2,
                }}
              />
            </Button>
            <Popover
              id={datePopoverId}
              container={formContainer}
              keepMounted
              open={datePopoverIsOpen}
              onClose={() => {
                restoreTmpDays();
                restoreTmpDates();
                handleCloseDate();
              }}
              anchorEl={dateAnchorEl}
              anchorOrigin={{
                vertical: 52,
                horizontal: "left",
              }}
              componentsProps={{
                backdrop: {
                  style: {
                    backgroundColor: "transparent",
                  },
                },
              }}
              PaperProps={{
                sx: {
                  borderRadius: 5.5,
                  display: "flex",
                  flexDirection: "column",
                  px: 6,
                  pt: 6,
                },
              }}
            >
              <Typography
                color="text.secondary"
                variant="eyebrowSmall"
                sx={{
                  mb: 6,
                }}
              >
                Date Range
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: 3,
                  mb: 7,
                }}
              >
                <FormControl size="small">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                      <DatePicker
                        label="Start Date"
                        slotProps={{
                          textField: {
                            name: "filter[start_date]",
                            helperText: startDateErrorMessage,
                          },
                        }}
                        format="YYYY-MM-DD"
                        value={startDateValue}
                        onChange={(newValue) => {
                          setStartDateValue(newValue);
                          // submitForm();
                        }}
                        maxDate={endDateValue}
                        onError={(newError) => setStartDateError(newError)}
                        disablePast
                      />
                      <DatePicker
                        label="End Date"
                        slotProps={{
                          textField: {
                            name: "filter[end_date]",
                            helperText: endDateErrorMessage,
                          },
                        }}
                        format="YYYY-MM-DD"
                        value={endDateValue}
                        onChange={(newValue) => {
                          setEndDateValue(newValue);
                          // submitForm();
                        }}
                        minDate={startDateValue}
                        onError={(newError) => setEndDateError(newError)}
                        disablePast
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </FormControl>
              </Box>
              <Divider
                color="background.primary"
                sx={{
                  backgroundColor: "common.hoverFill",
                  borderColor: "transparent",
                  mb: 7,
                }}
              />
              <Typography
                color="text.secondary"
                variant="eyebrowSmall"
                sx={{
                  mb: 6,
                }}
              >
                Day of the week
              </Typography>

              <List
                sx={{
                  p: 0,
                }}
              >
                {days.map(({ id, name }, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      p: 0,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={id}
                          onChange={handleDaysSelection}
                          checked={selectedDays.includes(id)}
                          name="filter[day_ids][]"
                        />
                      }
                      label={name}
                      componentsProps={{
                        typography: {
                          variant: "bodyMedium",
                        },
                      }}
                    />
                  </ListItem>
                ))}
              </List>
              <Box
                sx={{
                  display: "flex",
                  py: 4,
                  gap: 4,
                  position: "sticky",
                  bottom: 0,
                  justifyContent: "flex-end",
                  backgroundColor: "common.white",
                }}
              >
                <Button
                  color="primary"
                  variant="text"
                  onClick={() => {
                    setDateChipsUpdated("");
                    setDaysChipsUpdated("");
                    resetDates();
                  }}
                  sx={{
                    px: 10,
                  }}
                >
                  Reset
                </Button>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    handleCloseDate();
                    updateDatesChips();
                    updateDaysChips();
                    submitForm();
                  }}
                  sx={{
                    px: 10,
                  }}
                >
                  Apply
                </Button>
              </Box>
            </Popover>
          </FormControl>
          <FormControl
            size="small"
            sx={{
              height: 44,
              minWidth: {
                xs: 160,
                lg: 172,
              },
              m: 0,
              order: 4,
            }}
          >
            <Button
              variant="outlinedDropdown"
              color="primary"
              type="button"
              onClick={handleClickInstitutions}
              disableFocusRipple={true}
              endIcon={
                institutionPopoverIsOpen ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )
              }
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexGrow: 1,
                boxShadow: institutionPopoverIsOpen ? `0 0 0 2px #1271C4` : "",
              }}
              aria-describedby={institutionPopoverId}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  textAlign: "left",
                }}
              >
                Institutions
              </Box>
              <Badge
                badgeContent={selectedInstitutionsIds.length}
                color="primary"
                sx={{
                  ml: 2,
                }}
              />
            </Button>
            <Popover
              id={institutionPopoverId}
              container={formContainer}
              keepMounted
              open={institutionPopoverIsOpen}
              onClose={() => {
                restoreTmpInstitutions();
                handleCloseInstitutions();
              }}
              anchorEl={institutionAnchorEl}
              anchorOrigin={{
                vertical: 52,
                horizontal: "left",
              }}
              componentsProps={{
                backdrop: {
                  style: {
                    backgroundColor: "transparent",
                  },
                },
              }}
              PaperProps={{
                sx: {
                  borderRadius: 5.5,
                  display: "flex",
                  flexDirection: "column",
                  px: 6,
                  pt: 6,
                },
              }}
            >
              <Typography
                variant="eyebrowSmall"
                color="text.secondary"
                sx={{
                  mb: 6,
                }}
              >
                Institutions
              </Typography>
              <TextField
                size="small"
                inputProps={{
                  sx: {
                    pl: {
                      xs: 4,
                      md: 8,
                    },
                  },
                }}
                sx={{
                  flexGrow: 3,
                  mb: 5,
                  width: "100%",
                  "& fieldset": {
                    borderRadius: 8,
                  },
                }}
                placeholder="Search"
                onChange={(event) => {
                  setSearchInstitutionValue(event.target.value.toLowerCase());
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                  }
                }}
                value={searchInstitutionValue}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchInstitutionValue ? (
                        <IconButton
                          aria-label="Clear Institution Search"
                          type="button"
                          onClick={cleanInstitutionSearch}
                        >
                          <CloseIcon color="primary" />
                        </IconButton>
                      ) : (
                        <SearchOutlinedIcon />
                      )}
                    </InputAdornment>
                  ),
                  inputProps: { "aria-label": "Institutions" },
                }}
              />
              <Divider
                color="background.primary"
                sx={{
                  backgroundColor: "common.hoverFill",
                  mb: 7,
                }}
              />
              <List
                sx={{
                  p: 0,
                }}
              >
                {institutions.map(({ id, name }, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      p: 0,
                      display: searchedInstitutions.includes(id) ? "" : "none",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={id}
                          onChange={handleInstitutionSelection}
                          checked={selectedInstitutionsIds.includes(id)}
                          name="filter[target_school_ids][]"
                        />
                      }
                      label={name}
                      componentsProps={{
                        typography: {
                          variant: "bodyMedium",
                        },
                      }}
                    />
                  </ListItem>
                ))}
              </List>
              <Box
                sx={{
                  display: "flex",
                  py: 4,
                  gap: 4,
                  position: "sticky",
                  bottom: 0,
                  justifyContent: "flex-end",
                  backgroundColor: "common.white",
                }}
              >
                <Button
                  color="primary"
                  variant="text"
                  onClick={resetInstitutions}
                  sx={{
                    px: 10,
                  }}
                >
                  Reset
                </Button>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    handleCloseInstitutions();
                    updateInstitutionsChips();
                    submitForm();
                  }}
                  sx={{
                    px: 10,
                  }}
                >
                  Apply
                </Button>
              </Box>
            </Popover>
          </FormControl>
          <FormControl
            size="small"
            sx={{
              height: 44,
              minWidth: {
                xs: 142,
                lg: 172,
              },
              m: 0,
              order: {
                xs: 1,
                md: 7,
              },
            }}
          >
            <Button
              variant="outlinedDropdown"
              color="primary"
              type="button"
              onClick={openMoreFilter}
              disableFocusRipple={true}
              startIcon={<TuneIcon />}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexGrow: 1,
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  textAlign: "left",
                }}
              >
                More Filters
              </Box>
            </Button>
            <Drawer
              open={moreFilterIsOpen}
              onClose={onCloseMoreFilter}
              container={formContainer}
              anchor="right"
              // hideBackdrop={true}
              componentsProps={{
                backdrop: {
                  style: {
                    backgroundColor: "transparent",
                  },
                },
              }}
              role="presentation"
              ModalProps={{
                keepMounted: true,
              }}
              PaperProps={{
                sx: {
                  backgroundColor: "background.primary",
                  backgroundImage: "none",
                  border: "none",
                  color: "text.primary",
                  borderRadius: 0,
                  width: {
                    xs: "100%",
                    sm: 375,
                  },
                  height: {
                    xs: "100%",
                    sm: "calc(100% - 64px)",
                    md: "calc(100% - 96px)",
                  },
                  top: {
                    xs: 0,
                    sm: 64,
                    md: 96,
                  },
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  py: 2,
                  pl: 6,
                  pr: 3,
                }}
              >
                <Typography variant="titleMedium" color="text.primary">
                  Filters
                </Typography>
                <IconButton
                  size="large"
                  color="inherit"
                  aria-label="Close More Filters"
                  onClick={onCloseMoreFilter}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Divider
                color="background.primary"
                sx={{
                  backgroundColor: "common.hoverFill",
                }}
              />
              <Box
                sx={{
                  overflow: "auto",
                }}
              >
                <AllFiltersAccordion
                  minCredsValue={minCredsValue}
                  maxCredsValue={maxCredsValue}
                  setMinCredsValue={setMinCredsValue}
                  setMaxCredsValue={setMaxCredsValue}
                  sessions={sessions}
                  selectedSessions={selectedSessions}
                  setSelectedSessions={setSelectedSessions}
                  deliveryMethods={deliveryMethods}
                  selectedMethods={selectedMethods}
                  setSelectedMethods={setSelectedMethods}
                  selectedSelfPaced={selectedSelfPaced}
                  setSelectedSelfPaced={setSelectedSelfPaced}
                  allFiltersSearch={allFiltersSearch}
                  creditsRangeError={creditsRangeError}
                  setCreditsRangeError={setCreditsRangeError}
                  minCreditsErrorMessage={minCreditsErrorMessage}
                  setMinCreditsErrorMessage={setMinCreditsErrorMessage}
                  maxCreditsErrorMessage={maxCreditsErrorMessage}
                  setMaxCreditsErrorMessage={setMaxCreditsErrorMessage}
                  sortValue={sortValue}
                  setSortValue={setSortValue}
                  sortTextValue={sortTextValue}
                  setSortTextValue={setSortTextValue}
                />
              </Box>
              <Box
                sx={{
                  mt: "auto",
                  display: "flex",
                  px: 8,
                  pt: 4,
                  pb: 6,
                  gap: 4,
                  borderTopWidth: "1px",
                  borderColor: "common.hoverFill",
                  bg: "common.canvas",
                }}
              >
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={moreFiltersClean}
                  sx={{
                    width: "130px",
                    flexShrink: "0",
                  }}
                >
                  Reset
                </Button>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  disabled={creditsRangeError}
                  onClick={allFiltersSearch}
                  sx={{
                    width: "100%",
                  }}
                >
                  Search
                </Button>
              </Box>
            </Drawer>
          </FormControl>
        </Box>
      </Box>
      {/* Search Chips */}

      {!mobileBreakpoint && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            pt: 10,
            gap: {
              xs: 4,
              md: 6,
            },
          }}
        >
          {subjectsChips.length > 0 && (
            <Box>
              <Typography
                color="text.secondary"
                variant="eyebrowSmall"
                sx={{
                  mb: 2.5,
                }}
              >
                Subjects
              </Typography>
              <List
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  p: 0,
                }}
              >
                {subjectsChips.map(({ id, name }, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      p: 0,
                      width: "auto",
                    }}
                  >
                    <Button
                      type="button"
                      variant="chip"
                      aria-label={`Remove ${name} filter`}
                      endIcon={<CloseIcon color="primary" />}
                      onClick={() => {
                        removeSubjectId(id);
                      }}
                    >
                      {name}
                    </Button>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          {(dateChip || daysChip) && (
            <Box>
              <Typography
                color="text.secondary"
                variant="eyebrowSmall"
                sx={{
                  mb: 2.5,
                }}
              >
                Date & Time
              </Typography>
              <List
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  p: 0,
                }}
              >
                {dateChip && (
                  <ListItem
                    sx={{
                      p: 0,
                      width: "auto",
                    }}
                  >
                    <Button
                      type="button"
                      variant="chip"
                      aria-label="Remove dates filter"
                      endIcon={<CloseIcon color="primary" />}
                      onClick={() => {
                        removeDates();
                      }}
                    >
                      {dateChip}
                    </Button>
                  </ListItem>
                )}
                {daysChip && (
                  <ListItem
                    sx={{
                      p: 0,
                      width: "auto",
                    }}
                  >
                    <Button
                      type="button"
                      variant="chip"
                      aria-label="Remove days filter"
                      endIcon={<CloseIcon color="primary" />}
                      onClick={() => {
                        removeDays();
                      }}
                    >
                      {daysChip}
                    </Button>
                  </ListItem>
                )}
              </List>
            </Box>
          )}
          {institutionsChips.length > 0 && (
            <Box>
              <Typography
                color="text.secondary"
                variant="eyebrowSmall"
                sx={{
                  mb: 2.5,
                }}
              >
                Institutions
              </Typography>
              <List
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  p: 0,
                }}
              >
                {institutionsChips.map(({ id, name }, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      p: 0,
                      width: "auto",
                    }}
                  >
                    <Button
                      type="button"
                      variant="chip"
                      aria-label={`Remove ${name} filter`}
                      endIcon={<CloseIcon color="primary" />}
                      onClick={() => {
                        removeInstitutionId(id);
                      }}
                    >
                      {name}
                    </Button>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          {(sessionsChip.length > 0 || selfPacedChip) && (
            <Box>
              <Typography
                color="text.secondary"
                variant="eyebrowSmall"
                sx={{
                  mb: 2.5,
                }}
              >
                Term
              </Typography>
              <List
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  p: 0,
                }}
              >
                <>
                  {sessionsChip.length > 0 &&
                    sessionsChip.map((session, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          p: 0,
                          width: "auto",
                        }}
                      >
                        <Button
                          type="button"
                          variant="chip"
                          aria-label={`Remove ${session} filter`}
                          endIcon={<CloseIcon color="primary" />}
                          onClick={() => {
                            removeSession(session);
                          }}
                        >
                          {session}
                        </Button>
                      </ListItem>
                    ))}
                </>
                <>
                  {selfPacedChip && (
                    <ListItem
                      sx={{
                        p: 0,
                        width: "auto",
                      }}
                    >
                      <Button
                        type="button"
                        variant="chip"
                        aria-label="Remove self pace filter"
                        endIcon={<CloseIcon color="primary" />}
                        onClick={() => {
                          removeSelfPaced();
                        }}
                      >
                        Self Paced
                      </Button>
                    </ListItem>
                  )}
                </>
              </List>
            </Box>
          )}
          {creditChip && (
            <Box>
              <Typography
                color="text.secondary"
                variant="eyebrowSmall"
                sx={{
                  mb: 2.5,
                }}
              >
                Credits
              </Typography>
              <List
                sx={{
                  display: "flex",
                  gap: 2,
                  p: 0,
                }}
              >
                <ListItem
                  sx={{
                    p: 0,
                    width: "auto",
                  }}
                >
                  <Button
                    type="button"
                    variant="chip"
                    aria-label="Remove credits filters"
                    endIcon={<CloseIcon color="primary" />}
                    onClick={() => {
                      removeCredits();
                    }}
                  >
                    {creditChip}
                  </Button>
                </ListItem>
              </List>
            </Box>
          )}
          {methodsChips.length > 0 && (
            <Box>
              <Typography
                color="text.secondary"
                variant="eyebrowSmall"
                sx={{
                  mb: 2.5,
                }}
              >
                Learning Format
              </Typography>
              <List
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  p: 0,
                }}
              >
                {methodsChips.map(({ value, name }, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      p: 0,
                      width: "auto",
                    }}
                  >
                    <Button
                      type="button"
                      variant="chip"
                      aria-label={`Remove ${name} filter`}
                      endIcon={<CloseIcon color="primary" />}
                      onClick={() => {
                        removeMethod(value);
                      }}
                    >
                      {name}
                    </Button>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          {anyChip && (
            <Box
              sx={{
                pt: "26px",
              }}
            >
              <Button
                variant="chip"
                href="/search"
                size="small"
                sx={{
                  backgroundColor: "transparent",
                  borderColor: "rgba(0, 0, 0, 0.42)",
                  pr: 4,
                }}
              >
                Clear All
              </Button>
            </Box>
          )}
        </Box>
      )}
    </ThemeProvider>
  );
}
