import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["customField", "exceptCheckbox"];

  changeValue(element) {
    if (element.target.value == "custom") {
      this.customFieldTarget.disabled = false;
      this.exceptCheckboxTarget.disabled = false;
    } else {
      this.customFieldTarget.disabled = true;
      this.exceptCheckboxTarget.disabled = true;
    }
  }
}
