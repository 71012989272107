/* globals $ */
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.focusCloseButton();
  }

  focusCloseButton() {
    setTimeout(function () {
      $("#modal-close-button").focus();
    }, 1);
  }
}
