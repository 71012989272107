import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source", "notice"];

  copy() {
    navigator.clipboard.writeText(this.sourceTarget.value);
    if (this.hasNoticeTarget) {
      this.noticeTarget.classList.remove("hidden");
    }
  }
}
