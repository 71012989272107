/*globals $ */

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select"];

  connect() {
    let controller = this;

    $(controller.selectTarget).selectize({
      plugins: ["selectize-plugin-a11y"],
      valueField: "id",
      labelField: "name",
      options: [],
      closeAfterSelect: true,
      score: function () {
        return function () {
          return 1;
        };
      },
      load: function (query) {
        $.ajax({
          url: controller.universitiesUrl(),
          dataType: "json",
          type: "GET",
          data: { term: query },
          success: (response) => {
            let results;

            if (!response.universities.length) {
              results = [{ id: "", name: "No Results" }];
            } else {
              results = response.universities;
            }

            this.clearOptions();
            this.addOption(results);
            this.refreshOptions(true);
          },
        });
      },
      render: {
        option: function (item) {
          // Every option must have a unique id
          return `<div class="option" role="option" id="${item.name.replace(
            / /g,
            ""
          )}">${item.name}</div>`;
        },
      },
    });
  }

  universitiesUrl() {
    let customUniversitiesUrl = this.data.get("url");
    if (customUniversitiesUrl != null) {
      return customUniversitiesUrl;
    } else {
      return "/api/universities.json";
    }
  }
}
