import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "button"];

  toggle() {
    const button = this.buttonTarget;
    if (this.containerTarget.classList.contains("collapsed")) {
      this.containerTarget.classList.remove("collapsed");
      this.containerTarget.classList.add("expanded");
      button.querySelector(".text").innerText = "Show Less";
    } else {
      button.querySelector(".text").innerText = "Show More";
      this.containerTarget.classList.add("collapsed");
      this.containerTarget.classList.remove("expanded");
      this.containerTarget.scrollIntoView();
    }
  }
}
