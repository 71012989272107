import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["wrapper", "content"];

  async open() {
    await this.load();
    this.wrapperTarget.classList.remove("hidden");
    document.body.classList.add("modal-open");

    var focusableElements = [
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
    ];
    var modal = this.wrapperTarget;

    document.addEventListener("keydown", function (e) {
      let isTabPressed = e.key === "Tab" || e.keyCode === 9;

      if (!isTabPressed) {
        return;
      }

      var firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
      var focusableContent = modal.querySelectorAll(focusableElements);
      var lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

      if (e.shiftKey) {
        // if shift key pressed for shift + tab combination
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus(); // add focus for the last focusable element
          e.preventDefault();
        }
      } else {
        // if tab key is pressed
        if (firstFocusableElement) {
          if (document.activeElement === lastFocusableElement) {
            // if focused has reached to last focusable element then focus first focusable element after pressing tab
            firstFocusableElement.focus(); // add focus for the first focusable element
            e.preventDefault();
          }
        }
      }
    });
  }

  close() {
    this.wrapperTarget.classList.add("hidden");
    this.contentTarget.innerHTML = "";
    document.body.classList.remove("modal-open");
  }

  jumpToUrl(event) {
    let url = event.target.dataset["targetUrl"];
    location.href = url;
  }

  async load() {
    let response = await fetch(this.data.get("url"), {
      headers: { "X-Requested-With": "XMLHttpRequest" },
    });
    let html = await response.text();
    this.contentTarget.innerHTML = html;
  }
}
