import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "homeCourseId",
    "homeRequirementId",
    "teachingCourseId",
    "formDetailContainer",
    "endDateInput",
    "endDateInputWrapper",
    "majorSelect",
    "majorCheckbox",
    "submit",
  ];

  connect() {
    this.conditionallyShowFormDetail();
  }

  conditionallyShowFormDetail() {
    if (
      (this.homeCourseIdTargets.length &&
        this.teachingCourseIdTargets.length) ||
      (this.teachingCourseIdTargets.length &&
        this.hasHomeRequirementIdTarget &&
        this.homeRequirementIdTarget.value)
    ) {
      this.submitTarget.removeAttribute("disabled");
    } else {
      this.submitTarget.disabled = "disabled";
    }
  }

  setAsOngoing(event) {
    if (event.target.checked) {
      this.endDateInputTarget.value = "";
      this.endDateInputTarget.disabled = "disabled";
      this.endDateInputWrapperTarget.style["display"] = "none";
    } else {
      this.endDateInputWrapperTarget.style["display"] = null;
      this.endDateInputTarget.removeAttribute("disabled");
    }
  }

  toggleMajor() {
    if (this.majorCheckboxTarget.checked) {
      this.majorSelectTarget.removeAttribute("disabled");
      this.majorSelectTarget.classList.remove("hidden");
      this.majorSelectTarget.focus();
    } else {
      this.majorSelectTarget.disabled = "disabled";
      this.majorSelectTarget.classList.add("hidden");
      this.majorSelectTarget.value = "";
    }
  }
}
