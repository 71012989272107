import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect() {}

  update() {
    this.buttonTarget.click();
  }
}
