import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
import toastr from "toastr";

export default class extends Controller {
  static targets = ["newConfigKey", "newConfigValue", "updateConfigButton"];

  connect() {
    StimulusReflex.register(this);
  }

  afterApply(element) {
    toastr.options = {
      progressBar: true,
      preventDuplicates: false,
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "5000",
      extendedTimeOut: "3000",
    };

    toastr.info(
      '<div>Restart enqueued!</div><div><a href="' +
        element.dataset["tasksUrl"] +
        '" target="_blank">Check task progress here</a></div>'
    );
  }

  update(event) {
    var configKey = event.target.dataset["configKey"];
    var button = this.updateConfigButtonTargets.find(function (el) {
      return el.getAttribute("data-config-key") == configKey;
    });
    button.value = event.target.value;
  }

  afterUpdate(updateButton) {
    var configKey = updateButton.dataset["configKey"];
    toastr.info(configKey + " updated!");
  }

  add(event) {
    var newKey = this.newConfigKeyTarget.value;
    event.preventDefault();
    this.stimulate(
      "SISIntegrationAppConfigReflex#add",
      event.target,
      this.newConfigKeyTarget.value,
      this.newConfigValueTarget.value
    )
      .then(() => toastr.info(newKey + " added!"))
      .catch(() => this.newConfigKeyTarget.focus());
  }

  afterAdd() {
    this.newConfigKeyTarget.value = "";
    this.newConfigValueTarget.value = "";
  }

  afterDestroy(element) {
    var configKey = element.dataset["configKey"];
    toastr.info(configKey + " removed!");
  }
}
