/*eslint no-constant-condition: ["error", { "checkLoops": false }]*/

import { Controller } from "stimulus";
import CableReady from "cable_ready";
import consumer from "../channels/consumer";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  static values = { domId: String, batchApplicationId: String };

  connect() {
    StimulusReflex.register(this);
    const self = this;
    self.channel = consumer.subscriptions.create(
      {
        channel: "BatchApplicationFinancialAidChannel",
        id: self.domIdValue,
      },
      {
        connected() {
          self.stimulate(
            "BatchApplicationFinancialAidResults#load_financial_aid_items",
            self.domIdValue,
            self.batchApplicationIdValue
          );
        },
        received(data) {
          if (data.cableReady) CableReady.perform(data.operations);
        },
      }
    );
  }
}
