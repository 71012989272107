import { Modal } from "tailwindcss-stimulus-components";
export default class extends Modal {
  connect() {
    super.connect();
    this.modalOpen = false;
  }

  open(event) {
    var focusableElements = [
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
    ];

    super.open(event);
    this.modalOpen = true;
    var modal = this.containerTarget;

    document.addEventListener("keydown", function (e) {
      let isTabPressed = e.key === "Tab" || e.keyCode === 9;

      if (!isTabPressed) {
        return;
      }

      var firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
      var focusableContent = modal.querySelectorAll(focusableElements);
      var lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

      if (e.shiftKey) {
        // if shift key pressed for shift + tab combination
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus(); // add focus for the last focusable element
          e.preventDefault();
        }
      } else {
        // if tab key is pressed
        if (firstFocusableElement) {
          if (document.activeElement === lastFocusableElement) {
            // if focused has reached to last focusable element then focus first focusable element after pressing tab
            firstFocusableElement.focus(); // add focus for the first focusable element
            e.preventDefault();
          }
        }
      }
    });
  }

  close(event) {
    super.close(event);
    this.modalOpen = false;
  }

  openIfValid(event) {
    var form = event.target.form;
    if (form.checkValidity()) {
      this.open(event);
    } else {
      form.reportValidity();
    }
  }
}
