import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["mobileNav", "menuButton", "header"];

  connect() {
    if (window.innerWidth < 1023) {
      this.mobileNavTarget.hidden = true;
    }
  }

  toggleNavbar() {
    if (this.mobileNavTarget.classList.contains("opened")) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  openMenu() {
    this.menuButtonTarget.setAttribute("aria-expanded", "true");
    this.menuButtonTarget.classList.add("open");
    this.mobileNavTarget.hidden = false;
    this.mobileNavTarget.classList.add("opened");
    document.body.classList.add("modal-open");
    setTimeout(() => this.headerTarget.classList.add("mmenu-open"), 100);
  }

  closeMenu() {
    this.menuButtonTarget.setAttribute("aria-expanded", "false");
    this.menuButtonTarget.classList.remove("open");

    this.headerTarget.classList.remove("mmenu-open");
    document.body.classList.remove("modal-open");
    setTimeout(() => {
      if (window.innerWidth < 1023) {
        this.mobileNavTarget.hidden = true;
      }
      this.mobileNavTarget.classList.remove("opened");
    }, 501);
  }

  resizeClose() {
    if (window.innerWidth > 1023) {
      this.closeMenu();
    }
  }
}
