import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
import consumer from "../../channels/consumer";

export default class extends Controller {
  static values = { batchApplicationId: Number };
  static targets = ["dropping", "drop", "droppingMobile", "dropMobile"];

  connect() {
    StimulusReflex.register(this);
  }

  processDrop() {
    this.dropTarget.classList.add("hidden");
    this.droppingTarget.classList.remove("hidden");
    this.dropMobileTarget.classList.add("hidden");
    this.droppingMobileTarget.classList.remove("hidden");

    this.stimulate("BatchApplications#drop_ba", this.batchApplicationIdValue);
  }
}
