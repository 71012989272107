import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "consentCheckbox1",
    "consentCheckbox2",
    "consentCheckbox3",
    "consentCheckbox4",
    "consentCheckbox5",
    "consentCheckbox6",
    "consentCheckbox7",
    "consentCheckbox8",
    "continueButton",
  ];

  onAcceptedTerms() {
    if (
      this.consentCheckbox1Target.checked &&
      this.consentCheckbox2Target.checked &&
      this.consentCheckbox3Target.checked &&
      this.consentCheckbox4Target.checked &&
      this.consentCheckbox5Target.checked &&
      this.consentCheckbox6Target.checked &&
      this.consentCheckbox7Target.checked &&
      this.consentCheckbox8Target.checked
    ) {
      this.continueButtonTarget.removeAttribute("disabled");
    } else {
      this.continueButtonTarget.setAttribute("disabled", "disabled");
    }
  }
}
