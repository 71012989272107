import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  static targets = ["attributeMappingEmail"];

  connect() {
    StimulusReflex.register(this);
  }

  toggle_visibility(event) {
    event.preventDefault();
    let element = event.target;
    this.stimulate(
      "Exchange::Admin::SsoProviders#toggle_visibility",
      element.dataset.providerId,
      element.checked
    );
  }
}
