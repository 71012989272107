export const footerLinks = (domain) => [
  {
    title: "My HBCUv",
    href: "#",
    show: "Auth",
    subTitles: [
      {
        title: "The Yard",
        href: `${domain}/the-yard`,
      },
      {
        title: "My Circle",
        href: `${domain}/my-circle`,
      },
      {
        title: "Tap in",
        href: `${domain}/tap-in`,
      },
      {
        title: "My Path",
        href: `${domain}/my-path`,
      },
      {
        title: "My Courses",
        href: `${domain}/my-courses`,
      },
      {
        title: "Course Catalog",
        href: "/search",
      },
    ],
  },
  {
    title: "About HBCUv",
    href: "#",
    show: "Unauth",
    subTitles: [
      {
        title: "Mission & Vision",
        href: `${domain}/what-is-hbcuv#mission-and-vision`,
      },
      {
        title: "Team",
        href: `${domain}/what-is-hbcuv#meet-the-team`,
      },
      {
        title: "FAQ",
        href: "https://uncf.org/pages/facts-and-questions",
        target: 1,
      },
      {
        title: "Contact Us",
        href: `${domain}/form/contact-us`,
        target: 1,
      },
      {
        title: "Press Kit",
        href: "https://uncficb.org/press-kit",
        target: 1,
      },
    ],
  },
  {
    title: "About HBCUv",
    href: "#",
    show: "Auth",
    subTitles: [
      {
        title: "HBCU Legacy",
        href: "https://uncficb.org/about/timeline/",
        target: 1,
      },
      {
        title: "About Us",
        href: `${domain}/what-is-hbcuv`,
      },
      {
        title: "UNCF.org",
        href: "https://uncf.org/",
        target: 1,
      },
      {
        title: "Contact Us",
        href: `${domain}/form/contact-us`,
        target: 1,
      },
    ],
  },
  {
    title: "More Information",
    href: "#",
    show: "Unauth",
    subTitles: [
      {
        title: "Faculty & Admin",
        href: `${domain}/form/faculty-and-admin`,
        target: 1,
      },
      {
        title: "Institutional Donors",
        href: `${domain}/form/institutional-donors`,
        target: 1,
      },
      {
        title: "Corporate Partners",
        href: `${domain}/form/corporate-partners`,
        target: 1,
      },
    ],
  },
  {
    title: "News Updates",
    href: "#",
    show: "Unauth",
    subTitles: [
      {
        title: "Articles",
        href: "https://uncf.org/the-latest",
        target: 1,
      },
      {
        title: "Press Releases",
        href: "https://uncf.org/media-center",
        target: 1,
      },
      {
        title: "Videos",
        href: "https://uncf.org/media-center",
        target: 1,
      },
      {
        title: "Research",
        href: "https://uncf.org/pages/fdpri-reports",
        target: 1,
      },
    ],
  },
  {
    title: "Get Involved",
    herf: "#",
    show: "Unauth",
    subTitles: [
      {
        title: "Become a Sponsor",
        href: `${domain}/form/become-a-sponsor`,
        target: 1,
      },
      {
        title: "Partner with HBCUv",
        href: `${domain}/form/partner-with-hbcuv`,
        target: 1,
      },
      {
        title: "Donate",
        href: "https://uncf.org/hbcuv",
        target: 1,
      },
    ],
  },
  {
    title: "Get Involved",
    herf: "#",
    show: "Auth",
    subTitles: [
      {
        title: "Donate",
        href: "https://uncf.org/hbcuv",
        target: 1,
      },
      {
        title: "Become a Champion",
        href: `${domain}/form/become-a-champion`,
        target: 1,
      },
      {
        title: "Give Feedback",
        href: `${domain}/form/give-feedback`,
        target: 1,
      },
    ],
  },
];
