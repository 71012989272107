/* global fbq */

import $ from "jquery";

$(document).ready(function () {
  try {
    if (typeof fbq !== "undefined") fbq("track", "ViewContent");
  } catch (err) {
    console.log("caught:", err);
  }
});

window.paginate = function () {
  $("#search-button-for-table").click();
};
