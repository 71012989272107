import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "filtersSidePanel",
    "form",
    "liveSeatFilter",
    "onlyAvailableFilter",
    "sortOptionsMobilePanel",
    "searchResume",
    "searchOptions",
  ];

  showFiltersSidePanelOnMobile() {
    this.filtersSidePanelTarget.classList.remove("hidden");
    this.filtersSidePanelTarget.classList.add("block");
    document.body.classList.add("modal-open");
  }

  hideFiltersSidePanelOnMobile() {
    this.filtersSidePanelTarget.classList.remove("block");
    this.filtersSidePanelTarget.classList.add("hidden");
    document.body.classList.remove("modal-open");
  }

  showSortingOptionsOnMobile() {
    this.sortOptionsMobilePanelTarget.style["display"] = "block";
  }

  hideSortingOptionsOnMobile() {
    this.sortOptionsMobilePanelTarget.style["display"] = "none";
  }

  hideSearchResume() {
    this.searchResumeTarget.classList.remove("block");
    this.searchResumeTarget.classList.add("hidden");
    this.searchOptionsTarget.classList.remove("hidden");
    this.searchOptionsTarget.classList.add("block");
  }

  refreshResults() {
    if (this.formTarget.checkValidity()) {
      this.formTarget.submit();
    } else {
      this.formTarget.reportValidity();
    }
  }

  toggleLiveSeatsFilter() {
    if (this.onlyAvailableFilterTarget.checked) {
      this.liveSeatFilterTarget.checked = "checked";
    }
  }

  toggleOnlyAvailableFilter() {
    if (
      this.onlyAvailableFilterTarget !== undefined &&
      this.liveSeatFilterTarget.checked
    ) {
      this.onlyAvailableFilterTarget.checked = false;
    }
  }
}
