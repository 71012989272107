import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["courseId", "courseRow"];

  remove() {
    // Init a custom DOM event.
    // Recommended approach to communicate between controllers
    // https://github.com/stimulusjs/stimulus/issues/200#issuecomment-434731830
    const event = document.createEvent("CustomEvent");
    event.initCustomEvent("on-course-removed", true, true, {
      courseId: this.courseIdTarget.value,
      courseRow: this.courseRowTarget,
    });
    this.element.dispatchEvent(event);
  }
}
