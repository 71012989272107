import $ from "jquery";

var selector = "a[data-alertable-type]";

var Alerts = (function (jQ, selectQuery) {
  var self = {};

  self.createAlert = function (event) {
    var alertable_id = $(event.target).data("alertable-id");
    var alertable_type = $(event.target).data("alertable-type");
    if (!(alertable_id === undefined || alertable_id === "")) {
      $(event.target).addClass("active");
      $.ajax({
        url: "/alerts.js",
        method: "POST",
        data: {
          alert: {
            alertable_id: alertable_id,
            alertable_type: alertable_type,
          },
        },
        success: function () {
          $("#saved-alert-message").css("display", "inline");
          $("#save-search-button").css("display", "none");
          // For modal
          $(".saved-search-message").removeClass("hidden");
          $(".setalert a").addClass("hidden");
        },
      });
    }
  };

  self.bind = function () {
    jQ(selectQuery).on("click", self.createAlert);
  };

  return self;
})($, selector);

$(document).ready(Alerts.bind);
