/* global $ */

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];
  static values = {
    preselectedId: Number,
    preselectedName: String,
    allowOptionCreation: Boolean,
  };

  connect() {
    this.create();
  }

  // Selectize needs to be destroyed before the page morphs so it can be re-created after the page is morphed.
  // Otherwise the dom elements it creates are left hanging around and cause problems
  destroy() {
    // After destroying the selectize element during a morph-dom operation, for whatever reason
    // the select is being assigned as val the last option.

    // By manually getting the value _before_ destroying, and then re-assigning it, this issue is fixed.
    // Strangely, in Chrome on OS X this issue was not present.

    // We should get rid of selectize ASAP, mostly because jQuery plugins do not go along very well with
    // StimulusReflex
    let selectedValue = $(this.inputTarget).val();
    $(this.inputTarget)[0].selectize.destroy();
    $(this.inputTarget).val(selectedValue);
  }

  create() {
    let controller = this;
    let options = {
      plugins: ["selectize-plugin-a11y"],
      onInitialize: function () {
        let idValue =
          controller.preselectedIdValue || controller.preselectedNameValue;
        if (idValue) {
          this.addOption([
            {
              value: idValue,
              text: controller.preselectedNameValue,
            },
          ]);
          this.setValue(idValue, true);
        }
      },
      render: {
        option: function (item) {
          // Every option must have a unique id
          return `<div class="option" role="option" id="${item.text.replace(
            / /g,
            ""
          )}">${item.text}</div>`;
        },
      },
    };

    if (controller.allowOptionCreationValue) {
      options["create"] = function (input) {
        return { value: input, text: input };
      };
    }
    $(controller.inputTarget).selectize(options);
  }
}
