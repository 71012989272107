import { Controller } from "stimulus";
import tippy from "tippy.js";

export default class extends Controller {
  static values = { url: String };

  connect() {
    let urlValue = this.urlValue;

    this.tippy = tippy(this.element, {
      allowHTML: true,
      interactive: true,
      aria: {
        content: "describedby",
      },
      onCreate(instance) {
        instance._isFetching = false;
        instance._error = null;
      },
      onShow(instance) {
        if (instance._isFetching || instance._error) {
          return;
        }

        instance._isFetching = true;
        fetch(urlValue, { headers: { "X-Requested-With": "XMLHttpRequest" } })
          .then((response) => response.text())
          .then((html) => {
            instance.setContent(html);
          })
          .catch((error) => {
            instance._error = error;
            instance.setContent(`Request failed. ${error}`);
          })
          .finally(() => {
            instance._isFetching = false;
          });
      },
      onHidden(instance) {
        instance.setContent("Loading...");
        instance._src = null;
        instance._error = null;
      },
    });
  }

  disconnect() {
    this.tippy.destroy();
  }
}
