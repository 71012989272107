/* globals $ */
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "passwordInput",
    "passwordShow",
    "passwordHide",
    "middleNameInput",
    "middleNameCheckbox",
    "middleNameLabel",
    "separateMailingAddressCheckbox",
    "separateAddress",
    "mailingAddressField",
  ];
  connect() {
    if (this.hasMiddleNameCheckboxTarget) {
      this.toggleMiddleNameRequired();
    }
    if (this.hasMiddleNameCheckboxTarget) {
      this.toggleSeparateMailingAddress();
    }
  }

  togglePassword() {
    const type =
      $(this.passwordInputTarget).attr("type") === "password"
        ? "text"
        : "password";
    $(this.passwordInputTarget).attr("type", type);

    $(this.passwordShowTarget).toggleClass("hidden").toggleClass("flex");
    $(this.passwordHideTarget).toggleClass("hidden").toggleClass("flex");
  }

  toggleMiddleNameRequired() {
    if (this.middleNameCheckboxTarget.checked) {
      this.middleNameInputTarget.removeAttribute("required");
      this.middleNameLabelTarget.classList.remove("required");
    } else {
      this.middleNameInputTarget.setAttribute("required", "required");
      this.middleNameLabelTarget.classList.add("required");
    }
  }

  toggleSeparateMailingAddress() {
    if (this.separateMailingAddressCheckboxTarget.checked) {
      this.separateAddressTarget.classList.remove("hidden");
      this.mailingAddressFieldTarget.setAttribute("required", "required");
      this.mailingAddressFieldTargets.forEach((t) =>
        t.setAttribute("required", "required")
      );
    } else {
      this.separateAddressTarget.classList.add("hidden");
      this.mailingAddressFieldTarget.removeAttribute("required");
      this.mailingAddressFieldTargets.forEach((t) =>
        t.removeAttribute("required")
      );
    }
  }
}
