/* globals $ */

import { Controller } from "stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static targets = [
    "container",
    "multiselectionButton",
    "listContainer",
    "multiselectionList",
    "checkbox",
    "noResults",
    "option",
    "searchInput",
    "clearSearchButton",
    "unselectAllButton",
  ];
  static values = {
    placeholder: String,
  };

  connect() {
    useClickOutside(this);
    this.optionsChanged();
  }

  toggleMultiselection() {
    if (
      this.multiselectionButtonTarget.getAttribute("aria-expanded") == "true"
    ) {
      this.hideMultiselection();
    } else {
      this.showMultiselection();
    }
  }

  showMultiselection() {
    const self = this;
    const focusableElements = ["button", "input"];
    const container = this.containerTarget;

    this.multiselectionButtonTarget.setAttribute("aria-expanded", true);
    this.listContainerTarget.setAttribute("aria-hidden", false);
    this.multiselectionButtonTarget.parentElement.classList.add("opened");
    this.multiselectionButtonTarget.parentElement.classList.remove("closed");

    this.containerTarget.addEventListener("keydown", function (e) {
      const firstFocusableElement =
        container.querySelectorAll(focusableElements)[0];
      const focusableContent = container.querySelectorAll(focusableElements);
      const lastFocusableElement =
        focusableContent[focusableContent.length - 1];

      if (e.key === "Escape") {
        self.hideMultiselection();
        self.multiselectionButtonTarget.focus();
        return;
      }

      if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          self.hideMultiselection();
        }
      } else {
        if (
          (document.activeElement === lastFocusableElement ||
            (lastFocusableElement.disabled &&
              focusableContent[focusableContent.length - 2] ===
                document.activeElement)) &&
          !(e.key === " " || e.keyCode === 32)
        ) {
          self.hideMultiselection();
        }
      }
    });

    this.checkViewport();
  }

  hideMultiselection() {
    this.multiselectionButtonTarget.setAttribute("aria-expanded", false);
    this.listContainerTarget.setAttribute("aria-hidden", true);
    this.multiselectionButtonTarget.parentElement.classList.add("closed");
    this.multiselectionButtonTarget.parentElement.classList.remove("opened");
    this.listContainerTarget.classList.remove(
      "right-aligned",
      "bottom-aligned"
    );
    this.clearFilter();
  }

  clickOutside() {
    this.hideMultiselection();
  }

  optionsChanged() {
    const selectedOptions = this.checkboxTargets.filter(
      (checkbox) => checkbox.checked
    );

    if (selectedOptions.length > 1) {
      this.multiselectionButtonTarget.querySelector(
        "span"
      ).textContent = `${selectedOptions.length} options selected`;
    } else if (selectedOptions.length == 1) {
      this.multiselectionButtonTarget.querySelector("span").textContent =
        selectedOptions[0].parentElement.querySelector("span").textContent;
    } else {
      this.multiselectionButtonTarget.querySelector(
        "span"
      ).textContent = `Select ${this.placeholderValue}`;
    }
    if (this.hasUnselectAllButtonTarget) {
      this.unselectAllButtonTarget.toggleAttribute(
        "disabled",
        selectedOptions.length == 0
      );
    }
  }

  filterOptions(event) {
    if (event.keyCode == 27) {
      this.searchInputTarget.value = "";
    }
    let criteria = this.searchInputTarget.value.toLowerCase();
    let elements = [];

    if (criteria.length > 0) {
      // first hide all elements
      this.optionTargets.forEach((option) => {
        option.classList.add("hidden");
      });

      this.optionTargets.forEach((option) => {
        let text_content = option
          .getAttribute("data-value")
          .replace(/(\r\n|\n|\r)/gm, "")
          .toLowerCase();

        try {
          if (text_content.match(new RegExp(criteria, "ig")).length > 0) {
            elements.push(option);
            option.classList.remove("hidden");
          }
        } catch (error) {
          console.log("No matches");
        }
      });

      if (parseInt(elements.length) == 0) {
        this.noResultsTarget.classList.remove("hidden");
        this.multiselectionListTarget.setAttribute("aria-hidden", true);
        this.multiselectionListTarget.classList.add("hidden");
      } else {
        this.noResultsTarget.classList.add("hidden");
        this.multiselectionListTarget.classList.remove("hidden");
        this.multiselectionListTarget.setAttribute("aria-hidden", false);
      }
    } else {
      // if less to 1 character just show up everything again
      this.optionTargets.forEach((option) => {
        option.classList.remove("hidden");
      });
      this.noResultsTarget.classList.add("hidden");
      this.multiselectionListTarget.classList.remove("hidden");
      this.multiselectionListTarget.setAttribute("aria-hidden", true);
    }
    this.clearSearchButtonTarget.classList.toggle(
      "hidden",
      criteria.length == 0
    );
  }

  clearSelection() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false;
    });
    this.optionsChanged();
    this.clearFilter();
    this.checkboxTargets[0].focus();
  }

  clearFilter() {
    if (this.hasSearchInputTarget) {
      this.optionTargets.forEach((option) => {
        option.classList.remove("hidden");
      });
      this.noResultsTarget.classList.add("hidden");
      this.multiselectionListTarget.classList.remove("hidden");
      this.multiselectionListTarget.setAttribute("aria-hidden", true);
      this.searchInputTarget.value = "";
      this.clearSearchButtonTarget.classList.add("hidden");
    }
  }

  checkViewport() {
    const dropdown = this.listContainerTarget.getBoundingClientRect();
    const viewportWidth = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
    const viewportHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );

    if (dropdown.right > viewportWidth) {
      this.listContainerTarget.classList.add("right-aligned");
    } else {
      this.listContainerTarget.classList.remove("right-aligned");
    }
    if (dropdown.bottom > viewportHeight) {
      this.listContainerTarget.classList.add("bottom-aligned");
    } else {
      this.listContainerTarget.classList.remove("bottom-aligned");
    }
  }
}
