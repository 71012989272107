import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["template", "form"];

  addNewConfig() {
    // This adds the template for a new column config and replace the id and value
    // of the html element to remain consistent in the view when submitting it
    let uuid = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
    let t = this.templateTarget;
    let clone = document.importNode(t.content, true);
    let key_label = clone.querySelector("label[for=key_input_label]");
    let key_input = clone.querySelector("#key_input");
    key_input.name =
      "sis_integration_app_type_config[filter_columns][" + uuid + "][key]";
    key_input.id =
      "sis_integration_app_type_config_filter_columns_" + uuid + "_key";
    key_label.htmlFor =
      "sis_integration_app_type_config_filter_columns_" + uuid + "_key";
    let value_label = clone.querySelector("label[for=value_input_label]");
    let value_input = clone.querySelector("#value_input");
    value_input.name =
      "sis_integration_app_type_config[filter_columns][" + uuid + "][value]";
    value_input.id =
      "sis_integration_app_type_config_filter_columns_" + uuid + "_value";
    value_label.htmlFor =
      "sis_integration_app_type_config_filter_columns_" + uuid + "_value";
    this.formTarget.appendChild(clone);
  }
}
