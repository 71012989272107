import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["currentRow", "fullMessageRow"];

  expand() {
    this.currentRowTarget.classList.toggle("expanded");
    this.fullMessageRowTarget.classList.toggle("expanded");
  }
}
