import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  static targets = ["deleteButton"];

  connect() {
    StimulusReflex.register(this);
  }

  deleteRequirementRow() {
    let confirmDialog = confirm(
      "Are you sure you want to delete this program requirement row?"
    );
    if (confirmDialog == true) {
      document
        .querySelector('[data-loading-indicator-target="loadingIndicator"]')
        .classList.remove("hidden");
      this.stimulate(
        "Programs::Form::RequirementGroupInput#remove_requirement",
        this.deleteButtonTarget
      );
    }
  }

  deleteCourse() {
    document
      .querySelector("#program-requirement-loading-indicator")
      .classList.remove("hidden");
    this.stimulate(
      "Programs::Form::RequirementGroupInput#delete",
      this.deleteButtonTarget
    );
  }

  deleteRequirement() {
    document
      .querySelector("#program-requirement-loading-indicator")
      .classList.remove("hidden");
    this.stimulate(
      "Programs::Form::RequirementGroupInput#delete",
      this.deleteButtonTarget
    );
  }

  deleteSubject() {
    document
      .querySelector("#program-requirement-loading-indicator")
      .classList.remove("hidden");
    this.stimulate(
      "Programs::Form::RequirementGroupInput#delete",
      this.deleteButtonTarget
    );
  }
}
