import { Controller } from "stimulus";

export default class extends Controller {
  updateRegistrationReason() {
    var filfillment_type = document.getElementById(
      "batch_application_common_registration_reasons"
    ).value;
    var registration_reason = document.getElementById(
      "batch_application_answers_registration_reason"
    );

    switch (filfillment_type) {
      case "Other":
        registration_reason.value = "";
        registration_reason.hidden = false;
        break;
      case "":
        registration_reason.value = "";
        registration_reason.hidden = true;
        break;
      default:
        registration_reason.hidden = true;
        registration_reason.value = filfillment_type;
    }
  }
}
