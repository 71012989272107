import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["passwordInput", "showPassword", "hidePassword"];

  password() {
    if (this.passwordInputTarget.type === "password") {
      this.hidePasswordTarget.classList.remove("hidden");
      this.hidePasswordTarget.classList.add("flex");
      this.showPasswordTarget.classList.add("hidden");
      this.showPasswordTarget.classList.remove("flex");
      this.passwordInputTarget.type = "text";
    } else {
      this.showPasswordTarget.classList.remove("hidden");
      this.showPasswordTarget.classList.add("flex");
      this.hidePasswordTarget.classList.add("hidden");
      this.hidePasswordTarget.classList.remove("flex");
      this.passwordInputTarget.type = "password";
    }
  }
}
