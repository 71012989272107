import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["objectToChange"];

  changeValue(e) {
    if (e.target.value) {
      this.objectToChangeTargets.forEach((element) =>
        element.setAttribute("value", e.target.value)
      );
    } else {
      this.objectToChangeTargets.forEach(
        (element) => (element.checked = false)
      );
    }
  }
}
