/*eslint no-constant-condition: ["error", { "checkLoops": false }]*/

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = { domId: String };

  connect() {
    super.connect();
    // This is an antipattern and should be removed
    this.loadResults();
  }

  loadResults() {
    if (this.isActionCableConnectionOpen()) {
      try {
        this.stimulate(
          "GroupedHomeCollegeResults#trigger_search",
          this.domIdValue
        );
      } catch (e) {
        setTimeout(
          function () {
            this.loadResults();
          }.bind(this),
          500
        );
      }
    } else {
      setTimeout(
        function () {
          this.loadResults();
        }.bind(this),
        500
      );
    }
  }
}
