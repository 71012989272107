import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "form",
    "transferabilityEnableGeReq",
    "universityIdEq",
    "transfersToIdEq",
    "submitSearchButton",
    "requiredFieldsMessage",
  ];

  connect() {
    this.conditionallyEnableTransferabilityEnableGeReqTarget(
      this.transfersToIdEqTarget.value
    );

    if (this.hasSubmitSearchButtonTarget) {
      this.toggleSearchButton();
    }
  }

  submitForm() {
    this.formTarget.submit();
  }

  toggleSearchButton() {
    if (
      (this.hasUniversityIdEqTarget && this.universityIdEqTarget.value) ||
      this.transfersToIdEqTarget.value
    ) {
      this.submitSearchButtonTarget.removeAttribute("disabled");
      this.requiredFieldsMessageTarget.style["display"] = "none";
    } else {
      this.submitSearchButtonTarget.disabled = "disabled";
      this.requiredFieldsMessageTarget.style["display"] = null;
    }
  }

  homeUniversityFilterChanged() {
    if (this.hasTransferabilityEnableGeReqTarget) {
      this.conditionallyEnableTransferabilityEnableGeReqTarget(
        this.transfersToIdEqTarget.value
      );
    }
  }

  conditionallyEnableTransferabilityEnableGeReqTarget(value) {
    if (!this.hasTransferabilityEnableGeReqTarget) return;

    if (value === "")
      this.transferabilityEnableGeReqTarget.disabled = "disabled";
    else {
      this.transferabilityEnableGeReqTarget.removeAttribute("disabled");
    }
  }
}
