import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "consentCheckbox1",
    "consentCheckbox2",
    "consentCheckbox3",
    "consentCheckbox4",
    "consentCheckbox5",
    "continueButton",
  ];

  onAcceptedTerms() {
    const checkboxes = document.getElementsByClassName(
      "consent_given_checkbox"
    );
    let accepted_all = false;

    if (checkboxes.length > 0) {
      const filtered_selected = Array.from(checkboxes).filter(
        (checkbox) => checkbox.checked
      );
      accepted_all = filtered_selected.length == checkboxes.length;
    } else {
      // just to keep backward compatibility
      accepted_all =
        this.consentCheckbox1Target.checked &&
        (!this.hasConsentCheckbox2Target ||
          this.consentCheckbox2Target.checked) &&
        (!this.hasConsentCheckbox3Target ||
          this.consentCheckbox3Target.checked) &&
        (!this.hasConsentCheckbox4Target ||
          this.consentCheckbox4Target.checked) &&
        (!this.hasConsentCheckbox5Target ||
          this.consentCheckbox5Target.checked);
    }

    if (accepted_all) {
      this.continueButtonTarget.removeAttribute("disabled");
      const event = new CustomEvent("accepted_all");
      window.dispatchEvent(event);
    } else {
      this.continueButtonTarget.setAttribute("disabled", "disabled");
      const event = new CustomEvent("not_accepted_all");
      window.dispatchEvent(event);
    }
  }
}
