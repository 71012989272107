import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "enableKeyword",
    "keywordSearchSection",
    "regularKeywordRadio",
    "regularKeywordHidden",
    "openSearchKeywordRadio",
    "openSearchKeywordHidden",
  ];

  connect() {}

  toggleKeywordSearches() {
    let keywordsEnabled = false;
    // Either of the keyword search checkboxes are checked
    this.enableKeywordTargets.forEach((element) => {
      if (element.checked) {
        keywordsEnabled = true;
      }
    });

    if (keywordsEnabled) {
      this.keywordSearchSectionTarget.classList.remove("hidden");
      this.regularKeywordRadioTarget.setAttribute("required", "required");
      this.openSearchKeywordRadioTarget.setAttribute("required", "required");
    } else {
      this.keywordSearchSectionTarget.classList.add("hidden");
      // Unselect all keyword search checkboxes/hidden inputs
      this.regularKeywordRadioTarget.checked = false;
      this.regularKeywordRadioTarget.removeAttribute("required");
      this.regularKeywordHiddenTarget.value = false;
      this.openSearchKeywordRadioTarget.checked = false;
      this.openSearchKeywordRadioTarget.removeAttribute("required");
      this.openSearchKeywordHiddenTarget.value = false;
    }
  }

  selectRegularkeywordSearch() {
    this.regularKeywordHiddenTarget.value = true;
    this.openSearchKeywordHiddenTarget.value = false;
  }

  selectOpenSearchkeywordSearch() {
    this.openSearchKeywordHiddenTarget.value = true;
    this.regularKeywordHiddenTarget.value = false;
  }
}
