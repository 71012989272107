import React, { useState } from "react";

import { ThemeProvider, Box, Button, AppBar, Toolbar } from "@mui/material";
import { darkTheme, headerMobileHeight, headerDesktopHeight } from "./theme";
import { MobileNav } from "./mobileNav";
import { MobileNavNew } from "./mobileNavNew";
import { DesktopNav } from "./desktopNav";
import { DesktopNavNew } from "./desktopNavNew";

const Header = (props) => (
  <ThemeProvider theme={darkTheme}>
    <Box
      component="header"
      bgcolor="background.primary"
      color="text.primary"
      position="sticky"
      top={0}
      width="100%"
      zIndex={2}
    >
      <Button
        color="primary"
        variant="contained"
        sx={{
          position: "absolute",
          top: "-100px",
          width: "1px",
          height: "1px",
          overflow: "hidden",
          "&:focus-visible": {
            top: "10px",
            left: "30px",
            width: "auto",
            height: "auto",
            overflow: "visible",
            zIndex: 1,
          },
        }}
        href="#main"
      >
        Skip to Main Content
      </Button>
      <AppBar
        component="div"
        position="static"
        color="transparent"
        elevation={0}
      >
        <Box
          sx={{
            display: "flex",
            px: {
              xs: 2,
              md: 6,
              xl: 16,
            },
            py: 0,
            height: {
              xs: headerMobileHeight,
              md: headerDesktopHeight,
            },
          }}
        >
          <Toolbar
            disableGutters
            variant="dense"
            sx={{
              width: "100%",
              position: {
                md: props.user.logged ? "static" : "relative",
                xl: "relative",
              },
            }}
          >
            {props.newHeader ? (
              <>
                <MobileNavNew
                  logoWhiteUrl={props.logoWhiteUrl}
                  user={props.user}
                  domain={props.domain}
                  externalDomain={props.externalDomain}
                />
                <DesktopNavNew
                  logoWhiteUrl={props.logoWhiteUrl}
                  user={props.user}
                  domain={props.domain}
                  externalDomain={props.externalDomain}
                />
              </>
            ) : (
              <>
                <MobileNav
                  logoWhiteUrl={props.logoWhiteUrl}
                  user={props.user}
                  domain={props.domain}
                  externalDomain={props.externalDomain}
                />
                <DesktopNav
                  logoWhiteUrl={props.logoWhiteUrl}
                  user={props.user}
                  domain={props.domain}
                  externalDomain={props.externalDomain}
                />
              </>
            )}
          </Toolbar>
        </Box>
      </AppBar>
    </Box>
  </ThemeProvider>
);

export default Header;
