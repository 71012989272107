import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  create() {
    this.stimulate("SavedCoursesComponent#create");
  }

  destroy() {
    this.stimulate("SavedCoursesComponent#destroy");
  }
}
