import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Snackbar,
  Typography,
  ThemeProvider,
} from "@mui/material";

import { lightTheme } from "./theme";

export function ToastMessage(props) {
  const visibility = props.visibility;
  const setVisibility = props.setVisibility;
  const [open, setOpen] = React.useState(props.open);
  const onClose = () => {
    setOpen(false);
    props.setVisibility && setVisibility(false);
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open || visibility}
        onClose={onClose}
        ClickAwayListenerProps={{
          onClickAway: () => null,
        }}
      >
        <Alert
          onClose={onClose}
          severity={props.severity}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            "& .MuiAlert-message": {
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              gap: {
                xs: 4,
                md: 0,
              },
              justifyContent: "space-between",
              width: "100%",
            },
          }}
        >
          <Box>
            <Typography variant="titleMedium" component="p">
              {props.title}
            </Typography>
            <Typography variant="bodyMedium" component="p">
              {props.message}
            </Typography>
          </Box>
          {props.ctaLabel && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button variant="contained" onClick={onClose}>
                {props.ctaLabel}
              </Button>
            </Box>
          )}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}
