import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "container",
    "formContainer",
    "query",
    "universityId",
    "courseSearchResultsContainer",
    "courseDetails",
    "selectedCourseId",
    "searchButton",
    "courseRowDetails",
    "searchedCourse",
    "listCourseIds",
    "emptyCoursesMessage",
    "emptyCoursesMessageText",
    "courseRowDetailsTable",
  ];

  connect() {
    const targets = this.selectedCourseIdTargets;
    targets.forEach((el) => {
      this.appendCourseDetailsToTable(el.value);
    });
  }

  search() {
    if (
      this.universityIdTarget.value > 0 &&
      this.queryTarget.value.length > 0
    ) {
      this.searchButtonTarget.disabled = "disabled";
      fetch(
        "/exchange/admin/equivalencies/course_search?q[code_or_name_cont]=" +
          this.queryTarget.value +
          "&q[university_id_eq]=" +
          (this.universityIdTarget && this.universityIdTarget.value) +
          "&q[hidden_not_eq]=true",
        { headers: { "X-Requested-With": "XMLHttpRequest" } }
      )
        .then((response) => {
          return response.text();
        })
        .then((html) => {
          this.searchButtonTarget.removeAttribute("disabled");
          this.courseSearchResultsContainerTarget.innerHTML = html;
        })
        .then(() => {
          this.filterSearchResults();
        });
    } else {
      if (this.universityIdTarget.value < 1) {
        if (this.queryTarget.value.length < 1) {
          this.emptyCoursesMessageTextTarget.innerHTML =
            "Please select a University and  enter part of a course code or name.";
        } else {
          this.emptyCoursesMessageTextTarget.innerHTML =
            "Please select a University.";
        }
      } else {
        this.emptyCoursesMessageTextTarget.innerHTML =
          "Please enter part of a course code or name.";
      }
    }
  }

  // Event handler for custom 'on-course-selected' event.
  onCourseSelected(event) {
    this.selectCourse(event.detail.courseId);
  }

  discardEnterOnSearchInput(event) {
    if (event.keyCode == 13) {
      event.preventDefault();
      this.search();
      return false;
    }
  }

  selectCourse(courseId) {
    const targets = this.selectedCourseIdTargets;
    let newNumber = 0;
    if (targets && targets.length > 0) {
      targets.forEach((el) => {
        if (newNumber < parseInt(el.dataset.formIndex)) {
          newNumber = parseInt(el.dataset.formIndex);
        }
      });
      newNumber = newNumber + 1;
    }
    let transferCourseType =
      this.listCourseIdsTarget.dataset.transferCourseType;
    let new_input = `<input data-new-transfer-course-finder-target="selectedCourseId" data-new-transfer-target="${transferCourseType}CourseId" data-form-index=${newNumber} type="hidden" value="${courseId}" name="transfer[${transferCourseType}_transfer_courses_attributes][${newNumber}][course_id]" id="transfer_${transferCourseType}_transfer_courses_attributes_${newNumber}_course_id">`;
    this.listCourseIdsTarget.insertAdjacentHTML("beforeend", new_input);
    this.listCourseIdsTarget.dispatchEvent(new Event("change"));

    this.appendCourseDetailsToTable(courseId);
    this.filterSearchResults();
  }

  filterSearchResults() {
    const event = document.createEvent("CustomEvent");
    const targets = this.selectedCourseIdTargets;
    let selectedCourseIds = targets.map((el) => {
      return el.value;
    });

    if (selectedCourseIds.length) {
      this.emptyCoursesMessageTarget.classList.add("hidden");
    } else {
      this.emptyCoursesMessageTarget.classList.remove("hidden");
      this.emptyCoursesMessageTextTarget.innerHTML =
        "Please find courses and select them to be added as equivalent course/s.";
    }

    event.initCustomEvent("on-filter-search", true, true, {
      courseIds: selectedCourseIds,
    });
    this.searchedCourseTargets.forEach((el) => {
      el.dispatchEvent(event);
    });
  }

  onCourseRemoved() {
    const targets = this.selectedCourseIdTargets;
    targets.forEach((el) => {
      if (el.value == event.detail.courseId) {
        el.remove();
      }
    });
    this.listCourseIdsTarget.dispatchEvent(new Event("change"));
    event.detail.courseRow.remove();
    if (this.selectedCourseIdTargets.length === 0) {
      this.courseRowDetailsTableTarget.classList.add("hidden");
    }
    this.filterSearchResults();
  }

  emptycourses() {
    this.listCourseIdsTarget.innerHTML = "";
    this.listCourseIdsTarget.dispatchEvent(new Event("change"));
    this.courseSearchResultsContainerTarget.innerHTML = "";
    this.courseRowDetailsTarget.innerHTML = "";
    this.courseRowDetailsTableTarget.classList.add("hidden");
    this.emptyCoursesMessageTarget.classList.remove("hidden");
    this.emptyCoursesMessageTextTarget.innerHTML =
      "Please find courses and select them to be added as equivalent course/s.";
  }

  appendCourseDetailsToTable(courseId) {
    fetch(
      "/exchange/admin/equivalencies/courses/" + courseId + "?type=course_row",
      { headers: { "X-Requested-With": "XMLHttpRequest" } }
    )
      .then((response) => {
        return response.text();
      })
      .then((html) => {
        this.courseRowDetailsTarget.insertAdjacentHTML("beforeend", html);
        this.courseRowDetailsTableTarget.classList.remove("hidden");
      });
  }

  replaceCourse() {
    this.selectedCourseIdTarget.removeAttribute("value");
    this.selectedCourseIdTarget.dispatchEvent(new Event("change"));

    this.formContainerTarget.classList.remove("hidden");
    this.courseDetailsTarget.innerHTML = "";
  }
}
