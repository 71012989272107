/* globals $ */
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["courseSelect"];

  connect() {
    this.create();
  }

  // Selectize needs to be destroyed before the page morphs so it can be re-created after the page is morphed.
  // Otherwise the dom elements it creates are left hanging around and cause problems
  destroy() {
    // After destroying the selectize element during a morph-dom operation, for whatever reason
    // the select is being assigned as val the last option.

    // By manually getting the value _before_ destroying, and then re-assigning it, this issue is fixed.
    // Strangely, in Chrome on OS X this issue was not present.

    // We should get rid of selectize ASAP, mostly because jQuery plugins do not go along very well with
    // StimulusReflex
    let selectedValue = $(this.courseSelectTarget).val();
    $(this.courseSelectTarget)[0].selectize.destroy();
    $(this.courseSelectTarget).val(selectedValue);
  }

  create() {
    if (this.hasCourseSelectTarget) {
      const universityId = this.courseSelectTarget.dataset.universityId;
      $(this.courseSelectTarget).selectize({
        plugins: ["selectize-plugin-a11y"],
        valueField: "id",
        labelField: "text",
        openOnFocus: false,
        score: function () {
          return function () {
            return 1;
          };
        },
        onType: function () {
          this.clearOptions();
          this.addOption([{ id: "", text: "Loading..." }]);
          this.refreshOptions(true);
        },
        load: function (query) {
          $.ajax({
            url: "/api/search.json",
            dataType: "json",
            type: "GET",
            data: {
              query: query,
              university_id: universityId,
            },
            success: (response) => {
              let results;

              if (!response.results.length) {
                results = [{ id: "", text: "No Results" }];
              } else {
                results = response.results.map(function (course) {
                  return [
                    { id: course.id.replace(/[^\d]/g, ""), text: course.text },
                  ];
                });
              }

              // This makes sure that 'Loading...' is removed
              this.clearOptions();
              this.addOption(results);
              this.refreshOptions(true);
            },
          });
        },
        render: {
          option: function (item) {
            // Every option must have a unique id
            return `<div class="option" role="option" id="${item.text.replace(
              / /g,
              ""
            )}">${item.text}</div>`;
          },
        },
      });
    }
  }
}
