import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "hiddenLeaf",
    "toggleButton",
    "requirementContainer",
    "requirementItem",
    "expandButtonContainer",
  ];

  connect() {
    this.hidden = true;
    if (!this.hasHiddenLeafTarget && this.hasToggleButtonTarget) {
      this.toggleButtonTarget.classList.add("hidden");
    }

    if (this.requirementItemTargets.length > 5) {
      this.expandButtonContainerTarget.classList.remove("hidden");
      this.requirementContainerTarget.classList.add("requirement-collapsable");
    }
  }

  toggleLeaves() {
    this.hidden = !this.hidden;

    if (this.hidden) {
      this.hiddenLeafTargets.forEach((visibleLeaf) =>
        visibleLeaf.classList.add("hidden")
      );
      this.toggleButtonTarget.innerHTML = "View all";
    } else {
      this.hiddenLeafTargets.forEach((hiddenLeaf) =>
        hiddenLeaf.classList.remove("hidden")
      );
      this.toggleButtonTarget.innerHTML = "Hide all";
    }
  }

  toggleExpand() {
    this.requirementContainerTarget.classList.toggle("expanded");
  }
}
