import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "mobileNav",
    "menuButton",
    "menuOpenIcon",
    "menuClosedIcon",
    "header",
  ];

  connect() {
    if (window.innerWidth < 1023) {
      this.mobileNavTarget.hidden = true;
    }
  }

  toggleNavbar() {
    if (this.mobileNavTarget.classList.contains("hidden")) {
      this.openMenu();
    } else {
      this.closeMenu();
    }
  }

  openMenu() {
    this.menuOpenIconTarget.classList.remove("hidden");
    this.menuOpenIconTarget.classList.add("block");
    this.menuButtonTarget.setAttribute("aria-expanded", "true");

    this.menuClosedIconTarget.classList.add("hidden");
    this.menuClosedIconTarget.classList.remove("block");

    this.mobileNavTarget.classList.remove("hidden");
    this.mobileNavTarget.hidden = false;
    setTimeout(() => this.headerTarget.classList.add("mmenu-open"), 100);
  }

  closeMenu() {
    this.menuOpenIconTarget.classList.remove("block");
    this.menuOpenIconTarget.classList.add("hidden");
    this.menuButtonTarget.setAttribute("aria-expanded", "false");

    this.menuClosedIconTarget.classList.remove("hidden");
    this.menuClosedIconTarget.classList.add("block");

    this.headerTarget.classList.remove("mmenu-open");
    setTimeout(() => {
      this.mobileNavTarget.hidden = true;
      this.mobileNavTarget.classList.add("hidden");
    }, 501);
  }

  resizeClose() {
    if (window.innerWidth > 1023) {
      this.closeMenu();
    }
  }
}
