import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "linkedOfferingId",
    "topSubmitButton",
    "bottomSubmitButton",
    "errorMessage",
    "linkedSectionWrapper",
  ];

  submitRegistration(event) {
    if (
      this.hasLinkedOfferingIdTarget &&
      this.linkedOfferingIdTarget.value === ""
    ) {
      this.topSubmitButtonTarget.disabled = true;
      this.bottomSubmitButtonTarget.disabled = true;
      this.errorMessageTarget.classList.remove("hidden");
      event.preventDefault();
    }
  }

  selectLinkedSectionId(e) {
    let targetId = "linked-section-wrapper-" + e.target.value;
    this.linkedSectionWrapperTargets.forEach((el) => {
      if (el.id === targetId) {
        el.classList.add("border-c_content_bg_highlight");
        el.getElementsByClassName("selector-label")[0].innerText = "Selected";
      } else {
        el.classList.remove("border-c_content_bg_highlight");
        const otherLabel = el.getElementsByClassName("selector-label");
        if (otherLabel.length != 0) {
          otherLabel[0].innerText = "Select";
        }
      }
    });
    this.topSubmitButtonTarget.removeAttribute("disabled");
    this.bottomSubmitButtonTarget.removeAttribute("disabled");
    this.errorMessageTarget.classList.add("hidden");
    this.linkedOfferingIdTarget.value = e.target.value;
  }
}
