/* global require */

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "core-js/stable";
import "regenerator-runtime/runtime";

// jQuery, legacy plugins, and legacy jQuery event listener usage
import "../jquery/jquery";
import "../jquery/selectize";
import "selectize-plugin-a11y";

import "../jquery/event_listeners";
import "../jquery/alerts";
import "../jquery/event_delegator";

//

import Rails from "@rails/ujs";
Rails.start();

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import StimulusReflex from "stimulus_reflex";
import consumer from "../channels/consumer";

// This import is not used but sets up jQuery event listeners.
// Eventually they should be migrated to Stimulus.
import channels from "../channels"; //eslint-disable-line

import controller from "../controllers/application_controller";

// Safari WebSockets hotfix! Use this until Apple releases a fix.
//
// More details https://docs.stimulusreflex.com/appendices/troubleshooting#safari-nsurlsession-websocket-bug
// More details https://github.com/rails/rails/issues/43835
const originalWebSocketClose = WebSocket.prototype.close;
WebSocket.prototype.close = function () {
  if (this.readyState != WebSocket.CONNECTING) {
    originalWebSocketClose.apply(this, arguments);
  }
};

const application = Application.start();
const context = require.context("../controllers", true, /\.js$/);
application.load(definitionsFromContext(context));
StimulusReflex.initialize(application, { consumer, controller, debug: false });

import { Dropdown, Tabs } from "tailwindcss-stimulus-components";
application.register("dropdown", Dropdown);
application.register("tabs", Tabs);
