import { Controller } from "stimulus";
import CableReady from "cable_ready";
import consumer from "../channels/consumer";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  static values = { id: Number };

  connect() {
    const self = this;
    const paymentId = this.idValue;
    StimulusReflex.register(this);

    this.channel = consumer.subscriptions.create(
      {
        channel: "TransactPaymentChannel",
        id: paymentId,
      },
      {
        connected() {
          self.stimulate("Payments#check_transact", paymentId);
        },
        received(data) {
          if (data.cableReady) {
            CableReady.perform(data.operations);
          }
        },
      }
    );
  }

  disconnect() {
    this.channel.unsubscribe();
  }
}
