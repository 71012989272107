import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["newSiteCodeName"];

  connect() {}

  selectUniversity(event) {
    var element = event.target;
    if (element.value != "") {
      this.newSiteCodeNameTarget.setAttribute("required", "required");
      this.newSiteCodeNameTarget.removeAttribute("disabled");
      this.newSiteCodeNameTarget.focus();
    } else {
      this.newSiteCodeNameTarget.removeAttribute("required");
      this.newSiteCodeNameTarget.setAttribute("disabled", "disabled");
    }
  }

  removeCode(event) {
    let element = event.target;
    let container = document.getElementById(
      "home-college-custom-site-code-" + element.dataset.associationIndex
    );
    container.remove();
  }
}
