// @see https://www.w3.org/TR/wai-aria-practices/#dialog_modal
import { Modal } from "tailwindcss-stimulus-components";

const quottlyModalKeyCodes = {
  ESC: 27,
  TAB: 9,
};

export default class extends Modal {
  connect() {
    super.connect();
    this.modalOpen = false;
  }

  open(event) {
    this.focusableElements = [];

    try {
      this.beforeOpenActions(event);
    } catch (error) {
      console.log(
        "Tried to run beforeOpenActions, but no before action have been defined"
      );
    }

    super.open(event);
    this.modalOpen = true;

    try {
      this.afterOpenActions();
    } catch (error) {
      console.log(
        "Tried to run afterOpenActions, but no after action have been defined"
      );
    }
  }

  close(event) {
    try {
      this.beforeCloseActions();
    } catch (error) {
      console.log(
        "Tried to run beforeCloseActions, but no before action have been defined"
      );
    }

    super.close(event);
    this.modalOpen = false;

    try {
      this.afterCloseActions();
    } catch (error) {
      console.log(
        "Tried to run afterCloseActions, but no after close action have been defined"
      );
    }
  }

  controlWorkflow(event) {
    if (this.modalOpen == false) {
      return false;
    }

    let active_element = document.activeElement;

    switch (event.keyCode) {
      case quottlyModalKeyCodes.ESC:
        this.close(event);
        break;
      case quottlyModalKeyCodes.TAB:
        event.preventDefault();
        if (!this.focusableElements.includes(active_element)) {
          this.focusableElements[0].focus();
        }
        break;
    }
  }
}
