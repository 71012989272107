import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["notOperatorInput", "operatorInput"];

  checkNotOperator() {
    var op =
      this.operatorInputTarget.options[this.operatorInputTarget.selectedIndex];
    if (op.getAttribute("not_operation") == "true") {
      this.notOperatorInputTarget.value = "true";
    } else {
      this.notOperatorInputTarget.value = "false";
    }
  }
}
