import { Controller } from "stimulus";
import CableReady from "cable_ready";
import consumer from "../channels/consumer";

export default class extends Controller {
  static values = { id: Number };

  connect() {
    this.channel = consumer.subscriptions.create(
      {
        channel: "MyTranscriptsChannel",
        id: this.idValue,
      },
      {
        received(data) {
          if (data.cableReady) CableReady.perform(data.operations);
        },
      }
    );
  }

  disconnect() {
    this.channel.unsubscribe();
  }
}
