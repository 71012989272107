import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["denyReasonInput"];

  selectApprove() {
    this.denyReasonInputTarget.focus();
  }

  selectDeny() {
    this.denyReasonInputTarget.focus();
  }

  toggleApprove(event) {
    if (event.target.value == "approve") {
      this.selectApprove();
    } else if (event.target.value == "deny") {
      this.selectDeny();
    }
  }
}
