import { Controller } from "stimulus";
import React from "react";
import ReactDOM from "react-dom/client";

import { darkTheme, lightTheme } from "../../packs/theme";
import { Tooltip, IconButton, ThemeProvider } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default class extends Controller {
  static values = {
    props: Object,
  };

  connect() {
    const root = ReactDOM.createRoot(this.element);
    const theme = this.propsValue.theme || lightTheme;
    root.render(
      <ThemeProvider theme={theme}>
        <Tooltip arrow title={this.propsValue.title} enterTouchDelay={0}>
          <IconButton>
            <InfoOutlinedIcon
              sx={{
                height: "16px",
                width: "16px",
                color: "blue.main",
              }}
            />
          </IconButton>
        </Tooltip>
      </ThemeProvider>
    );
  }
}
