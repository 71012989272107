import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "universitySelect",
    "highSchoolSelect",
    "universityContainer",
    "highSchoolContainer",
  ];

  changeType(event) {
    if (event.target.value == "University") {
      this.highSchoolSelectTarget.disabled = true;
      this.universitySelectTarget.disabled = false;
      this.universityContainerTarget.classList.remove("hidden");
      this.highSchoolContainerTarget.classList.add("hidden");
    } else {
      this.universitySelectTarget.disabled = true;
      this.highSchoolSelectTarget.disabled = false;
      this.universityContainerTarget.classList.add("hidden");
      this.highSchoolContainerTarget.classList.remove("hidden");
    }
  }
}
