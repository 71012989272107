import { Controller } from "stimulus";
import React from "react";
import ReactDOM from "react-dom/client";

import { FormSelect } from "../../packs/formSelect";

export default class extends Controller {
  static values = {
    props: Object,
    options: Array,
  };

  connect() {
    const root = ReactDOM.createRoot(this.element);

    root.render(
      <FormSelect properties={this.propsValue} options={this.optionsValue} />
    );
  }
}
