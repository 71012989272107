import { Controller } from "stimulus";

export default class extends Controller {
  toggleDefaultPrimaryLabelOverride() {
    var override_checkbox = document.getElementById(
      "program_requirement_override_default_subheading"
    );

    var override_text_input = document.getElementById(
      "program_requirement_subheading_override_text"
    );

    var override_text_section = document.getElementById(
      "subheading-override-text-section"
    );

    if (override_checkbox.checked) {
      override_text_input.disabled = false;
      override_text_section.classList.remove("hidden");
    } else {
      override_text_input.value = "";
      override_text_input.disabled = true;
      override_text_section.classList.add("hidden");
    }
  }
}
