import QuottlyModalController from "./quottly_modal_controller.js";

export default class extends QuottlyModalController {
  static targets = [
    "modalReviewAction",
    "modalTitle",
    "modaldescription",
    "modalAuditNotes",
    "modalApproveInput",
    "approveButton",
    "denyButton",
    "modalCloseButton",
    "modalConfirmButton",
    "modalForm",
  ];

  beforeOpenActions(event) {
    let element = event.target;

    // Accessibility: keep track on what triggered the action for future actions
    this.triggerElement = element;

    this.modalReviewActionTarget.value = element.dataset.reviewAction;
    this.modalTitleTarget.innerHTML = element.dataset.warningTitle;
    this.modalTitleTarget.setAttribute(
      "aria-label",
      element.dataset.warningTitle
    );
    this.modaldescriptionTarget.innerHTML = element.dataset.warningDescription;

    // Accessibility: trigger buttons should be disabled at this point to prevent double actions
    // for tab navigation
    this.approveButtonTarget.setAttribute("disabled", true);
    this.denyButtonTarget.setAttribute("disabled", true);

    let confirm_btn = this.modalConfirmButtonTarget;
    let close_btn = this.modalCloseButtonTarget;
    this.modalCloseButtonTarget.setAttribute(
      "aria-label",
      close_btn.dataset.youAreActionLabel + ": " + element.dataset.warningTitle
    );
    this.modalConfirmButtonTarget.setAttribute(
      "aria-label",
      confirm_btn.dataset.youAreActionLabel +
        ": " +
        element.dataset.warningTitle
    );

    if (element.dataset.reviewAction == "deny") {
      if (this.hasModalAuditNotesTarget) {
        this.modalAuditNotesTarget.required = true;
        this.modalAuditNotesTarget.classList.remove("hidden");
      }
      if (this.hasModalApproveInputTarget) {
        this.modalApproveInputTarget.required = false;
        this.modalApproveInputTarget.disabled = true;
        this.modalApproveInputTarget.classList.add("hidden");
      }
      this.focusableElements.push(
        this.modalAuditNotesTarget,
        this.modalCloseButtonTarget,
        this.modalConfirmButtonTarget
      );
    } else {
      if (this.hasModalAuditNotesTarget) {
        this.modalAuditNotesTarget.required = false;
        this.modalAuditNotesTarget.value = "";
        this.modalAuditNotesTarget.classList.add("hidden");
      }
      if (this.hasModalApproveInputTarget) {
        this.modalApproveInputTarget.classList.remove("hidden");
        this.modalApproveInputTarget.required = true;
        this.modalApproveInputTarget.disabled = false;
      }
      this.focusableElements.push(
        this.modalApproveInputTarget,
        this.modalCloseButtonTarget,
        this.modalConfirmButtonTarget
      );
    }
  }

  afterOpenActions() {
    this.focusableElements[0].focus();
  }

  beforeCloseActions() {
    this.approveButtonTarget.removeAttribute("disabled");
    this.denyButtonTarget.removeAttribute("disabled");

    // Accessibility: return the focus to the trigger element
    this.triggerElement.focus();
  }
}
