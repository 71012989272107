export const mainLinks = (domain) => [
  {
    href: `${domain}/`,
    text: "The Yard",
    show: "Auth",
    order: 1,
  },
  {
    href: `${domain}/my-circle`,
    text: "My Circle",
    show: "Auth",
    order: 2,
  },
  {
    href: `${domain}/tap-in`,
    text: "Tap In",
    show: "Auth",
    order: 3,
  },
  {
    href: `${domain}/my-path`,
    text: "My Path",
    show: "Auth",
    order: 4,
  },
  {
    text: "Courses",
    show: "Auth",
    order: 5,
    subLinks: [
      {
        href: `${domain}/my-courses`,
        text: "My Courses",
        show: "Auth",
        order: 1,
        target: "_blank",
      },
      {
        href: "/search",
        text: "Course Catalog",
        show: "Both",
        order: 5,
      },
    ],
  },
  {
    href: "/search",
    text: "Course Catalog",
    show: "Unauth",
    order: 5,
  },
  {
    href: `${domain}/what-is-hbcuv`,
    text: "About Us",
    show: "Unauth",
    order: 4,
  },
];

export const accountLinks = (domain) => [
  {
    href: `${domain}/profile/me`,
    text: "Profile",
    icon: "Account",
    show: "Auth",
  },
  {
    href: `${domain}/form/support`,
    text: "Get Help",
    icon: "Help",
    show: "Auth",
  },
];
