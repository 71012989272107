import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["optionsContainer", "availableOptions"];

  connect() {}

  toogleOptions(event) {
    let element = event.target;

    if (element.value == "Y") {
      this.optionsContainerTarget.classList.remove("hidden");
      this.availableOptionsTarget.setAttribute("required", "required");
    } else {
      this.availableOptionsTarget.value = "";
      this.optionsContainerTarget.classList.add("hidden");
      this.availableOptionsTarget.removeAttribute("required");
    }
  }
}
