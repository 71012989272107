import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "password",
    "passwordConfirmation",
    "passwordConfirmationError",
    "recoveryEmail",
    "recoveryEmailConfirmation",
    "recoveryEmailConfirmationError",
    "signUpButton",
    "tosAgreement",
    "sharedTranscriptsAgreement",
  ];

  connect() {}

  enableSignUp() {
    if (
      this.tosAgreementTarget.checked &&
      (!this.hasSharedTranscriptsAgreementTarget ||
        this.sharedTranscriptsAgreementTarget.checked)
    ) {
      this.signUpButtonTarget.removeAttribute("disabled");
    } else {
      this.signUpButtonTarget.disabled = "disabled";
    }
  }

  validatePassword() {
    if (!this.hasPasswordTarget) {
      return true;
    }

    let valid =
      this.passwordTarget.value != "" &&
      this.passwordConfirmationTarget.value != "" &&
      this.passwordTarget.value == this.passwordConfirmationTarget.value;

    if (!valid) {
      this.passwordConfirmationTarget.classList.add(
        ...["border", "border-red-600"]
      );
      this.passwordTarget.classList.add(...["border", "border-red-600"]);
      this.passwordConfirmationErrorTarget.classList.remove("hidden");
    } else {
      this.passwordConfirmationTarget.classList.remove(
        ...["border", "border-red-600"]
      );
      this.passwordTarget.classList.remove(...["border", "border-red-600"]);
      this.passwordConfirmationErrorTarget.classList.add("hidden");
    }

    return valid;
  }

  validateRecoveryEmail() {
    if (!this.hasRecoveryEmailTarget) {
      return true;
    }

    let valid =
      this.recoveryEmailTarget.value ==
      this.recoveryEmailConfirmationTarget.value;

    if (!valid) {
      this.recoveryEmailConfirmationErrorTarget.classList.remove("hidden");
      this.recoveryEmailConfirmationTarget.classList.add(
        ...["border", "border-red-600"]
      );
      this.recoveryEmailTarget.classList.add(...["border", "border-red-600"]);
    } else {
      this.recoveryEmailConfirmationErrorTarget.classList.add("hidden");
      this.recoveryEmailConfirmationTarget.classList.remove(
        ...["border", "border-red-600"]
      );
      this.recoveryEmailTarget.classList.remove(
        ...["border", "border-red-600"]
      );
    }

    return valid;
  }
}
