import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "cancelLinkContainer",
    "triggerLinkContainer",
    "residencyDropDownContainer",
    "residencyValue",
    "originalLabel",
  ];

  connect() {}

  showDropDownMenu() {
    this.triggerLinkContainerTarget.classList.add("hidden");
    this.cancelLinkContainerTarget.classList.remove("hidden");
    this.residencyDropDownContainerTarget.classList.remove("hidden");
    this.originalLabelTarget.classList.add("hidden");
    document.getElementById("batch_application_residency_id").value =
      this.residencyValueTarget.value;
  }

  hideDropDownMenu() {
    this.triggerLinkContainerTarget.classList.remove("hidden");
    this.cancelLinkContainerTarget.classList.add("hidden");
    this.residencyDropDownContainerTarget.classList.add("hidden");
    this.originalLabelTarget.classList.remove("hidden");
    document.getElementById("batch_application_residency_id").value = "";
  }

  updateResidencyValueInApprovalDenyForm() {
    document.getElementById("batch_application_residency_id").value =
      this.residencyValueTarget.value;
  }
}
