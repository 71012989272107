import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["homeCollegeSelector", "logInButton", "logInForm"];

  updateSigninLink() {
    if (this.homeCollegeSelectorTarget.value) {
      this.logInButtonTarget.removeAttribute("disabled");
    } else {
      this.logInButtonTarget.setAttribute("disabled", "disabled");
    }
  }

  clickOnSignIn(e) {
    if (!this.homeCollegeSelectorTarget.value) {
      e.preventDefault();
    }
  }
}
