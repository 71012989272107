import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    currentUserPresent: Boolean,
    authHost: String,
    redirect: Boolean,
    redirectUrl: String,
    skipAuth: Boolean,
  };

  connect() {
    if (this.skipAuthValue) return;

    fetch(this.authHostValue + "/api/v1/sessions/me", {
      mode: "cors",
      credentials: "include",
    }).then((response) => {
      if (response.status == 200 && !this.currentUserPresentValue) {
        let spinner = document.getElementById(
          "hbcuv-silent-login-loading-indicator"
        );
        if (spinner) {
          document.body.classList.add("modal-open");
          spinner.classList.remove("hidden");
        }
        document.getElementById("header-sign-in-link-hidden").click();
      } else if (response.status != 200 && this.currentUserPresentValue) {
        document.getElementById("header-sign-out-link-hidden").click();
      } else if (this.redirectValue) {
        window.location.replace(this.redirectUrlValue || "/search");
      }
    });
  }
}
