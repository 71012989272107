import React, { Fragment, useState } from "react";
import { AccountCircleOutlined as AccountCircleOutlinedIcon } from "@mui/icons-material";
import {
  Box,
  Link,
  Button,
  Typography,
  Popover,
  IconButton,
} from "@mui/material";

import { accountLinks, mainLinks } from "./headerContent";

export function DesktopNav(props) {
  const [anchorElList, setAnchorElList] = useState({
    id: null,
  });

  const accountMenuId = "menu-account";

  function onOpenMenu(id, event) {
    setAnchorElList({
      [id]: event.currentTarget,
    });
  }

  function onCloseMenu() {
    setAnchorElList({});
  }

  return (
    <Box
      sx={{
        display: {
          xs: "none",
          md: "flex",
        },
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box>
        <Link href={props.externalDomain}>
          <img
            alt="hbcuv logo"
            src={props.logoWhiteUrl}
            width="127px"
            height="24px"
            className="inline align-baseline"
          />
        </Link>
        {props.user.logged && (
          <Typography
            variant="titleSmall"
            sx={{
              color: "common.white",
            }}
          >
            {props.user.institutionName}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          right: 0,
        }}
      >
        <Box
          component="nav"
          sx={{
            display: "flex",
            ml: "auto",
            gap: 2,
          }}
        >
          {mainLinks(props.externalDomain).map(
            ({ href, text, subLinks, show, target }, index) => {
              if (
                (show === "Auth" && !props.user.logged) ||
                (show === "Unauth" && props.user.logged) ||
                (text === "My Courses" && !props.user.show_my_courses)
              ) {
                return <Fragment key={index} />;
              }
              if (!subLinks) {
                return (
                  <Link
                    key={index}
                    href={href}
                    variant="menuItem"
                    underline="hover"
                    target={target}
                  >
                    {text}
                  </Link>
                );
              }

              const id = `menu-${index}`;

              return (
                <Fragment key={index}>
                  <Link component="button" variant="menuItem" underline="hover">
                    {text}
                  </Link>
                  <Popover
                    id={id}
                    keepMounted
                    open={Boolean(anchorElList[id])}
                    onClose={onCloseMenu}
                    anchorEl={anchorElList[id]}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    componentsProps={{
                      backdrop: {
                        style: {
                          backgroundColor: "transparent",
                        },
                      },
                    }}
                    PaperProps={{
                      sx: {
                        borderRadius: 0,
                        display: "flex",
                        flexDirection: "column",
                        width: 200,
                      },
                    }}
                  >
                    {subLinks.map(({ href, text }, index) => (
                      <Link
                        key={index}
                        onClick={onCloseMenu}
                        href={href}
                        variant="menuItemSmall"
                        underline="hover"
                      >
                        {text}
                      </Link>
                    ))}
                  </Popover>
                </Fragment>
              );
            }
          )}
        </Box>
        {props.user.logged && (
          <>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <IconButton
                onClick={function (event) {
                  return onOpenMenu(accountMenuId, event);
                }}
                aria-label="Open account menu"
                color="inherit"
                size="large"
              >
                {props.user.profileImgUrl ? (
                  <Box
                    sx={{
                      borderRadius: "100%",
                      overflow: "hidden",
                      width: "44px",
                      height: "44px",
                    }}
                  >
                    <img
                      alt="user profile image"
                      src={props.user.profileImgUrl}
                      className="object-cover w-11 h-11"
                    />
                  </Box>
                ) : (
                  <AccountCircleOutlinedIcon />
                )}
              </IconButton>
            </Box>
            <Box
              sx={{
                mr: {
                  xl: -16,
                },
                overflow: "hidden",
                width: {
                  md: "96px",
                  xl: "220px",
                },
                height: "96px",
              }}
            >
              <Box
                component="img"
                sx={{
                  width: 220,
                  height: 96,
                  maxWidth: "none",
                }}
                alt="partner cobranded logo"
                src={props.user.institutionDeskImgUrl}
              />
            </Box>
            <Popover
              id={accountMenuId}
              keepMounted
              open={Boolean(anchorElList[accountMenuId])}
              onClose={onCloseMenu}
              anchorEl={anchorElList[accountMenuId]}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              componentsProps={{
                backdrop: {
                  style: {
                    backgroundColor: "transparent",
                  },
                },
              }}
              PaperProps={{
                sx: {
                  borderRadius: 0,
                  display: "flex",
                  flexDirection: "column",
                  width: 200,
                },
              }}
              sx={{
                top: 28,
              }}
            >
              {accountLinks(props.externalDomain).map(
                ({ href, text }, index) => (
                  <Link
                    key={index}
                    onClick={onCloseMenu}
                    href={href}
                    variant="menuItemSmall"
                    underline="hover"
                    sx={{
                      typography: "labelLarge",
                    }}
                  >
                    {text}
                  </Link>
                )
              )}
              <Link
                href={props.user.logoutUrl}
                variant="menuItemSmall"
                underline="hover"
                sx={{
                  textAlign: "left",
                  typography: "labelLarge",
                }}
                data-method="delete"
              >
                Log Out
              </Link>
            </Popover>
          </>
        )}
        {!props.user.logged && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 4,
              ml: 10,
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              href="https://uncf.org/hbcuv"
              target="_blank"
              rel="noopener"
            >
              Support HBCUv
            </Button>
            <Button
              color="primary"
              variant="contained"
              href={`${
                props.externalDomain
              }/?login=true&loginRedirectUrl=${encodeURIComponent(
                `https://${props.domain}/global_sign_in?redirect_url=${window.location.href}`
              )}`}
            >
              Sign In
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
