import { Controller } from "stimulus";

const twilioMaxSmsSize = 1600;
export default class extends Controller {
  static targets = ["remaining"];

  calculateRemaining(event) {
    let contentLength = event.target.value.length;
    this.remainingTarget.innerHTML = twilioMaxSmsSize - contentLength;
  }
}
