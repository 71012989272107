import React, { Fragment, useState } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  Container,
  IconButton,
  ThemeProvider,
  Box,
  Button,
  Grid,
  Link,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  TextField,
} from "@mui/material";

import { ToastMessage } from "../packs/toastMessage";

import { lightTheme } from "./theme";

import { footerLinks } from "./footerLinks";

export function Footer(props) {
  const [expandedPanel, setExpandedPanel] = useState("");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : "");
  };
  const emailSubscriptionEndpointUrl = props.emailSubscriptionEndpoint;
  const [subscribeEmail, setSubscribeEmail] = useState("");
  const subscribeEmailBody = `Hello HBCUv team,\nPlease add ${subscribeEmail} to the subscription newsletter list. \nThanks!`;

  const [emailError, setEmailError] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const emailSubscriptionRequest = (event) => {
    event.preventDefault();

    const emailPattern = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    if (emailPattern.test(subscribeEmail)) {
      setEmailError(false);
      sendSubscriptionRequest();
    } else {
      setEmailError(true);
    }
  };

  const sendSubscriptionRequest = () => {
    fetch(emailSubscriptionEndpointUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        contact: subscribeEmail,
        subject: "Newsletter Subscription",
        type: "NewsletterSubscriptionDynamic",
        body: subscribeEmailBody,
      }),
    }).then((response) => {
      if (response.ok) {
        setShowSuccessMessage(true);
        setSubscribeEmail("");
      } else {
        setShowErrorMessage(true);
        console.error("Fetch error. Status Code: " + response.status);
      }
    });
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <Box component="footer" bgcolor="background.primary" color="text.primary">
        <Container
          sx={{
            pt: {
              xs: 10,
              md: 20,
            },
            pb: {
              xs: 10,
              md: 10,
            },
          }}
        >
          <Grid
            container
            sx={{
              columnGap: {
                md: 15,
                lg: 24,
              },
            }}
          >
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                mb: 14,
              }}
            >
              <Box
                sx={{
                  mb: 10,
                  position: "relative",
                }}
              >
                <Box
                  component="img"
                  className="hbcuv-logo"
                  sx={{
                    width: 187,
                    height: 35,
                  }}
                  alt="hbcuv logo"
                  src={props.logoDarkUrl}
                />
              </Box>
              <Typography
                variant="titleLarge"
                sx={{
                  mb: 10,
                }}
              >
                We can&apos;t simply believe in equality in education. We have
                to create it.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: 4,
                }}
              >
                <IconButton
                  aria-label="facebook"
                  color="inherit"
                  href="https://www.facebook.com/UNCF"
                  target="_blank"
                  rel="noopener"
                  sx={{
                    p: 0,
                  }}
                >
                  <FacebookIcon fontSize="large" />
                </IconButton>
                <IconButton
                  aria-label="twitter"
                  color="inherit"
                  href="https://twitter.com/UNCFICB"
                  target="_blank"
                  rel="noopener"
                  sx={{
                    p: 0,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 22 20"
                  >
                    <path
                      fill="currentColor"
                      d="M17.326.057H20.7L13.33 8.48 22 19.943h-6.789L9.894 12.99 3.81 19.943H.434l7.883-9.01L0 .057h6.961l4.806 6.354 5.56-6.354Zm-1.184 17.866h1.87L5.945 1.97H3.94l12.203 15.953Z"
                    />
                  </svg>
                </IconButton>
                <IconButton
                  aria-label="instagram"
                  color="inherit"
                  href="https://www.instagram.com/uncficb/"
                  target="_blank"
                  rel="noopener"
                  sx={{
                    p: 0,
                  }}
                >
                  <InstagramIcon fontSize="large" />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography
                variant="titleMedium"
                sx={{
                  mb: 4,
                }}
              >
                Join the Mission
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: {
                    xs: 2,
                    md: 4,
                  },
                  mb: 14,
                }}
              >
                <TextField
                  label="Enter your email address"
                  variant="outlined"
                  name="email"
                  error={emailError}
                  helperText={
                    emailError && "Please enter a valid email address"
                  }
                  value={subscribeEmail}
                  onChange={(e) => setSubscribeEmail(e.target.value)}
                  sx={{
                    flex: {
                      xs: 1,
                      md: 0.75,
                    },
                    width: "50%",
                  }}
                />
                <Button
                  color="primary"
                  variant="contained"
                  onClick={emailSubscriptionRequest}
                  sx={{
                    width: "25%",
                  }}
                >
                  Subscribe
                </Button>
              </Box>
              <ToastMessage
                severity="success"
                title="Success!"
                message="You have successfully subscribed to our newsletter."
                visibility={showSuccessMessage}
                setVisibility={setShowSuccessMessage}
              />
              <ToastMessage
                severity="error"
                title="Something went wrong"
                message="We were unable to complete your request. Please try again."
                visibility={showErrorMessage}
                setVisibility={setShowErrorMessage}
              />
              <Box
                sx={{
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                  justifyContent: "space-between",
                  mb: 14,
                }}
              >
                {footerLinks(props.domain).map(
                  ({ title, subTitles, show }, index) => {
                    if (
                      (show === "Auth" && !props.user.logged) ||
                      (show === "Unauth" && props.user.logged)
                    ) {
                      return <Fragment key={index} />;
                    }

                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                        key={index}
                      >
                        <Typography
                          variant="titleMedium"
                          sx={{
                            mb: 6,
                          }}
                        >
                          {title}
                        </Typography>
                        {subTitles.map(({ title, href, target }, subIndex) => (
                          <Link
                            key={subIndex}
                            href={href}
                            target={target && "_blank"}
                            color="text.secondary"
                            variant="bodyMedium"
                            underline="hover"
                            sx={{
                              p: 0,
                              mb: 2,
                            }}
                          >
                            {title}
                          </Link>
                        ))}
                      </Box>
                    );
                  }
                )}
              </Box>
              <Box
                sx={{
                  display: {
                    md: "none",
                  },
                  mb: 14,
                }}
              >
                {footerLinks(props.domain).map(
                  ({ title, subTitles, show }, index) => {
                    if (
                      (show === "Auth" && !props.user.logged) ||
                      (show === "Unauth" && props.user.logged)
                    ) {
                      return <Fragment key={index} />;
                    }

                    return (
                      <Accordion
                        key={index}
                        expanded={expandedPanel === title}
                        elevation={0}
                        onChange={handleChange(title)}
                        sx={{
                          backgroundColor: "transparent",
                          minHeight: 0,
                          "&:before": {
                            display: "none",
                          },
                          "&.Mui-expanded": {
                            my: 0,
                          },
                          ".MuiAccordionSummary-content": {
                            my: 0,
                          },
                          ".MuiAccordionSummary-content.Mui-expanded": {
                            my: 0,
                          },
                          ".MuiAccordionSummary-root.Mui-expanded": {
                            minHeight: 0,
                          },
                          ".MuiCollapse-wrapperInner": {
                            mb: 0,
                          },
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon
                              sx={{
                                color: "text.primary",
                              }}
                            />
                          }
                          sx={{
                            backgroundColor: "transparent",
                            p: 0,
                            minHeight: 0,
                            mt: index === 0 ? 0 : 6,
                          }}
                        >
                          <Typography
                            variant="titleMedium"
                            sx={{
                              m: 0,
                            }}
                          >
                            {title}
                          </Typography>
                        </AccordionSummary>
                        {subTitles.map(({ title, href, target }, subIndex) => (
                          <AccordionDetails
                            key={subIndex}
                            sx={{
                              p: 0,
                              mt: 6,
                              mb:
                                footerLinks.length - 1 !== index &&
                                subTitles.length - 1 === subIndex
                                  ? 4
                                  : 0,
                            }}
                          >
                            <Link
                              key={subIndex}
                              href={href}
                              target={target && "_blank"}
                              color="text.secondary"
                              variant="bodyMedium"
                              underline="hover"
                              sx={{
                                p: 0,
                              }}
                            >
                              {title}
                            </Link>
                          </AccordionDetails>
                        ))}
                      </Accordion>
                    );
                  }
                )}
              </Box>
              <Box
                sx={{
                  display: {
                    md: "flex",
                  },
                  mb: 14,
                }}
              >
                <Typography
                  variant="titleMedium"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: {
                      xs: 4,
                      md: 0,
                    },
                    mr: 8,
                    whiteSpace: "nowrap",
                  }}
                >
                  Partner Schools
                </Typography>
                <Grid
                  container
                  columns={11}
                  sx={{
                    justifyContent: {
                      xs: "flex-start",
                      md: "space-evenly",
                    },
                    rowGap: 8,
                  }}
                >
                  {props.institutionsLogos.map((institution, index) => (
                    <Grid item key={index} xs={2} md={1}>
                      <Link href={institution.website} target="_blank">
                        <img
                          src={institution.url}
                          width="40px"
                          height="40px"
                          alt="Partner Institution logo"
                          className="inline align-baseline"
                        />
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                  gap: {
                    xs: 6,
                    md: 10,
                  },
                  justifyContent: "right",
                }}
              >
                <Link
                  variant="linkSmall"
                  color="text.secondary"
                  href="https://uncf.org/pages/uncf-privacy-policy"
                  rel="noopener"
                  target="_blank"
                  sx={{
                    textAlign: "left",
                  }}
                >
                  Privacy Policy
                </Link>
                <Link
                  variant="linkSmall"
                  color="text.secondary"
                  href="https://uncf.org/pages/uncf-website-terms-and-conditions"
                  rel="noopener"
                  target="_blank"
                  sx={{
                    textAlign: "left",
                  }}
                >
                  Terms and Conditions
                </Link>
                <Typography variant="bodySmall" color="text.secondary">
                  © Copyright 2024
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
