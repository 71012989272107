import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "fileContainer",
    "fileLoader",
    "fileUploadErrorMessage",
    "fileUploadedFiles",
  ];

  connect() {}

  toggleResidency(event) {
    let element = event.target;

    if (element.value == "Y") {
      this.fileContainerTarget.classList.remove("hidden");
    } else {
      this.fileContainerTarget.classList.add("hidden");
    }

    this.fileUploadedFilesTarget.classList.add("hidden");
    this.fileUploadErrorMessageTarget.classList.add("hidden");
    this.fileLoaderTarget.value = "";
  }

  toggleResidencyWithMinimum(event) {
    let element = event.target;
    let required_input_file = this.fileContainerTarget.getElementsByClassName(
      "required_input_file"
    );

    if (element.value == "Y") {
      this.fileContainerTarget.classList.remove("hidden");

      if (required_input_file.length > 0) {
        Array.from(required_input_file).filter((input_files) =>
          input_files.setAttribute("required", "required")
        );
      }
    } else {
      this.fileContainerTarget.classList.add("hidden");

      if (required_input_file.length > 0) {
        Array.from(required_input_file).filter((input_files) =>
          input_files.removeAttribute("required")
        );
      }
    }

    this.fileUploadedFilesTarget.classList.add("hidden");
    this.fileUploadErrorMessageTarget.classList.add("hidden");
    this.fileLoaderTarget.value = "";
  }
}
