import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["courseId", "searchedCourse", "searchedCourseSelectButton"];

  onSelectedChange() {
    let courseIds = event.detail.courseIds;

    let courseId = this.searchedCourseTarget.getAttribute("data-course-id");
    if (courseIds && courseIds.includes(courseId)) {
      this.searchedCourseSelectButtonTarget.classList.add("hidden");
    } else {
      this.searchedCourseSelectButtonTarget.classList.remove("hidden");
    }
  }

  select() {
    // Init a custom DOM event.
    // Recommended approach to communicate between controllers
    // https://github.com/stimulusjs/stimulus/issues/200#issuecomment-434731830
    const event = document.createEvent("CustomEvent");
    event.initCustomEvent("on-course-selected", true, true, {
      courseId: this.courseIdTarget.value,
    });
    this.element.dispatchEvent(event);
  }
}
