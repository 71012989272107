import QuottlyModalController from "./../../quottly_modal_controller.js";

export default class extends QuottlyModalController {
  static targets = ["openButton", "modalCloseButton"];

  beforeOpenActions() {
    this.focusableElements.push(this.modalCloseButtonTarget);
  }

  afterOpenActions() {
    this.modalCloseButtonTarget.focus();
  }

  beforeCloseActions() {}
}
