import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["wrapper"];

  playVideo() {
    const url = this.wrapperTarget.dataset.video;
    this.wrapperTarget.innerHTML = "<iframe src='" + url + "'></iframe>";
  }
}
