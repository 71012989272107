import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    approveState: String,
    denyState: String,
  };
  static targets = ["denyReason", "denyReasonContainer"];

  selectApprove() {
    this.denyReasonTarget.required = false;
    this.denyReasonTarget.disabled = true;
    this.denyReasonContainerTarget.classList.add("hidden");
  }

  selectDeny() {
    this.denyReasonTarget.required = true;
    this.denyReasonTarget.disabled = false;
    this.denyReasonContainerTarget.classList.remove("hidden");
    this.denyReasonTarget.focus();
  }

  toggleApprove(event) {
    if (event.target.value == this.approveStateValue) {
      this.selectApprove();
    } else if (event.target.value == this.denyStateValue) {
      this.selectDeny();
    }
  }
}
