export const mainLinks = (domain) => [
  {
    href: `${domain}/my-courses`,
    text: "My Courses",
    show: "Auth",
    order: 1,
    target: "_blank",
  },
  {
    href: `${domain}/the-yard/1`,
    text: "The Yard",
    show: "Auth",
    order: 2,
  },
  {
    href: `${domain}/user-directory`,
    text: "Directory",
    show: "Auth",
    order: 3,
  },
  {
    href: "/search",
    text: "Course Catalog",
    show: "Both",
    order: 5,
  },
  {
    href: `${domain}/what-is-hbcuv`,
    text: "About Us",
    show: "Unauth",
    order: 4,
  },
];

export const accountLinks = (domain) => [
  {
    href: `${domain}/profile/me`,
    text: "Profile",
    icon: "Account",
    show: "Auth",
  },
  {
    href: `mailto:webmaster@hbcuv.org?bcc=&subject=HBCUv Support Request&body=Hello HBCUv team,%0D%0A%0AI%27m running into an issue on HBCUv.org and would like to chat with someone to get help.%0D%0A%0A<insert additional message here>%0D%0A%0AThanks!`,
    text: "Get Help",
    icon: "Help",
    show: "Auth",
  },
];
