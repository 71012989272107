import React, { useState, Fragment } from "react";
import {
  Box,
  Link,
  Button,
  Drawer,
  IconButton,
  Accordion,
  AccordionDetails,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LogoutIcon from "@mui/icons-material/Logout";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";

import { accountLinks, mainLinks } from "./headerContent";

import { headerMobileHeight } from "./theme";

export function MobileNav(props) {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const onCloseDrawer = () => setDrawerIsOpen(false);

  const [expandedPanel, setExpandedPanel] = useState("");

  return (
    <>
      <Drawer
        open={drawerIsOpen}
        onClose={onCloseDrawer}
        role="presentation"
        PaperProps={{
          sx: {
            backgroundColor: "background.secondary",
            backgroundImage: "none",
            border: "none",
            color: "text.primary",
            borderRadius: 0,
            width: 311,
            pb: 10,
          },
        }}
      >
        <Box
          component="header"
          sx={{
            display: "flex",
            alignItems: "center",
            height: headerMobileHeight,
          }}
        >
          <IconButton
            size="large"
            color="inherit"
            aria-label="Close drawer"
            onClick={() => setDrawerIsOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          component="nav"
          sx={{
            display: "flex",
            flexDirection: "column",
            px: 6,
          }}
        >
          {mainLinks(props.externalDomain).map(
            ({ href, text, subLinks, show, order, target }, index) => {
              if (
                (show === "Auth" && !props.user.logged) ||
                (show === "Unauth" && props.user.logged) ||
                (text === "My Courses" && !props.user.show_my_courses)
              ) {
                return <Fragment key={index} />;
              }
              if (!subLinks) {
                return (
                  <Link
                    key={index}
                    href={href}
                    variant="menuItem"
                    underline="hover"
                    target={target}
                    sx={{
                      order: order,
                    }}
                    onClick={onCloseDrawer}
                  >
                    {text}
                  </Link>
                );
              }

              return (
                <Accordion
                  key={index}
                  expanded={expandedPanel === `panel${index}`}
                  elevation={0}
                  sx={{
                    backgroundColor: "transparent",
                  }}
                >
                  <Link
                    component="button"
                    variant="menuItem"
                    underline="hover"
                    endIcon={
                      <ExpandMoreIcon color="primary" fontSize="small" />
                    }
                    sx={{
                      width: "100%",
                    }}
                    onClick={() =>
                      setExpandedPanel(
                        expandedPanel === `panel${index}` ? "" : `panel${index}`
                      )
                    }
                  >
                    {text}
                  </Link>
                  <AccordionDetails>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {subLinks.map(({ href, text }, subIndex) => (
                        <Link
                          key={subIndex}
                          href={href}
                          variant="menuItemSmall"
                          underline="hover"
                          sx={{
                            color: "common.secondWhite",
                          }}
                          onClick={onCloseDrawer}
                        >
                          {text}
                        </Link>
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            }
          )}
        </Box>
        <Box
          component="main"
          sx={{
            mt: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              px: 6,
            }}
          >
            {!props.user.logged ? (
              <>
                <Button
                  color="primary"
                  variant="outlined"
                  href="https://uncf.org/hbcuv"
                  target="_blank"
                  rel="noopener"
                >
                  Support HBCUv
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  href={`${
                    props.externalDomain
                  }/?login=true&loginRedirectUrl=${encodeURIComponent(
                    `https://${props.domain}/global_sign_in?redirect_url=${window.location.href}`
                  )}`}
                >
                  Sign In
                </Button>
              </>
            ) : (
              <>
                {accountLinks(props.externalDomain).map(
                  ({ href, text, icon }, index) => (
                    <Link
                      key={index}
                      href={href}
                      variant="menuItemSmall"
                      sx={{
                        display: "flex",
                        gap: 6,
                        textDecoration: "none",
                      }}
                      onClick={onCloseDrawer}
                    >
                      <Box
                        sx={{
                          flex: "0 0 24px",
                          textAlign: "center",
                        }}
                      >
                        {index === 0 && props.user.profileImgUrl ? (
                          <img
                            alt="user profile image"
                            src={props.user.profileImgUrl}
                            className="rounded-full w-6 h-6"
                          />
                        ) : (
                          <Box>
                            {icon === "Account" ? (
                              <AccountCircleOutlinedIcon
                                fontSize="small"
                                color="gold"
                              />
                            ) : (
                              <LiveHelpIcon fontSize="small" color="gold" />
                            )}
                          </Box>
                        )}
                      </Box>
                      {text}
                    </Link>
                  )
                )}
                <Link
                  href={props.user.logoutUrl}
                  variant="menuItemSmall"
                  sx={{
                    display: "flex",
                    gap: 6,
                    textDecoration: "none",
                  }}
                  data-method="delete"
                >
                  <Box
                    sx={{
                      flex: "0 0 24px",
                      textAlign: "center",
                    }}
                  >
                    <LogoutIcon fontSize="small" color="primary" />
                  </Box>
                  Log Out
                </Link>
              </>
            )}
          </Box>
          {props.user.logged && (
            <img
              alt="partner cobranded logo"
              src={props.user.institutionMobImgUrl}
              width="123px"
              height="174px"
              style={{
                position: "absolute",
                right: 0,
                bottom: 0,
                zIndex: -1,
              }}
            />
          )}
        </Box>
      </Drawer>
      <Box
        sx={{
          display: {
            xs: "flex",
            md: "none",
          },
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <IconButton
          size="large"
          color="inherit"
          aria-label="Open navigation"
          aria-haspopup="true"
          onClick={() => setDrawerIsOpen(true)}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mx: "auto",
            textAlign: "center",
          }}
        >
          {props.user.logged ? (
            <>
              <Link
                href={props.externalDomain}
                sx={{
                  height: "19px",
                  mt: 2,
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: 106,
                    height: 20,
                  }}
                  alt="hbcuv logo"
                  src={props.logoWhiteUrl}
                />
              </Link>
              <Typography
                variant="titleSmall"
                sx={{
                  color: "common.white",
                  fontSize: "10px",
                  ml: "-10px",
                }}
              >
                {props.user.institutionName}
              </Typography>
            </>
          ) : (
            <Link
              href={`${props.externalDomain}/`}
              sx={{
                height: "19px",
              }}
            >
              <Box
                component="img"
                sx={{
                  width: 106,
                  height: 20,
                }}
                alt="hbcuv logo"
                src={props.logoWhiteUrl}
              />
            </Link>
          )}
        </Box>
        <Box
          sx={{
            width: 48,
          }}
        />
      </Box>
    </>
  );
}
