import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source", "key", "noResults"];

  filter(event) {
    if (event.keyCode == 27) {
      this.sourceTarget.value = "";
    }

    let criteria = this.sourceTarget.value.toLowerCase();
    let elements = [];

    if (criteria.length > 0) {
      // first hide all elements
      this.keyTargets.forEach((el) => {
        el.classList.add("hidden");
      });

      this.keyTargets.forEach((el) => {
        let text_content = el
          .getAttribute("data-key")
          .replace(/(\r\n|\n|\r)/gm, "")
          .toLowerCase();
        console.log(text_content);

        try {
          if (text_content.match(new RegExp(criteria, "ig")).length > 0) {
            elements.push(el);
            el.classList.remove("hidden");
          }
        } catch (error) {
          console.log("no matches");
        }
      });

      if (parseInt(elements.length) == 0) {
        this.noResultsTarget.classList.remove("hidden");
      } else {
        this.noResultsTarget.classList.add("hidden");
      }
    } else {
      // if less or equal to 1 character just show up everything again
      this.keyTargets.forEach((el) => {
        el.classList.remove("hidden");
        this.noResultsTarget.classList.add("hidden");
      });
    }
  }

  clear() {
    this.sourceTarget.value = "";
    this.keyTargets.forEach((el) => {
      el.classList.remove("hidden");
    });
    this.noResultsTarget.classList.add("hidden");
  }
}
