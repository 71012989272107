import { Controller } from "stimulus";
import tippy from "tippy.js";

export default class extends Controller {
  static values = { title: String };

  connect() {
    this.startTippy();
  }

  startTippy() {
    this.tippy = tippy(this.element, {
      content: this.titleValue,
      animation: "perspective-subtle",
    });
  }

  restartTippy() {
    this.disconnect();
    this.startTippy();
  }

  disconnect() {
    this.tippy.destroy();
  }
}
