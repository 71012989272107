import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["colorPicker", "colorText"];

  static values = {
    modelName: String,
  };

  connect() {}

  updatePickerValue() {
    this.colorPickerTarget.value = this.colorTextTarget.value;
  }

  updateTextValue() {
    this.colorTextTarget.value = this.colorPickerTarget.value;
  }
}
