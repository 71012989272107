import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["field"];

  connect() {
    this.setRequiredAttribute();
  }

  setRequiredAttribute() {
    // Loop through all the checkboxes, if at least 1 is checked,
    // remove the required attribute from them to allow form submission
    const selectedCheckbox = this.fieldTargets.find(
      (checkboxField) => checkboxField.checked
    );

    this.fieldTargets.forEach((checkboxField) => {
      if (selectedCheckbox) {
        checkboxField.removeAttribute("required");
        checkboxField.setCustomValidity("");
      } else {
        checkboxField.setAttribute("required", "required");
      }
    });
  }
}
