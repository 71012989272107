import { Controller } from "stimulus";
import scrollSpy from "simple-scrollspy";

export default class extends Controller {
  connect() {
    scrollSpy(document.getElementById("course-page-nav"), {
      sectionClass: ".course-section",
      offset: -200,
    });
  }
}
