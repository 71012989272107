import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["startTimeSelect", "endTimeSelect", "timeErrorMessage"];

  restrictTimeFilter() {
    if (
      parseInt(this.startTimeSelectTarget.value) <
      parseInt(this.endTimeSelectTarget.value)
    ) {
      this.timeErrorMessageTarget.innerHTML = "";
      this.startTimeSelectTarget.classList.remove("border-red-700");
      this.endTimeSelectTarget.classList.remove("border-red-700");
      this.__getControllerByIdentifier("search-results").refreshResults();
    } else {
      this.timeErrorMessageTarget.innerHTML =
        "End time cannot be earlier than Start time";
      this.startTimeSelectTarget.classList.add("border-red-700");
      this.endTimeSelectTarget.classList.add("border-red-700");
    }
  }

  // This function will only work properly assuming there's a single instance of `identifier`
  // on the page.
  __getControllerByIdentifier(identifier) {
    return this.application.controllers.find((controller) => {
      return controller.context.identifier === identifier;
    });
  }
}
