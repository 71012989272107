import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
import consumer from "../../channels/consumer";

export default class extends Controller {
  static values = { courseId: Number, userId: Number, urlFinishParam: String };
  static targets = [
    "offering",
    "register",
    "registering",
    "registerMobile",
    "registeringMobile",
  ];

  connect() {
    StimulusReflex.register(this);
    let changeUrlParams = new URLSearchParams(window.location.search);
    changeUrlParams.delete("show_registration_status");
    changeUrlParams.delete("show_drop_status");
    if (changeUrlParams.toString() == "") {
      changeUrlParams.set("process", "done");
    }
    window.history.replaceState(null, null, "?" + changeUrlParams.toString());
    const courseId = this.courseIdValue;
    const userId = this.userIdValue;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set(this.urlFinishParamValue, "true");
    this.channel = consumer.subscriptions.create(
      {
        channel: "CourseRegistrationChannel",
        user_id: userId,
        course_id: courseId,
      },
      {
        received() {
          window.location.search = urlParams;
        },
      }
    );
  }

  disconnect() {
    this.channel.unsubscribe();
  }

  processRegistration() {
    let offId = 0;
    this.offeringTargets.forEach((el) => {
      if (el.checked) {
        offId = el.value;
      }
    });
    this.registerTarget.classList.add("hidden");
    this.registeringTarget.classList.remove("hidden");
    this.registerMobileTarget.classList.add("hidden");
    this.registeringMobileTarget.classList.remove("hidden");

    this.stimulate("BatchApplications#process_ba", offId);
  }
}
