import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["schoolNameContainer", "schoolNameText"];

  connect() {}

  toggleSchooName(event) {
    let element = event.target;

    if (element.value == "Y") {
      this.schoolNameContainerTarget.classList.remove("hidden");
      this.schoolNameTextTarget.setAttribute("required", "required");
    } else {
      this.schoolNameContainerTarget.classList.add("hidden");
      this.schoolNameTextTarget.removeAttribute("required");
    }
  }
}
