import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["item"];
  static values = {
    collapseAll: Boolean,
  };

  // To collapse all answers when expanding a new one we should add
  // data-accordion-collapse-all-value="true"

  toggle(e) {
    let button = e.currentTarget;
    if (button.parentElement.classList.contains("open")) {
      button.parentElement.classList.add("closed");
      button.parentElement.classList.remove("open");
      button.setAttribute("aria-expanded", false);
    } else {
      if (this.collapseAllValue) {
        this.itemTargets.forEach((t) => {
          t.classList.remove("open");
          t.classList.add("closed");
          button.setAttribute("aria-expanded", false);
        });
      }
      button.setAttribute("aria-expanded", true);
      button.parentElement.classList.remove("closed");
      button.parentElement.classList.add("open");
    }
  }
}
