import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "selectedTransferReviewIds",
    "bulkUpdateForm",
    "bulkAction",
    "bulkApproveBtn",
    "bulkRejectBtn",
    "requiredFieldsMessage",
  ];

  connect() {
    this.selectedTransferReviewIdsTarget.value = "";
  }

  toggleTransferReviewSelection(event) {
    let values = this.selectedTransferReviewIdsTarget.value
      .split(",")
      .filter(function (e) {
        return e != "";
      });
    let transferReviewId = event.target.dataset.transfer_review_id;
    if (event.target.checked) {
      values.push(transferReviewId);
      this.selectedTransferReviewIdsTarget.value = values;
      this.bulkUpdateFormTarget.classList.remove("hidden");
    } else {
      let index = values.indexOf(transferReviewId);
      values.splice(index, index + 1);
      this.selectedTransferReviewIdsTarget.value = values;
      if (values.length == 0) this.bulkUpdateFormTarget.classList.add("hidden");
    }
  }

  validateBulkActionNotes(e) {
    if (e.target.value.length > 10) {
      this.bulkApproveBtnTarget.removeAttribute("disabled");
      this.bulkRejectBtnTarget.removeAttribute("disabled");
      this.requiredFieldsMessageTarget.classList.add("hidden");
    } else {
      this.bulkApproveBtnTarget.disabled = "disabled";
      this.bulkRejectBtnTarget.disabled = "disabled";
      this.requiredFieldsMessageTarget.classList.remove("hidden");
    }
  }

  bulkApprove(e) {
    this.bulkActionTarget.value = "approve";
    this.submitForm(e);
  }

  bulkReject(e) {
    this.bulkActionTarget.value = "reject";
    this.submitForm(e);
  }

  submitForm(e) {
    e.preventDefault();
    this.bulkUpdateFormTarget.submit();
  }
}
