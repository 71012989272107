import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "mainContainer",
    "destroyCheckbox",
    "trashBtn",
    "restoreBtn",
    "trixEditorContainer",
    "mainConsentItemsContainer",
    "consentItemTemplate",
    "studentSignatureContainer",
    "studentSignatureText",
  ];

  connect() {
    this.consentItemsAddedCount = 0;
  }

  removeItem(event) {
    event.preventDefault();
    let element = event.target;
    element.setAttribute("checked", "checked");
    this.mainContainerTarget.classList.add("consent-item-removed");
    this.trashBtnTarget.classList.add("hidden");
    this.trixEditorContainerTarget.classList.add("hidden");
    this.restoreBtnTarget.classList.remove("hidden");
  }
  restoreItem(event) {
    event.preventDefault();
    let element = event.target;
    this.destroyCheckboxTarget.removeAttribute("checked");
    this.mainContainerTarget.classList.remove("consent-item-removed");
    this.trashBtnTarget.classList.remove("hidden");
    this.trixEditorContainerTarget.classList.remove("hidden");
    this.restoreBtnTarget.classList.add("hidden");
  }

  addConsentItem(event) {
    event.preventDefault();
    this.consentItemsAddedCount++;
    let existing_trix_editors_count = parseInt(
      document.getElementsByClassName("trix_editor_container").length
    );
    let removed_trix_editors_count = parseInt(
      document.getElementsByClassName("trix_editor_container hidden").length
    );
    let element_index = parseInt(
      this.consentItemsAddedCount +
        existing_trix_editors_count -
        removed_trix_editors_count
    );

    let template = this.consentItemTemplateTarget;
    let cloned_element = document.importNode(template.content, true);
    this.mainConsentItemsContainerTarget.appendChild(cloned_element);
    let containers =
      this.mainConsentItemsContainerTarget.getElementsByClassName(
        "content_item_row"
      );
    let container = containers[containers.length - 1];

    Array.prototype.slice
      .call(container.getElementsByClassName("destroy_checkbox"))
      .forEach((checkbox) => {
        checkbox.setAttribute(
          "name",
          "registration_term[consent_items_attributes][" +
            element_index +
            "][_destroy]"
        );
      });
    Array.prototype.slice
      .call(container.getElementsByClassName("trix_editor_item_text"))
      .forEach((trix_text) => {
        trix_text.setAttribute(
          "name",
          "registration_term[consent_items_attributes][" +
            element_index +
            "][item_text]"
        );
        trix_text.setAttribute(
          "id",
          "registration_term_consent_items_" + element_index + "_item_text"
        );
      });
    Array.prototype.slice
      .call(container.getElementsByClassName("trix_editor_item_text_editor"))
      .forEach((trix_text) => {
        trix_text.setAttribute(
          "input",
          "registration_term_consent_items_" + element_index + "_item_text"
        );
      });
  }

  studentSignature(event) {
    event.preventDefault();
    let element = event.target;

    if (element.checked) {
      this.studentSignatureContainerTarget.classList.remove("hidden");
      this.studentSignatureTextTarget.setAttribute("required", "required");
    } else {
      this.studentSignatureContainerTarget.classList.add("hidden");
      this.studentSignatureTextTarget.value = "";
      this.studentSignatureTextTarget.removeAttribute("required");
    }
  }
}
