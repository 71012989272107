import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  static targets = [
    "loadingIndicator",
    "searchTypeLink",
    "homeCollegeContainer",
    "searchTypeContainer",
    "searchInputContainer",
    "input",
  ];

  connect() {
    this._hideLoadingIndicator();
    StimulusReflex.register(this);
  }

  clearFilter() {
    this.inputTarget.selectize.clear(true);
  }

  beforeReflex() {
    this._showLoadingIndicator();
  }

  afterReflex() {
    this._hideLoadingIndicator();
    this._clickSearchTypeRadio();
  }

  updateFilter(event) {
    // If the filter update is triggered by a university change or toggle between "all" or "single"
    // university, clear the search_type radios and search type inputs
    if (this.homeCollegeContainerTarget.contains(event.target)) {
      this.searchTypeContainerTarget
        .querySelectorAll("input")
        .forEach((i) => (i.checked = false));
      this.searchInputContainerTarget
        .querySelectorAll("select")
        .forEach((s) => (s.selectedIndex = 0));
    }

    // If the filter update is triggered by a search_type change, clear the search type inputs.
    if (this.searchTypeContainerTarget.contains(event.target)) {
      this.searchInputContainerTarget
        .querySelectorAll("select")
        .forEach((s) => (s.selectedIndex = 0));
    }

    waitFor(
      300,
      () => this.isActionCableConnectionOpen(),
      () => {
        this.stimulate("SearchTopbar#update_filter");
      }
    );
  }

  _showLoadingIndicator() {
    if (this.hasLoadingIndicatorTarget)
      this.loadingIndicatorTarget.style["display"] = null;
  }

  _hideLoadingIndicator() {
    if (this.hasLoadingIndicatorTarget)
      this.loadingIndicatorTarget.style["display"] = "none";
  }

  // There's some kind of issue with radio buttons being set via StimulusReflex / morphdom when the value doesn't change.
  // The proper radio input has the "checked" property but it's not properly styled nor searchable via the `:checked`
  // pseudo selector.
  //
  // It looks like this is a bug in one of these libraries, but for the time being, manually clicking the selected search type
  // after it's been reloaded ensures that if it hasn't, then it's selected.
  _clickSearchTypeRadio() {
    let searchTypeValue = this.data.get("searchType");
    let searchTypeLink = this.searchTypeLinkTargets.find(
      (l) => l.value === searchTypeValue
    );

    if (searchTypeLink !== undefined) {
      searchTypeLink.click();
    }
  }
}

function waitFor(period, condition, callback) {
  if (condition()) {
    callback();
  } else {
    window.setTimeout(waitFor.bind(null, period, condition, callback), period);
  }
}
