// From https://gist.github.com/kaspermeyer/7fe28bb7c55c2810e7b5f3d5e67c1a44
//
//~ Dispatch jQuery events as regular DOM events ~
//
//Delegated events are given a new name in the format `jquery:<original event name>`.
//If you delegate `ajax:send` you will be able to listen for `jquery:ajax:send`
//on native event listeners such as Stimulus actions and `EventTarget.addEventListener`.
//
//Notes:
//  * The first parameter must be called "event".
//  * The parameters can be accessed as members on the `event.detail` object.
//
//Example:
//  delegate('ajax:send', { parameters: ['event', 'xhr'] });
//  document.addEventListener('jquery:ajax:send', function(event) {
//    return console.log(event.detail);
//  });
//

import $ from "jquery";

var slice = [].slice;
var delegate = function (eventName, arg) {
  var handler, parameters;
  parameters = arg.parameters;
  handler = function () {
    var args, data, delegatedEvent, i, index, len, name;
    args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
    data = {};
    for (index = i = 0, len = parameters.length; i < len; index = ++i) {
      name = parameters[index];
      data[name] = args[index];
    }
    delegatedEvent = new CustomEvent("jquery:" + eventName, {
      bubbles: true,
      cancelable: true,
      detail: data,
    });
    return data.event.target.dispatchEvent(delegatedEvent);
  };
  return $(document).on(eventName, handler);
};

// jQuery change event -> trigged by ab.datepicker
delegate("change", { parameters: ["event"] });
