import { Controller } from "stimulus";

export default class extends Controller {
  static values = { eventId: String };

  trackEvent() {
    if (window.dataLayer == undefined) {
      return;
    }

    window.dataLayer.push({ event: this.eventIdValue });
  }
}
